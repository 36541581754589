/* @font-face {
    font-family: 'KentoLight';
    src: url(./fonts/KentoLight.ttf);
}
 */
html, body {
    overscroll-behavior: contain !important;
    touch-action: none;
}

@font-face {
	font-family: 'KentoLight';
	src: url(./fonts/KentoLight/kento-light.eot);
	src: url(./fonts/KentoLight/kento-light.eot?#iefix) format('embedded-opentype'),
             url(./fonts/KentoLight/kento-light.woff2) format('woff2'),
	     url(./fonts/KentoLight/kento-light.woff) format('woff'),
	     url(./fonts/KentoLight/kento-light.ttf) format('truetype'),
	     url(./fonts/KentoLight/kento-light.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KentoRegular';
	src: url(./fonts/KentoRegular/kento-regular.eot);
	src: url(./fonts/KentoRegular/kento-regular.eot?#iefix) format('embedded-opentype'),
             url(./fonts/KentoRegular/kento-regular.woff2) format('woff2'),
	     url(./fonts/KentoRegular/kento-regular.woff) format('woff'),
	     url(./fonts/KentoRegular/kento-regular.ttf) format('truetype'),
	     url(./fonts/KentoRegular/kento-regular.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KentoBold';
	src: url(./fonts/KentoBold/kento-bold.eot);
	src: url(./fonts/KentoBold/kento-bold.eot?#iefix) format('embedded-opentype'),
         url(./fonts/KentoBold/kento-bold.woff2) format('woff2'),
	     url(./fonts/KentoBold/kento-bold.woff) format('woff'),
	     url(./fonts/KentoBold/kento-bold.ttf) format('truetype'),
	     url(./fonts/KentoBold/kento-bold.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Joules';
	src: url(./fonts/joules-et-jaques/joules-et-jaques-hand.otf);
	font-weight: normal;
	font-style: normal;
}


:root {
    --green: #9bcaa6;
    --black: #141515;
}

.kento_light {
    font-family: KentoLight;
}

html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
html.front, html.front>body, html.front #root {
    position: static !important;
}

.label > div
{
    font-family: Helvetica, Arial;
    position: absolute;
    background: #00000088;
    color: white;
    padding: 15px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 30px;
    user-select: none;
    pointer-events: none;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10vw;
    font-family: 'Courier New', Courier, monospace;
}

.list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8vw;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
}

h1 {
    position: absolute;
    margin: 0;
}

.html-mobile {
    position: fixed !important;
}
.html-mobile * {
    pointer-events: none !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.no-scroll {
    overflow: hidden !important;
}

.content {
    width: calc(132px * 5);
    height: calc(273px * 5);
    padding: 0;
    font-size: 37px;
}

.mobile-frame {
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 19%, 75% 19%, 75% 45%, 25% 45%, 25% 100%, 100% 100%, 100% 0%);
    background-color: coral;
}
.mobile-frame p {
    margin: 0;
    padding-top: 640px;
    padding-left: 250px;
}



.menu-fijo-wrapper {
    position: fixed !important;
    top: 0;
    left: 0;
    transform: unset !important;
    pointer-events: none;
}
.menu-fijo {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-fijo img {
    width: calc(100% - 20px);
    height: calc(100% - 80px);
}

.scroll-div {
    left: -50% !important;
}




/* LOADING SCREEN */

.loading-screen {
    width: 100%;
    height: 100%;
    background-color: #9BCAA6;
    z-index: 99999999999;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: skewY(0deg) translateY(0%);
    transition: transform 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.loading-screen.loaded, .horizontal-warning.loaded {
    transform: skewY(6deg) translateY(-130%);
}
/* @media screen and (orientation: portrait) {
    .horizontal-warning.loaded {
        transform: unset !important;
        transition: unset;
    }
} */

.ls__center-div {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-bottom: 4rem;
}
.ls__center-text {
    text-align: center;
}
.ls__center-text h1 {
    font-size: 4.740vw;
    line-height: 1;
    font-family: KentoLight;
    position: relative;
}
.ls__center-text p {
    font-size: 2.5rem;
    line-height: 1.05;
    font-family: Joules;
    margin: 0;
    margin-top: 1rem
}
.cita-header-index {
    margin: 0 !important;
    width: 100% !important;
    text-align: center !important;
}
.loading-screen .header__pretitulo, .pieza-header {
    margin-bottom: 2.5rem;
}

.ls__loading-button {
    position: absolute;
    bottom: 66px;
    left: 50%;
    transform: translate(0, -50%);
}
.ls__loading-text {
    position: absolute;
    transform: translate(-50%, -50%);
    width: max-content;
    font-size: 15px;
    font-family: KentoRegular;
    pointer-events: none;
    z-index: 2;
    transition: all .4s ease;
}
.ls__loading-text:after {
    overflow: hidden;
    display: inline-block;
    position: absolute;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 1900ms infinite;      
    animation: ellipsis steps(4,end) 1900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.05em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.05em;    
    }
  }

.ls__loading-button-main {
    display: flex;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 26px;
    z-index: 1;
    pointer-events: none;
}
.ls__square {
    --size: 9px;
    width: var(--size);
    height: var(--size);
    transform: rotate(45deg);
    border: 1px solid var(--black);
    z-index: 1;
    transition: opacity .5s ease-in-out .8s
}
.ls__line {
    width: 346px;
    height: 1px;
    background-color: var(--black);
    margin-top: 4px;
    transition: opacity 0s linear 1s;
}
.ls__line-remover {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background-color: var(--green);
    transition: width 1s ease-in-out, opacity 0s linear 1s;
}
:root {
    --loading-progress: 0; /* Up to 100 */
}
.ls__diamond {
    position: absolute;
    bottom: -18px;
    transform: scale(1);
    left: calc(-5px + 305px * (var(--loading-progress) * .01));
    transition: left 2s ease, transform .8s ease-in-out, bottom .8s ease-in-out;
}
.ls__diamond svg {
    width: 70px;
    height: 70px;
}

.svg-button {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}
.svg-button .path1 {
    stroke-dasharray: 750;
    stroke-dashoffset: 1000;
    transition: stroke-dasharray 1s ease;
}
.svg-button .path2 {
    stroke-dasharray: 600;
    stroke-dashoffset: 1000;
    transition: stroke-dasharray 1s ease;
}
.svg-button .path3 {
    stroke-dasharray: 600;
    stroke-dashoffset: 1000;
    transition: stroke-dasharray 1s ease
}
.svg-button .line1 {
    stroke-dasharray: 270;
    stroke-dashoffset: 1000;
    transition: stroke-dasharray 1s ease
}
.svg-button .line2 {
    stroke-dasharray: 270;
    stroke-dashoffset: 1000;
    transition: stroke-dasharray 1s ease
}
.svg-button__text {
    opacity: 0;
    font-family: KentoRegular;
    transition: opacity 1s ease
}


.ls__header-logo {
    display: none;
}

@media screen and (max-width: 768px) and (max-height: 600px), (max-width: 600px) {
    .ls__header-logo {
        display: inherit;
    }
    .loading-screen {
        flex-direction: column;
    }

    .loading-screen .pieza-header {
        display: none;
    }
    .loading-screen .header__pretitulo {
        display: flex;
        align-items: center;
        gap: 14px;
        justify-content: center;
        flex-direction: column;
    }
    .loading-screen .cita-header {
        display: none;
    }

    .ls__loading-button {
        position: relative;
        display: block;
        bottom: unset !important;
        left: unset !important;
        transform: unset;
        text-align: center;
        margin-top: 40px;
    }
    .svg-button.ls__start-button {
        position: relative;
        bottom: unset;
        left: unset;
        transform: unset;
    }
    .ls__loading-button-main {
        transform: translateY(-50%);
        top: 50%;
        bottom: unset;
        position: relative;
    }
    .ls__loading-text {
        transform: translateX(50%);
        right: 50%;
        bottom: 0;
    }
    .ls__center-div {
        position: unset !important;
    }
    .loading-screen .header__pretitulo {
        margin-bottom: 50px !important;
    }
}

@media screen and (max-width: 600px) {
    .loading-screen {
        margin-bottom: 0;
        justify-content: space-between;
        padding-top: 20vh;
        padding-bottom: 60px;
    }
    .ls__center-div {
        margin-bottom: 0;
    }
    .loading-screen .header__pretitulo {
        margin-bottom: 69px !important;
    }
    .loading-screen .cita-header {
        display: block;
    }
    .loading-screen .header__titulo {
        margin-bottom: 10px;
    }
    .ls__loading-button {
        margin-top: 15px;
    }
    .svg-button {
        height: 54px;
    }
    .header__titulo {
		width: 100vw;
	}
}





body.loaded .ls__line-remover {
    width: 100%;
    opacity: 0;
}
body.loaded .ls__line {
    opacity: 0;
}
body.loaded .ls__diamond {
    transform: scale(0);
    bottom: -34px;
}
body.loaded .ls__loading-text {
    opacity: 0;
}
body.loaded .ls__square {
    opacity: 0;
}
.svg-button.load {
    pointer-events: inherit !important;
}
.end-button.svg-button.load {
    pointer-events: all !important;
}

.svg-button.load .path1,
.svg-button.load .path2,
.svg-button.load .path3 {
    stroke-dasharray: 500;
}
.svg-button.load .line1,
.svg-button.load .line2 {
    stroke-dasharray: 250;
}
.svg-button.load .svg-button__text {
    opacity: 1;
}

.svg-button.is-disabled {
    opacity: .3;
    pointer-events: none !important;
}



.svg-button.can-hover {
    pointer-events: inherit;
}
.svg-button.can-hover .path1 {
    transition: stroke-dasharray 1.5s ease-in-out;
}
.svg-button.can-hover .path2, 
.svg-button.can-hover .path3 {
    transition: stroke-dasharray 1.5s ease-in-out;
}
.svg-button.can-hover .line1, 
.svg-button.can-hover .line2 {
    transition: stroke-dasharray 1.5s ease-in-out;
}
.svg-button.can-hover .svg-button__text {
    transition: transform 1.5s ease-in-out;
}



@media screen and (min-width: 1025px) {
    .svg-button.can-hover:hover .path1 {
        stroke-dasharray: 1000 !important;
    }
    .svg-button.can-hover:hover .path2, 
    .svg-button.can-hover:hover .path3 {
        stroke-dasharray: 1000 !important;
    }
    .svg-button.can-hover:hover .line1, 
    .svg-button.can-hover:hover .line2 {
        stroke-dasharray: 300 !important;
    }
    .svg-button.can-hover:hover .svg-button__text {
        transform: translate(95.858px, 36.485px) scale(.9) !important;
    }
}

@media screen and (orientation: portrait) {
    .svg-button.only-horizontal {
        display: none !important;
    }
}


/* HUD */

/* .hud {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.hud__wrapper {
    top: 0 !important;
    left: 0 !important;
} */

.hud_wrapper {
    transform: none !important;
}

.hud:not(.show) *:not(.button-start) {
    opacity: 0;
}
.hud *:not(.button-start):not(.hud *:not(.button-start)) {
    transition: opacity .6s ease;
}
/* .hud.show *:not(.button-start) {
    opacity: unset;
} */

.hud {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    pointer-events: none;
}

.button-start {
    font-size: 60px;
    font-family: monospace;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all !important;
    cursor: pointer;
}

.hud__logo {
    position: absolute;
    top: 54px;
    left: 43px;
}
.hud__logo path {
    transition: fill .2s ease;
}
header.experience .header__logo path {
    fill: var(--green) !important;
}
body.dianelumnizer header.experience .header__logo path,
body.show-lantern header.experience .header__logo.is-hovered path, html.positive-part .header__logo path {
    fill: var(--black) !important;
}

:root {
    --hover-multiplier: 1;
}
.logo_hover_area {
    width: calc(350px * var(--hover-multiplier));
    height: calc(350px * var(--hover-multiplier));
    position: absolute;
    top: 0px;
    left: 100px;
    transform: translate(-50%, -50%);
    pointer-events: all;
}
.menu_hover_area {
    width: calc(400px * var(--hover-multiplier));
    height: calc(400px * var(--hover-multiplier));
    position: absolute;
    top: 40px;
    right: calc(-300px * var(--hover-multiplier));
    transform: translate(-50%, -50%);
    pointer-events: all;
}
.rights_hover_area {
    width: calc(200px * var(--hover-multiplier));
    height: calc(400px * var(--hover-multiplier));
    position: absolute;
    top: 50%;
    right: calc(-150px * var(--hover-multiplier));
    transform: translate(-50%, -50%);
    pointer-events: all;
}
.progress_hover_area {
    width: calc(13.021vw * var(--hover-multiplier));
    height: calc(13.021vw * var(--hover-multiplier));
    position: absolute;
    top: 33.333vw;
    left: 4.167vw;
    transform: translate(-50%, -50%);
    pointer-events: all;
}
.arrow_left_hover_area {
    width: calc(10.417vw * var(--hover-multiplier));
    height: calc(10.417vw * var(--hover-multiplier));
    position: absolute;
    top: 3.646vw;
    left: calc(50% - 9.896vw);
    transform: translate(-50%, -50%);
    pointer-events: all;
    z-index: -1;
}
.arrow_right_hover_area {
    width: calc(10.417vw * var(--hover-multiplier));
    height: calc(10.417vw * var(--hover-multiplier));
    position: absolute;
    top: 3.646vw;
    left: calc(50% + 9.896vw);
    transform: translate(-50%, -50%);
    pointer-events: all;
    z-index: -1;
}
.scroll_warning_hover_area {
    width: calc(10.417vw * var(--hover-multiplier));
    height: calc(10.417vw * var(--hover-multiplier));
    position: absolute;
    bottom: -6.250vw;
    left: calc(50%);
    transform: translate(-50%, -50%);
    pointer-events: all;
    z-index: -1;
}

.hud__speaker {
    position: absolute;
    bottom: 48px;
    right: 48px;
    cursor: pointer;
    pointer-events: all;
    z-index: 8;
    width: 20px;
}
.hud__speaker svg {
    width: 100%;
    height: auto;
}

.hud__speaker .bar1 {
    animation: dNone 2s linear infinite;
}
.hud__speaker .bar2 {
    animation: dNone 2s linear .2s infinite;
}
.hud__speaker .bar3 {
    animation: dNone 2s linear .4s infinite;
}
.hud__speaker rect, .hud__speaker path {
    stroke: var(--green);
    transition: .2s stroke ease;
}
body.show-lantern .hud__speaker.is-hovered rect, body.show-lantern .hud__speaker.is-hovered path {
    stroke: var(--black);
}
body.dianelumnizer .hud__speaker rect, body.dianelumnizer .hud__speaker path,
html:not(.is-touch) body.show-lantern .hud__speaker:hover rect, html:not(.is-touch) body.show-lantern .hud__speaker:hover path,
html.positive-part .hud__speaker rect, html.positive-part .hud__speaker path {
    stroke: var(--black) !important;
}

@keyframes dNone {
    0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
    100% {
        opacity: 0;
    }
  }

.hud__hamburger {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    pointer-events: all !important;
}

.hud__scroll {
    position: absolute;
    bottom: 0px;
    left: calc(50%);
    transform: translateX(-50%);
    width: 1.563vw;
    height: 2.604vw;
    pointer-events: none;
    transition: opacity .4s ease;
}
html.last-step .hud__scroll,
.hud__scroll.hide-scroll-warning {
    opacity: 0 !important;
}

.scroll__text {
    font-size: 12px;
    font-family: KentoLight;  
    position: absolute;
    bottom: 1.042vw;
    left: -0.781vw;
    color: var(--green);
    transition: color .2s ease;  
}
html.first-step .scroll__text,
html.positive-part .scroll__text,
.hud__scroll.is-hovered .scroll__text {
    color: var(--black);
}

.scroll__finger, .scroll__wheel, .scroll__lines {
    position: absolute;
}
.scroll__finger {
    bottom: 2.083vw;
    width: 1.563vw;
    animation: scroll-finger 1s linear infinite;
}
.scroll__finger path {
    stroke: var(--green);
    transition: stroke .2s ease;
}

html.first-step .scroll__finger path,
html.positive-part .scroll__finger path,
body.show-lantern .hud__scroll.is-hovered .scroll__finger path {
    stroke: var(--black);
}

.scroll__wheel-lines {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 4.688vw;
    width: 1.094vw;
    margin-left: 0.260vw;
    height: 2.396vw;
    overflow: hidden;
    animation: scroll-wheel 1s linear infinite;
}
.scroll__wheel {
    width: 1.094vw;
    height: 2.396vw;
}
.scroll__wheel rect {
    stroke: var(--green);
    transition: stroke .2s ease;
}

html.first-step .scroll__wheel rect,
html.positive-part .scroll__wheel rect,
body.show-lantern .hud__scroll.is-hovered .scroll__wheel rect {
    stroke: var(--black);
}

.scroll__lines {
    transform: translateY(0px);
    animation: scroll-lines 1s linear infinite;
}
.scroll__lines line {
    stroke: var(--green);
    transition: stroke .2s ease;
}

html.first-step .scroll__lines line,
html.positive-part .scroll__lines line,
body.show-lantern .hud__scroll.is-hovered .scroll__lines line {
    stroke: var(--black);
}

@keyframes scroll-finger {
    0%   {transform: translateY(-1.563vw);}
    40%  {transform: translateY(-0.573vw);}
    100%  {transform: translateY(-1.563vw);}
}
@keyframes scroll-wheel {
    0%   {clip-path: polygon(0% 0%, 0 60%, 15% 56%, 30% 54%, 50% 52%, 69% 53%, 84% 56%, 100% 61%, 100% 0);}
    40%   {clip-path: polygon(0% 0%, 0 100%, 13% 95%, 29% 92%, 47% 91%, 66% 92%, 81% 94%, 100% 99%, 100% 0);}
    100%   {clip-path: polygon(0% 0%, 0 60%, 15% 56%, 30% 54%, 50% 52%, 69% 53%, 84% 56%, 100% 61%, 100% 0);}
}
@keyframes scroll-lines {
    0%   {transform: translateY(-0.938vw); }
    40%  {transform: translateY(0px); }
}

html.is-touch .hud__scroll {
    opacity: 0 !important;
}

.hud__rights {
    color: var(--green);
    font-size: 10px;
    font-family: KentoLight;
    -webkit-font-smoothing: subpixel-antialiased;
    position: absolute;
    top: 50%;
    right: -80px;
    transform: translateY(-50%) rotate(-90deg);
    transition: color .2s ease;
}
body.show-lantern .hud__rights.is-hovered, html.positive-part .hud__rights, body.dianelumnizer .hud__rights {
    color: var(--black);
}

@media screen and (max-width: 600px) {
    .hud__rights {
        display: none !important;
    }
}

.hud__progress {
    position: absolute;
    top: calc(50% - 1.302vw);
    left: 1.563vw;
    transform: translateY(-50%);
}
.hud__progress-gradient {
    position: absolute;
    top: calc(50% + 1.302vw);
    left: 0px;
    transform: translateY(-50%);
    z-index: -1;
}
.hud__progress-svg {
    position: absolute;
    top: calc(50%);
    left: 0px;
    transform: translateY(-50%);
    z-index: 1;
}

.hud__progress-info {
    position: absolute;
    top: calc(50% + 17.969vw);
    left: 0px;
    transform: translateY(-50%);
}
.hud__progress-info * {
    color: var(--green);
    transition: color .2s ease;
}
body.show-lantern .hud__progress-info.is-hovered *, html.positive-part .hud__progress-info * {
    color: var(--black);
}
.hud__progress-info p {
    margin: 0 1.302vw;
    width: max-content;
}
.hud__km {
    font-size: 12px;
    font-family: KentoLight;
}
.hud__step {
    font-size: 14px;
    font-family: KentoRegular;
}

.hud__progress-bar {
    margin-top: 3.125vw;
    margin-left: 1.4vw;
    height: 26.042vw;
    width: 1px;
    position: relative;
    background-color: var(--green);
    transition: background-color .4s ease;
}
html.positive-part .hud__progress-bar {
    background-color: var(--black);
}

:root {
    --hud-point-size: 9px;
}
.hud__progress-point {

    position: absolute;
    left: -5px;

    transform: rotate(45deg) translate(-2px, -3px);
    width: var(--hud-point-size);
    height: var(--hud-point-size);
    background-color: var(--black);
    border: 1px solid var(--green);
    transition: background-color .4s cubic-bezier(.3,.86,.36,.95);
}
.hud__progress-point:first-of-type {
    background-color: var(--green);
}
.hud__progress-point.reached:not(.hud-point-0) {
    background-color: var(--green);
    animation: pointReached 1.1s ease;
}

html.positive-part .hud__progress-point {
    background-color: #575857;
    border-color: var(--black);
}
html.positive-part .hud__progress-point:first-of-type {
    background-color: var(--black);
}
html.positive-part .hud__progress-point.reached:not(.hud-point-0) {
    background-color: var(--black);
}

@keyframes pointReached {
    0%   {transform: rotate(45deg) translate(-2px, -3px) scale(1)}
    50%  {transform: rotate(45deg) translate(-2px, -3px) scale(1.6)}
    100% {transform: rotate(45deg) translate(-2px, -3px) scale(1)}
}

:root {
    --cross-size: 39px;
}

.hud__progress-indicator {
    width: var(--cross-size);
    position: absolute;
    top: 0;
    left: calc(var(--cross-size) / 2 * -1);
    
}

.hpi__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(0deg, rgba(150,223,163,0.700717787114846) 0%, rgba(0,0,0,0) 100%);
    transition: background .2s ease
}
html.positive-part .hpi__bg {
    background: linear-gradient(0deg, rgba(192,235,202.700717787114846) 0%, rgba(0,0,0,0) 100%);
}

.hpi__cross {
    position: absolute;
    bottom: calc(var(--cross-size) / 2 * -1);
    left: 50%;
    transform: translateX(-50%);
    height: var(--cross-size);
    width: var(--cross-size);
    z-index: -2;
}
.hpi__cross::before {
    content: '';
    position: absolute;
    top: 0;
    left: 51%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: var(--green);
    transition: background-color .4s ease;
}
.hpi__cross::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: var(--green);
    transition: background-color .4s ease;
}
html.positive-part .hpi__cross::before,
html.positive-part .hpi__cross::after {
    background-color: var(--black);
}
.hpi__cross-point {
    transform: rotate(45deg) translate(calc(-50%), calc(-50%));
    bottom: calc(var(--cross-size) / 2 * -1 + 9px);
    left: calc(50% - 4px);
    position: absolute;    
    z-index: 1;
    width: var(--hud-point-size);
    height: var(--hud-point-size);
    background-color: #81BC8F;
    border: 1px solid var(--green);
    transition: background-color .4s cubic-bezier(.3,.86,.36,.95);
}
html.positive-part .hpi__cross-point {
    border-color: var(--black);
}
.hud__km-counter {
    position: fixed;
}

.hud__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    transition: gap 1s ease;
}


.hud-arrow {
    width: 135px;
    height: 62px;
    position: relative;
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    overflow: hidden;
    cursor: pointer;
    pointer-events: all !important;
    transition: transform 3s ease-in-out, opacity 2s ease;
}
.hud-arrow path {
    transition: fill .4s ease;
}
html.first-step:not(.is-touch) .hud-arrow path,
html.positive-part .hud-arrow path,
.hud-arrow.is-hovered path,
html:not(.is-touch) .hud-arrow:hover path,
body.dianelumnizer .hud-arrow path {
    fill: var(--black) !important;
}
.hud-arrow.is-disabled {
    pointer-events: none !important;
    opacity: 0 !important;
}
html body .hud-arrow.is-disabled path {
    fill: rgb(110, 110, 110) !important;
}
body.camera-moving .hud-arrow, body:not(.can-interact) .hud-arrow {
    opacity: .2;
    pointer-events: none !important;
}
/* html body:not(.can-interact) .hud-arrow:not(.is-disabled) {
    opacity: 0 !important;
    pointer-events: none !important;
} */
@keyframes arrowWaiting {
    0% {
        opacity: 1
    }
    25% {
        opacity: .5
    }
    50% {
        opacity: 1
    }
    75% {
        opacity: .5
    }
    100% {
        opacity: 1
    }
}

.hud-arrow__bg-first,
.hud-arrow__bg-warning,
.hud-arrow__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 100%;
    background-color: var(--green);
/*     -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
    transform: rotate(25deg);
    
    transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hud-arrow.left .hud-arrow__bg-first,
.hud-arrow.left .hud-arrow__bg-warning,
.hud-arrow.left .hud-arrow__bg {
    transform: rotate(-25deg);
}
.hud-arrow.right .hud-arrow__bg-warning {
    width: 120%;
    background-color: #fff;
    animation-name: bgWarn;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
html:not(.first-step) .hud-arrow__bg-warning {
    opacity: 0 !important;
}
@keyframes bgWarn {
    0% {
        top: -120%;
        left: 120%;
        height: 250%;
        opacity: .5;
    }
    15% {
        height: 10%;
        opacity: .5;
    }
    30% {
        top: 120%;
        left: -120%;
        height: 250%;
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
    
}


html:not(.is-touch) .hud-arrow:hover .hud-arrow__bg {
    top: 0 !important;
    left: 0 !important;
}
.hud-arrow .hud-arrow__icon {
    transition: all 0.6s ease;
    z-index: 2;
}
html:not(.is-touch) .hud-arrow:hover .hud-arrow__icon {
    transform: translateX(-8px);
}
html.first-step .hud-arrow__bg-first {
    top: 0;
    left: 0;
}

.hud-arrow__outline {
    position: absolute;
    z-index: 1;
}
html.first-step .hud-arrow__outline {
    opacity: 0;
}
.hud-arrow__icon {
    position: absolute;
}

.hud-arrow.right {
    transform: rotate(180deg);
}
.hud-arrow.right .hud-arrow__bg {
    top: -100%;
}

html.last-step:not(.is-touch) .hud-arrow.left {
    transform: translate(calc(50% - 9.896vw), 0px);
}
html.last-step:not(.is-touch) .hud-arrow.right {
    transform: translateX(30px) rotate(180deg);
}

.end-button {
    position: absolute;
    bottom: 41px;
    z-index: 10;
}

.mobile-steps-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    z-index: -1;
    text-align: center;
    transition: backdrop-filter 1s ease;
}
.mobile-steps-screen.is-active {
    backdrop-filter: blur(26px);
}

.mss__text {
    max-width: 340px;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.mss__text h1 {
    font-size: 28px;
    line-height: 1;
    font-family: KentoRegular;
    font-weight: 500;
    transform: translateY(20px);
    opacity: 0;
    position: unset;
    transition: all .4s ease-in-out;
}
.mss__text.is-active h1 {
    transform: translateY(0px);
    opacity: 1;
}
.mss__text p {
    font-size: 15px;
    line-height: 19px;
    transform: translateY(30px);
    opacity: 0;
    margin: 0;
    margin-top: 20px;
    transition: all .4s ease-in-out;
}
.mss__text.is-active p {
    transform: translateY(0px);
    opacity: 1;
}
.mss__button {
    width: 170px;
    height: 78px;
    background-color: var(--black);
    color: var(--green);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease;
}
.mss__button.is-active {
    opacity: 1;
    pointer-events: all !important;
}
.mss__button span {
    font-size: 11px;
    font-family: KentoRegular;
    opacity: 0;
    position: absolute;
    transition: all .4s ease-in-out;
}
.mss__button span.is-active {
    opacity: 1;
}






.global-scroller {
    overflow: hidden auto;
}


header.experience {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    pointer-events: none;
}
header.experience .header__menu-content {
    pointer-events: all;
}
body:not(.loaded) header.experience .header__menu-content {
    pointer-events: none !important;
}

/* header.experience .header__logo {
    display: none !important;
} */

header.experience .header__menu,
header.experience .header__menu .header__menu-c1,
header.experience .header__menu .header__menu-c2 {
    border-color: var(--colorVerde);
}

header.experience .header__menu .header__menu-l1,
header.experience .header__menu .header__menu-l2,
header.experience .header__menu .header__menu-l3 {
    background-color: var(--colorVerde);
}

body.show-lantern:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close),
body.show-lantern:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-c1,
body.show-lantern:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-c2 {
    border-color: var(--black);
}

body.dianelumnizer:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l1,
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l2,
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l3,
body.show-lantern header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l1,
body.show-lantern header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l2,
body.show-lantern header.experience .header__menu.is-hovered:not(.header__menu-close) .header__menu-l3 {
    background-color: var(--black);
}

body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close),
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-c1,
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-c2,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover .header__menu-c1,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover .header__menu-c2,
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close),
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-c1,
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-c2 {
    border-color: var(--black);
}

body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l1,
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l2,
body.dianelumnizer:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l3,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover .header__menu-l1,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover .header__menu-l2,
html:not(.is-touch) body.show-lantern:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close):hover .header__menu-l3,
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l1,
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l2,
html.positive-part body:not(.activeMenuB) header.experience .header__menu:not(.header__menu-close) .header__menu-l3 {
    background-color: var(--black);
}

.menu__btn-contacto,
.menu__btn-rs,
.menu .menu__btn-invierte {
    pointer-events: all;
}

:root {
    --color-switch-off: #2F302F;
    --color-switch-on: #5A8364;
}

.light-switch {
    height: 37px;
    width: 66px;
    border-radius: calc(66px / 2);
    position: absolute;
    bottom: 40px;
    border: 1px solid var(--green);
    background-color: var(--color-switch-off);
    pointer-events: all;
    transition: background-color .4s ease, opacity .4s ease;
}
.light-switch.is-hidden {
    opacity: 0;
    pointer-events: none !important;
}
.light-switch.is-active {
    background-color: var(--color-switch-on);
}
.light-switch__ball {
    --size: 31px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid var(--green);
    background-color: var(--color-switch-off);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left .4s ease;
}
.light-switch.is-active .light-switch__ball {
    left: calc(100% - (var(--size) + 2px))
}
.light-switch__ball svg {
    width: 12px;
    height: 12px;
}
.light-switch__text {
    position: absolute;
    color: var(--green);
    bottom: 55px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-family: KentoRegular;
    transition: color .4s ease;
}
body.dianelumnizer .light-switch__text {
    color: var(--black);
}
.light-switch.waiting svg {
    animation: dianelumnizerSvg 3s ease-in-out infinite;
}

@keyframes dianelumnizerSvg {
    0% {
        transform: scale(1)
    }
    25% {
        transform: scale(1.5)
    }
    50% {
        transform: scale(1)
    }
    75% {
        transform: scale(1.5)
    }
    100% {
        transform: scale(1)
    }
}

.horizontal-warning {
    display: none !important;
}

.horizontal-warning {
    width: 100%;
    height: 100%;
    background-color: var(--green);
    z-index: 99999999;
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    transition: transform 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.horizontal-warning .svg-button.ls__start-button {
    position: relative;
    bottom: unset;
    left: unset;
    transform: unset;
}

.hw__mobile-wrapper, .hw__switch-wrapper {
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hw__switch-wrapper path {
    animation: switchSvg 3s ease-in-out infinite;
}
@keyframes switchSvg {
    0% {
        transform: translate(50px, 4px)
    }
    25% {
        transform: translate(5px, 4px)
    }
    50% {
        transform: translate(50px, 4px)
    }
    100% {
        transform: translate(50px, 4px)
    }
}

.hw__switch-wrapper #Grupo_7611 {
    animation: switchStarSvg 3s ease-in-out infinite;
}
@keyframes switchStarSvg {
    0% {
        transform: translate(65px, 19px);
    }
    25% {
        transform: translate(19px, 19px)
    }
    50% {
        transform: translate(65px, 19px)
    }
    100% {
        transform: translate(65px, 19px)
    }
}



.hw__svg {
    width: 100px !important;
    height: 100px !important;
}

.hw__items {
    display: flex;
    gap: 32px;
    /* margin-top: 60px; */
}
.hw__item {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    text-align: center;
}

.hw__item-step {
    font-size: 14px;
    margin-bottom: 8px;
}
.hw__item-desc {
    font-size: 16px;
    line-height: 20px;
    max-width: 100%;
}

.hw__bottom {
    max-width: 400px;
    width: calc(100% - 100px);
    text-align: center;
}
.hw__warn {
    font-size: 16px;
    font-family: KentoRegular;
    margin: 0;
}
.hw__title {
    font-size: 30px;
    font-family: KentoRegular;
    line-height: 1.1;
    margin: 0;
    margin-bottom: 15px;
}
.hw__text {
    font-size: 15px;
    line-height: 1.2;
    margin: 0;
}
.hw__svg-mobile {
    height: 50px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    animation: mobileSvg 4s ease-in-out infinite;
}
@keyframes mobileSvg {
    0% {
        transform: translate(-50%, -50%) rotate(90deg)
    }
    25% {
        transform: translate(-50%, -50%) rotate(0deg)
    }
    50% {
        transform: translate(-50%, -50%) rotate(90deg)
    }
}
.hw__logo {
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translate(-50%);
}

.hw__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}
.hw__waiting-step1 {
    font-size: 14px;
}

@media screen and (max-width: 1180px),
screen and (max-width: 1180px) and (-Webkit-min-device-pixel-ratio: 1.5), 
screen and (max-width: 1180px) and (-moz-min-device-pixel-ratio: 1.5),
screen and (max-width: 1180px) and (-o-min-device-pixel-ratio: 3/2),
screen and (max-width: 1180px) and (min-device-pixel-ratio: 1.5),
screen and (hover: none)

 {
    .horizontal-warning {
        display: flex !important;
    }
    .hud__speaker {
        top: 3.5vw;
        right: 10.5vw;
    }
    .hud__controls {
        height: 120px;
    }
    .ls__diamond {
        bottom: -14px
    }
    body.loaded .ls__diamond {
        bottom: -32px
    }
    .cita .cita__title {
        font-size: 3.3vw;
    }
    .cita-header {
        margin-top: 12px;
    }
    .pieza-header {
        margin-bottom: 1rem;
    }
    html.first-step.is-touch body .hud-arrow path {
        fill: var(--black) !important
    }
    .hud__progress-info {
        display: none;
    }
    .hud__progress-bar {
        margin-left: 35px;
        height: 492px;
    }
    .hud__rights {
        right: -110px;
    }
    html.first-step .light-switch__text {
        color: var(--black)
    }
    body.bodyExperiencia .menu {
        z-index: 999999999 !important;
    }
    html.mobile-end-part .hud__controls {
        gap: 26vw;
    }
    
}

@media screen and (max-width: 1180px) and (min-width: 769px) {
    .mss__text h1 {
        font-size: 58px;
    }
    .mss__text p {
        max-width: 350px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .mss__text {
        top: 50%;
        max-width: 600px;
    }
}

@media screen and (max-width: 1179px) and (max-height: 400px) {
    .hud__progress, .hud__rights {
        display: none;
    }
    .hud__controls {
        justify-content: space-between;
        padding: 0 30px;
    }
    .hud-arrow, .hud-arrow svg {
        width: 130px;
        height: 58px;
    }
    .light-switch {
        left: 50%;
        transform: translateX(-50%);
    }
    .hud-arrow__bg-first {
        display: none !important;
    }
    html.first-step .hud-arrow__outline {
        opacity: 1;
    }
    .light-switch__text {
        bottom: -20px;
        font-size: 8px;
    }
    html.first-step.is-touch .light-switch__text {
        color: var(--black) !important
    }
    .pieza-header {
        margin-bottom: .5rem
    }
    .loading-screen .header__pretitulo {
        margin-bottom: 1.5rem
    }
    .ls__center-text h1 {
        font-size: 4.14vw;
    }
    .ls__center-div {
        margin-bottom: 0;
        position: absolute;
        top: 40px;
    }
    .ls__loading-button {
        bottom: 40px;
    }
    .ls__diamond svg {
        width: 55px;
        height: 55px;
    }
    .ls__diamond {
        bottom: -10px;
    }
    body.loaded .ls__diamond {
        bottom: -24px;
    }
    .cita .cita__title {
        font-size: 25px;
    }
    .cita-header {
        margin-top: 8px;
    }
    html.first-step .light-switch__text {
        color: var(--green)
    }
    html.first-step.is-touch body .hud-arrow path {
        fill: var(--green) !important
    }
    html.first-step.is-touch body.dianelumnizer .hud-arrow path {
        fill: var(--black) !important
    }
    .header__menu .header__menu-c1, .header__menu .header__menu-c2 {
        top: 11px;
        left: 8px;
        width: 49px;
        height: 44px;
    }
    header .header__menu-content {
        right: 20px;
        top: 16px;
        width: 68px;
        height: 68px;
    }
    .header__menu {
        width: 68px;
        height: 68px;
    }
    .header__menu .header__menu-l1, .header__menu .header__menu-l2, .header__menu .header__menu-l3 {
        top: 32px;
        width: 20px;
    }
    header .header__logo, header .header__logo a svg {
        width: 147px;
    }
    header .header__logo {
        left: 30px;
        top: 40px;
    }



    /* DUPLICATED */
    .mss__text {
        max-width: 490px;
        width: 100%;
        position: absolute;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
    
    .mss__text h1 {
        font-size: 28px;
        line-height: 1;
        font-family: KentoRegular;
        font-weight: 500;
        transform: translateY(20px);
        opacity: 0;
        position: unset;
        transition: all .4s ease-in-out;
    }
    .mss__text.is-active h1 {
        transform: translateY(0px);
        opacity: 1;
    }
    .mss__text p {
        font-size: 15px;
        line-height: 19px;
        transform: translateY(30px);
        opacity: 0;
        margin: 0 auto;
        margin-top: 20px;
        transition: all .4s ease-in-out;
    }
    .mss__text.is-active p {
        transform: translateY(0px);
        opacity: 1;
    }
    .mss__button {
        width: 170px;
        height: 78px;
        background-color: var(--black);
        color: var(--green);
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: all .4s ease;
    }
    .mss__button.is-active {
        opacity: 1;
        pointer-events: all !important;
    }
    .mss__button span {
        font-size: 11px;
        font-family: KentoRegular;
        opacity: 0;
        position: absolute;
        transition: all .4s ease-in-out;
    }
    .mss__button span.is-active {
        opacity: 1;
    }
}

@media screen and (max-width: 600px) {
    .ls__line {
        width: 233px;
    }
    .ls__diamond {
        left: calc(-5px + 192px * (var(--loading-progress) * .01));
    }
    /* .hw__svg-mobile, .hw__svg {
        top: 35%;
    } */
    .ls__center-text h1 {
        font-size: 26px;
        line-height: 1.5;
    }
    html.is-touch .ani_ini_mask {
        overflow: visible !important;
    }
    .split-line {
        height: 35px;
    }
    .cita-header {
        margin-top: 25px;
    }
    .loading-screen .header__pretitulo {
        margin-bottom: 3.5rem;
    }
    .cita .cita__title {
        font-size: 30px !important;
    }
    .loading-screen .header__pretitulo {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
    }
    .pieza-header {
        top: 40px;
        position: absolute;
    }
    .horizontal-warning {
        gap: 50px;
    }
}

@media screen and (orientation: landscape) {
    .horizontal-warning {
        flex-direction: row;
        padding: 45px;
    }
    .hw__button-container {
        display: none !important;
    }
    .hw__bottom {
        text-align: left;
    }
    .hw__text {
        margin-bottom: 40px;
    }
    .svg-button.only-horizontal {
        height: 54px;
        width: 160px;
    }
}

@media screen and (orientation: landscape) and (max-width: 1000px) {
    .hw__svg {
        width: 66px !important;
        height: 66px !important;
    }
    .hw__svg-mobile {
        height: 36px !important;
    }
    .hw__svg-mobile rect, .hw__svg-mobile line,
    .hw__svg path {
        stroke-width: 2 !important;
    }
    .hw__switch-wrapper svg {
        height: 40px;
    }
    .hw__item-desc {
        font-size: 15px;
    }
    .hw__title {
        font-size: 28px;
    }
    .hw__item {
        max-width: 280px;
    }
    .horizontal-warning {
        padding: 45px 40px;
    }
}
.menu {
    z-index: 99999999 !important;
}

:root {
    --cookie-angle: 40px;
}

.cookie-popup {
    z-index: 999999999999;
    position: fixed;
    bottom: 30px;
    left: 30px;
    padding: 40px 50px 45px;
    background-color: #101010;
    pointer-events: none;
    opacity: 0;
    max-width: 600px;
    width: calc(100% - 60px);
    -webkit-clip-path: polygon(var(--cookie-angle) 0%, calc(100% - var(--cookie-angle)) 0%, 100% var(--cookie-angle), 100% calc(100% - var(--cookie-angle)), calc(100% - var(--cookie-angle)) 100%, var(--cookie-angle) 100%, 0% calc(100% - var(--cookie-angle)), 0% var(--cookie-angle));
    clip-path: polygon(var(--cookie-angle) 0%, calc(100% - var(--cookie-angle)) 0%, 100% var(--cookie-angle), 100% calc(100% - var(--cookie-angle)), calc(100% - var(--cookie-angle)) 100%, var(--cookie-angle) 100%, 0% calc(100% - var(--cookie-angle)), 0% var(--cookie-angle));
    transition: opacity .4s ease;
}
.cookie-popup.--show {
    pointer-events: auto;
    opacity: 1;
}



.cookie-popup p, .cookie-popup a {
    color: var(--green);
    font-size: 16px;
    line-height: 20px;
}
.cookie-popup p {
    margin-bottom: 40px;
}

.cookie-button {
    position: static !important;
    pointer-events: all;
    transform: unset;
    bottom: unset;
    left: unset;
    width: 165px !important;
    height: 55px !important;
}
.first-cookie-button {
    margin-right: 25px;
}

.cookie-button path, .cookie-button line {
    stroke: var(--green) !important;
}
.cookie-button text {
    fill: var(--green) !important;
}

.cc__wrapper {
    z-index: 999999999999;
    position: fixed ;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: opacity .4s ease;
    opacity: 0;
}

.cookie-configuration {
    position: relative;
    /* position: fixed;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%; */
    
    background-color: #101010;
    -webkit-clip-path: polygon(var(--cookie-angle) 0%, calc(100% - var(--cookie-angle)) 0%, 100% var(--cookie-angle), 100% calc(100% - var(--cookie-angle)), calc(100% - var(--cookie-angle)) 100%, var(--cookie-angle) 100%, 0% calc(100% - var(--cookie-angle)), 0% var(--cookie-angle));
    clip-path: polygon(var(--cookie-angle) 0%, calc(100% - var(--cookie-angle)) 0%, 100% var(--cookie-angle), 100% calc(100% - var(--cookie-angle)), calc(100% - var(--cookie-angle)) 100%, var(--cookie-angle) 100%, 0% calc(100% - var(--cookie-angle)), 0% var(--cookie-angle));
    color: var(--green);
    padding: 50px;
    max-width: 700px;
    max-height: 79vh;
    font-size: 16px;
    line-height: 20px;
}
.cc__wrapper.--show {
    opacity: 1;
    pointer-events: auto;
}



.cc__close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cc__close svg {
    transition: transform .4s ease;
}
.cc__close:hover svg {
    transform: scale(1.2) rotate(-90deg)
}

.cc__scrollable {
    overflow: auto;
    height: 50vh;
    scrollbar-width: auto;
    scrollbar-color: var(--green) #101010;
    padding-right: 20px;
}

/* Other browsers */
.cc__scrollable-mobile::-webkit-scrollbar, .cc__scrollable::-webkit-scrollbar {
    width: 13px;
}

.cc__scrollable-mobile::-webkit-scrollbar-track, .cc__scrollable::-webkit-scrollbar-track {
    background: #101010;
}

.cc__scrollable-mobile::-webkit-scrollbar-thumb,  .cc__scrollable::-webkit-scrollbar-thumb {
    background-color: var(--green);
    border-radius: 20px;
    border: 2px solid #101010;
}

.cc__info {
    overflow: hidden;
    height: 90px;
}
.cc__info.read-more {
    height: unset;
}

.cc__read-more {
    font-size: 14px;
    margin-bottom: 40px;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
}

.cc__title {
    font-size: 22px;
    font-weight: 700;
}

.cc__table-item {
    border-top: 1px solid var(--green);
    padding-top: 20px;
    padding-bottom: 10px;
}

.cc__table-item-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cc__buttons {
    padding-top: 30px
}

.cc__switch[type="checkbox"] {
    position: relative;
    width: 44px;
    height: 24px;
    margin: 0;
    background: #d0d5d2;
    -webkit-appearance: none;
    border-radius: 50px;
    cursor: pointer;
    outline: 0;
    border: none;
}
.cc__switch[type="checkbox"]:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    margin: 0;
}

.cc__switch[type="checkbox"]:checked {
    background: var(--green);
}
.cc__switch[type="checkbox"]:checked:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

body.cookies-accepted .cookie-popup,
body.cookies-accepted .cc__wrapper {
    display: none !important;
}


@media screen and (max-width: 1180px) {
    .cookie-popup {
        padding: 30px 40px 35px;
    }
    .cookie-configuration {
        padding: 35px
    }
}

@media screen and (max-width: 768px) {
    .cookie-configuration {
        width: calc(100% - 60px);
        max-height: calc(100% - 60px);
    }
    .cookie-popup {
        max-width: unset;
        padding: 20px 35px 30px;
    }
    .cc__scrollable-mobile {
        overflow: auto;
        width: 100%;
        height: calc(100vh - 280px);
        padding-bottom: 40px;
    }
    .cc__scrollable {
        height: unset;
    }
    .cc__info {
        height: 100px;
    }
}

@media screen and (max-width: 600px) {
    .cookie-popup {
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px 30px 25px;
    }
    .cp__buttons {
        width: 100%;
        text-align: center;
    }

    .first-cookie-button {
        margin: 0;
        margin-bottom: 20px;
    }

    .cookie-configuration {
        padding: 30px;
    }
    .cc__info {
        height: 210px;
    }
    
}

.info-icon {
    display: none;
}

.mobile-speaker svg.volume-on path:nth-of-type(1) {
    animation: fadeSpeakerMobile 1s infinite .4s;
}
.mobile-speaker svg.volume-on path:nth-of-type(2) {
    animation: fadeSpeakerMobile 1s infinite .2s;
}
.mobile-speaker svg.volume-on path:nth-of-type(3) {
    animation: fadeSpeakerMobile 1s infinite;
}

@keyframes fadeSpeakerMobile {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
    .hud__progress {
        display: none;
    }
    .hud__speaker {
        top: 40px;
        right: 105px;
    }
}

.hud__controls .hud__progress {
    display: none;
}

/* .mobile-scroll-advice {
    display: none;
} */
.mobile-scroll-advice {
    display: block;
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transition: opacity .3s ease 2s;
}
body.moved .mobile-scroll-advice {
    opacity: 0 !important;
}

.mobile-scroll-advice p {
    font-size: 14px;
    line-height: 17px;
    font-family: 'KentoRegular';
    text-align: center;
    color: var(--green);
}

html.positive-part .mobile-scroll-advice p {
    color: var(--black);
}

html.last-step .mobile-scroll-advice,
.mobile-scroll-advice.hide-scroll-warning, .mobile-scroll-advice.remove-for-good {
    opacity: 0 !important;
    transition: opacity .3s ease;
}
@media screen and (max-width: 600px) and (orientation: portrait) {
    .cp__buttons {
        display: flex;
        gap: 30px;
    }

    .cookie-button {
        height: 40px !important;
    }
    .cc__buttons {
        display: flex;
        gap: 20px;
        padding-right: 20px;
    }

    .hud-arrow {
        display: none !important;
    }

    .mobile-scroll-advice {
        bottom: 110px;
    }

    .hud__speaker {
        top: 30px !important;
        right: 100px !important;
        width: 18px !important;
    }

    .info-icon {
        display: block;
        height: 36px;
        width: 36px;
        pointer-events: all;
    }
    
    .light-switch {
        position: relative;
        bottom: unset;
    }
    
    .hud__controls {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
    }

    .hud__progress-bar {
        /* width: 170px;
        height: 1px; */
        margin-left: 0;
        height: 170px;
    }

    .hud__controls .hud__progress {
        display: block;
    }

    .hud__progress {
        transform: rotate(-90deg);
        position: relative;
        top: unset;
        left: unset;
        transition: opacity .3s ease;
    }

    .mobile-end-part .hud__progress {
        opacity: 0;
    }

    
    
}

#leva__root {
	display: none !important;
}