@font-face {
	font-family: 'KentoLight';
	src: url(../fonts/KentoLight/kento-light.eot);
	src: url(../fonts/KentoLight/kento-light.eot?#iefix) format('embedded-opentype'),
		url(../fonts/KentoLight/kento-light.woff2) format('woff2'),
		url(../fonts/KentoLight/kento-light.woff) format('woff'),
		url(../fonts/KentoLight/kento-light.ttf) format('truetype'),
		url(../fonts/KentoLight/kento-light.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'KentoRegular';
	src: url(../fonts/KentoRegular/kento-regular.eot);
	src: url(../fonts/KentoRegular/kento-regular.eot?#iefix) format('embedded-opentype'),
		url(../fonts/KentoRegular/kento-regular.woff2) format('woff2'),
		url(../fonts/KentoRegular/kento-regular.woff) format('woff'),
		url(../fonts/KentoRegular/kento-regular.ttf) format('truetype'),
		url(../fonts/KentoRegular/kento-regular.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KentoBold';
	src: url(../fonts/KentoBold/kento-bold.eot);
	src: url(../fonts/KentoBold/kento-bold.eot?#iefix) format('embedded-opentype'),
		url(../fonts/KentoBold/kento-bold.woff2) format('woff2'),
		url(../fonts/KentoBold/kento-bold.woff) format('woff'),
		url(../fonts/KentoBold/kento-bold.ttf) format('truetype'),
		url(../fonts/KentoBold/kento-bold.svg#youworkforthem) format('svg');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

@font-face {
	font-family: 'JoulesEtJaques';
	src: url(../fonts/joules-et-jaques/joules-et-jaques-hand-upright.otf) format('opentype');
	font-weight: normal;
	font-style: normal;
}

:root {
	--colorNegro: #141515;
	--colorVerde: #9bcaa6;
	--colorVerdeOsc: #81bc8f;

	--tamBorder: 1px;

	--aniBtnMenu: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
	--aniBtnRombo: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
	--aniBtnSlider: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
	--aniCards: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
	--aniListadoItem: all 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

*,
::after,
::before {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
}

body {
	margin: 0px;
	padding: 0px;
	font-family: 'Work Sans', sans-serif;
	font-size: 0.938vw;
	line-height: 1.302vw;
}

.container {
	width: 100%;
}

header {
	position: fixed;
	top: -150px;
	left: 0px;
	width: 100%;
	z-index: 15;
}

header .header__logo {
	position: absolute;
	top: 2.6vw;
	left: 1.667vw;
	width: 14vw;
	height: auto;
}

header .header__logo a {
	display: block;
	width: 13vw;
	overflow: hidden;
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
	transition-delay: 0s;
}

header .header__logo a svg {
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);

}

header .header__logo a svg.header__logo_logo {
	width: 3.594vw;
	height: auto;
	float: left;
	margin-right: 0.781vw;
}

header .header__logo a svg.header__logo_txt {
	width: 8.513vw;
	height: auto;
	float: left;
	margin-top: 0.65vw;
	position: absolute;
}

body.scroll-down header .header__logo a svg.header__logo_txt {
	opacity: 0;
}

body.scroll-down header .header__logo a {
	width: 4vw;
	transition-delay: 1s;
}

header .header__logo a svg path {
	fill: var(--colorNegro);
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
}

header.activeMenu {
	z-index: 999999999;
}

header.h-verde .header__logo a svg path,
header.activeMenu .header__logo a svg path {
	fill: var(--colorVerde) !important;
}

header.h-verde .header__menu .header__menu-l1,
header.h-verde .header__menu .header__menu-l2,
header.h-verde .header__menu .header__menu-l3,
header.h-verde .header__menu .header__menu-c1,
header.h-verde .header__menu .header__menu-c2,
header.h-verde .header__menu {
	border-color: var(--colorVerde);
}

header.h-verde .header__menu .header__menu-l1,
header.h-verde .header__menu .header__menu-l2,
header.h-verde .header__menu .header__menu-l3 {
	background-color: var(--colorVerde);
}

header .header__menu-content {
	position: absolute;
	right: 1.771vw;
	top: 1.354vw;
	width: 4.688vw;
	height: 4.8vw;
	overflow: hidden;
}

.header__menu {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 4.688vw;
	height: 4.688vw;
	background: transparent;
	cursor: pointer;
	border-radius: 4.688vw;
	border: var(--tamBorder) solid var(--colorNegro);
	padding: 0px;
	margin: 0px;
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
	/* animation: aniLoadBtnMenu 1s cubic-bezier(.165,.84,.44,1) ; */
}

.header__menu span {
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
}

.header__menu .header__menu-c1,
.header__menu .header__menu-c2 {
	position: absolute;
	top: 0.7vw;
	left: 0.7vw;
	width: 3.2vw;
	height: 3.2vw;
	border: var(--tamBorder) solid var(--colorNegro);
	transform: rotate(23deg);
	transform-origin: center;
}

.header__menu .header__menu-c2 {
	transform: rotate(-23deg);
	animation: aniLoadBtnMenuC2 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header__menu:hover .header__menu-c1 {
	transform: rotate(-68deg);
}

.header__menu:hover .header__menu-c2 {
	transform: rotate(68deg);
}

.header__menu .header__menu-l1,
.header__menu .header__menu-l2,
.header__menu .header__menu-l3 {
	position: absolute;
	height: 1px;
	width: 0.833vw;
	background-color: var(--colorNegro);
	top: 2.25vw;
	left: 50%;
	transform: translateX(-50%);
}

.header__menu .header__menu-l2 {
	margin-top: -5px;
}

.header__menu .header__menu-l3 {
	margin-top: 5px;
}

.header__menu:hover .header__menu-l1,
.header__menu:hover .header__menu-l2,
.header__menu:hover .header__menu-l3 {
	width: 1.3vw;
}

/* VERSION CLOSE */
.header__menu-close {
	border-color: var(--colorVerde);
}

.header__menu-close .header__menu-c1,
.header__menu-close .header__menu-c2 {
	border-color: var(--colorVerde);
}

.header__menu-close .header__menu-l2,
.header__menu-close .header__menu-l3 {
	background-color: var(--colorVerde);
	transform-origin: center;
	left: 1.92vw;
	top: 2.3vw;
	margin: 0px;
}

.header__menu-close .header__menu-l1 {
	opacity: 0;
}

.header__menu-close .header__menu-l2 {
	transform: rotate(45deg);
}

.header__menu-close .header__menu-l3 {
	transform: rotate(-45deg);
}

.header__menu-close:hover .header__menu-l2,
.header__menu-close:hover .header__menu-l3 {
	width: 1.3vw;
	left: 1.72vw;
	top: 2.3vw;
}

.menu {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100vw;
	height: 0vh;
	z-index: 9;
}

.menu__container {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.menu .menu__fondo {
	background: var(--colorNegro);
	height: 100vh;
	width: 100vw;
	transform: skewY(-8deg);
	margin-top: 8vw;
}

.menu .menu__close {
	position: absolute;
	top: 1.354vw;
	right: 1.771vw;
	opacity: 0;
}

.menu__menu {
	position: absolute;
	margin: 0px;
	padding: 0px;
	list-style: none;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.menu__menu li {
	overflow: hidden;
	height: 4vw;
	position: absolute;
	padding: 0px 30px;
	z-index: 2;
}

.menu__menu li:nth-child(1),
.menu__menu li:nth-child(4) {
	top: 11vh;
	transform: translateX(-50%);
	left: 50%;
}

.menu__menu li:nth-child(2),
.menu__menu li:nth-child(3) {
	transform: translateY(-50%);
	top: 49%;
	left: 2.50vw;
}

.menu__menu li:nth-child(3) {
	left: auto;
	right: 4.50vw;
}

.menu__menu li:nth-child(4) {
	top: auto;
	bottom: 15vh;
}

.menu__menu li a.menu__menu-a {
	font-size: 4.167vw;
	line-height: 5.8vw;
	color: var(--colorVerde);
	font-family: 'KentoRegular';
	text-decoration: none;
	display: block;
	transform-origin: center bottom;
	perspective: 10vw;
	pointer-events: initial;
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
}

.menu__menu li a.menu__menu-a.active {
	pointer-events: none;
	opacity: .5;
}

html:not(.loaded) .bloque__sl__rombo {
	opacity: 0 !important;
}

.menu__menu li a.menu__menu-a span {
	transition-duration: 0.8s;
	transition-timing-function: cubic-bezier(0.26, 1, 0.48, 1);
	transition-property: opacity, transform;
	transform-origin: center top;
	display: inline-block;
	transform: translateY(110%) rotateX(-90deg);
}

.menu__menu.active li a.menu__menu-a span {
	transform: translateY(0%) rotateX(0deg);
}

.menu__menu.active li a.menu__menu-a span:nth-child(1) {
	transition-delay: 0.03s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(2) {
	transition-delay: 0.06s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(3) {
	transition-delay: 0.09s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(4) {
	transition-delay: 0.12s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(5) {
	transition-delay: 0.15s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(6) {
	transition-delay: 0.18s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(7) {
	transition-delay: 0.21s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(8) {
	transition-delay: 0.24s;
}

.menu__menu.active li a.menu__menu-a span:nth-child(9) {
	transition-delay: 0.27s;
}

.menu .menu__container .menu__line1,
.menu .menu__container .menu__line2,
.menu .menu__container .menu__line3 {
	height: 1px;
	width: 120vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--colorVerde);
	opacity: 0;
}

.menu .menu__container .menu__line1 {
	transform: translate(-50%, -50%) rotate(340deg);
}

.menu .menu__container .menu__line2 {
	transform: translate(-50%, -50%) rotate(40deg);
}

.menu .menu__container .menu__line3 {
	transform: translate(-50%, -50%) rotate(-40deg);
	display: none;
}

.menu .menu__btn-contacto {
	position: absolute;
	bottom: -7vw;
	left: 1.823vw;
}

.menu .menu__btn-rs {
	position: absolute;
	bottom: -7vw;
	right: 4.823vw;
	z-index: 1;
}

.menu .menu__reserved {
	position: absolute;
	bottom: -2vw;
	left: 50%;
	transform: translateX(-50%);
	font-family: 'KentoLight';
	color: var(--colorVerde);
	font-size: 0.729vw;
	line-height: 1vw;
	z-index: 1;
}

.menu .menu__reserved span {
	color: var(--colorVerde);
	-moz-transition: var(--aniBtnMenu);
	-ms-transition: var(--aniBtnMenu);
	-o-transition: var(--aniBtnMenu);
	transition: var(--aniBtnMenu);
}

.btn__rombo {
	text-decoration: none;
	font-weight: normal;
	font-size: 0.885vw;
	line-height: 0.885vw;
	color: var(--colorVerde);
	font-family: 'KentoRegular';
	position: relative;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	width: 12.604vw;
	height: 2.729vw;
	display: inline-block;
	text-align: center;
	background-color: var(--colorVerde);
	padding: 1px;
}

.btn__rombo::before {
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	width: 12.604vw;
	height: 5.729vw;
	position: absolute;
	background-color: var(--colorVerde);
	content: '';
	left: 100%;
	top: 100%;
	z-index: 1;
	padding: 2px;
	-moz-transition: var(--aniBtnRombo);
	-ms-transition: var(--aniBtnRombo);
	-o-transition: var(--aniBtnRombo);
	transition: var(--aniBtnRombo);
	transition-duration: 0.4s;
}

.btn__rombo_svg {
	width: 4.167vw;
	height: 4.167vw;
}

.btn__rombo_svg .btn__rombo_txt svg {
	width: 0.885vw;
	height: auto;
}

.btn__rombo_svg::before {
	width: 4.167vw;
	height: 4.167vw;
}

.btn__rombo_svg .btn__rombo_txt svg>g {
	-moz-transition: var(--aniBtnRombo);
	-ms-transition: var(--aniBtnRombo);
	-o-transition: var(--aniBtnRombo);
	transition: var(--aniBtnRombo);
	transition-delay: 0s;
}

.btn__rombo_svg:hover .btn__rombo_txt svg>g {
	fill: var(--colorNegro);
	transition-delay: 0.2s;
}

.btn__rombo_svg-negro {
	background-color: var(--colorNegro);
}

.btn__rombo_svg-negro.btn__rombo span.btn__rombo_cont {
	background-color: var(--colorVerde);
}

.btn__rombo_svg-negro.btn__rombo::before {
	background-color: var(--colorNegro);
}

.btn__rombo_svg-negro.btn__rombo_svg:hover .btn__rombo_txt svg>g {
	fill: transparent;
	stroke: var(--colorVerde);
}

.btn__rombo_svg-blanco {
	background-color: var(--colorNegro);
}

.btn__rombo_svg-blanco.btn__rombo span.btn__rombo_cont {
	background-color: #fff;
}

.btn__rombo_svg-verde.btn__rombo::before,
.btn__rombo_svg-blanco.btn__rombo::before {
	background-color: var(--colorNegro);
}

.btn__rombo_svg-verde.btn__rombo_svg:hover .btn__rombo_txt svg>g,
.btn__rombo_svg-blanco.btn__rombo_svg:hover .btn__rombo_txt svg>g {
	fill: transparent;
	stroke: #fff;
}

.btn__rombo_svg-verde {
	background-color: var(--colorNegro);
}

.btn__rombo_svg-verde.btn__rombo span.btn__rombo_cont {
	background-color: var(--colorVerde);
}

.btn__rombo:hover::before {
	left: 0%;
	top: 0%;
}

.btn__rombo span.btn__rombo_cont {
	text-decoration: none;
	font-weight: normal;
	font-size: 0.885vw;
	line-height: 5.729vw;
	color: var(--colorVerde);
	font-family: 'KentoRegular';

	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	width: 99%;
	height: 100%;
	display: inline-block;
	text-align: center;
	background-color: var(--colorNegro);

	-moz-transition: var(--aniBtnRombo);
	-ms-transition: var(--aniBtnRombo);
	-o-transition: var(--aniBtnRombo);
	transition: var(--aniBtnRombo);
}

.btn__rombo span.btn__rombo_txt {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transition: var(--aniBtnRombo);
	-ms-transition: var(--aniBtnRombo);
	-o-transition: var(--aniBtnRombo);
	transition: var(--aniBtnRombo);
	overflow: hidden;
	perspective: 10vw;
}

.btn__rombo:hover span.btn__rombo_txt {
	z-index: 9;
}

.btn__rombo span.btn__rombo_txt span {
	display: table-cell;
}

.btn__rombo:hover span.btn__rombo_txt span {
	animation: aniLoadBtn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
	animation-delay: 0.2s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(1) {
	animation-delay: 0.2s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(2) {
	animation-delay: 0.22s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(3) {
	animation-delay: 0.24s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(4) {
	animation-delay: 0.26s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(5) {
	animation-delay: 0.29s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(6) {
	animation-delay: 0.31s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(7) {
	animation-delay: 0.33s;
}

.btn__rombo:hover span.btn__rombo_txt span:nth-child(8) {
	animation-delay: 0.35s;
}

.menu__container .btn__rombo_svg {
	height: 100px;
	width: 100px;
}

.menu__container .btn__rombo_svg::before {
	height: 100px;
	width: 100px;
}

.menu__container .btn__rombo_svg .btn__rombo_txt svg {
	width: 25px;
}

.menu .menu__btn-contacto .btn__rombo {
	height: 0.729vw;
	z-index: 1;
}

.menu .menu__btn-rs .btn__rombo_svg {
	width: 0.729vw;
}

.menu .menu__btn-invierte {
	position: absolute;
	right: 15vw;
	top: 56%;
	height: 6vw;
	overflow: hidden;
}

.menu .menu__btn-invierte .btn__rombo {
	transform: translateY(6vw);
	height: 0.729vw;
	z-index: 1;
}

.menu__hover-top,
.menu__hover-left,
.menu__hover-right,
.menu__hover-bottom {
	position: absolute;
	z-index: 0;
	background-color: var(--colorVerde);
	width: 1500px;
	height: 629px;
	clip-path: polygon(50% 100%, 0 0, 100% 0);
	transform: translateX(-50%);
	bottom: 50vh;
	top: auto;
	pointer-events: none;
	opacity: 0;
	transition: var(--aniBtnMenu);
	left: 50%;
}

.menu__hover-bottom {
	clip-path: polygon(50% 0%, 0 100%, 100% 100%);
	top: 50%;
	bottom: auto;
}

.menu__hover-right,
.menu__hover-left {
	clip-path: polygon(0 0, 0 100%, 100% 50%);
	top: 50%;
	right: 50%;
	left: auto;

	height: 1680px;
	width: 1000px;
	transform: none;
	transform: translateY(-50%);
}

.menu__hover-right {
	clip-path: polygon(100% 0, 100% 100%, 0% 50%);
	top: 50%;
	left: 50%;
	right: auto;
}

.menu__hover.is-active {
	opacity: 1;
}

.menu__hover.is-close {
	opacity: 0 !important;
	transition-duration: 0.4s !important;
	transition-delay: 0s !important;
}

.menu__hover#menu__hover_producto video,
.menu__hover#menu__hover_faqs video,
.menu__hover#menu__hover_inversion video,
.menu__hover#menu__hover_marca video,
.menu__hover#menu__hover_producto img,
.menu__hover#menu__hover_faqs img,
.menu__hover#menu__hover_inversion img,
.menu__hover#menu__hover_marca img {
	position: absolute;
	left: 50%;
	bottom: 0%;
	transition: var(--aniBtnRombo);
	transition-delay: 0s;
	width: auto;
	height: 120%;
	transform: translateX(-50%);
}

.menu__hover#menu__hover_faqs video,
.menu__hover#menu__hover_faqs img {
	top: 24%;
	height: 85vh;
}

.menu__hover#menu__hover_faqs.is-active video,
.menu__hover#menu__hover_faqs.is-active img {
	top: -1%;
	height: 78vh;
}

.menu__hover#menu__hover_inversion video,
.menu__hover#menu__hover_inversion img {
	top: 90%;
	height: 121vh;
	left: 60%;
	transform: translate(-50%, -50%);
}

.menu__hover#menu__hover_inversion.is-active video,
.menu__hover#menu__hover_inversion.is-active img {
	top: 60%;
}

.menu__hover#menu__hover_marca video,
.menu__hover#menu__hover_marca img {
	bottom: -34vh;
	height: 98vh;
}

.menu__hover#menu__hover_marca.is-active video,
.menu__hover#menu__hover_marca.is-active img {
	bottom: -19vh;
	height: 93vh;
}

.menu__hover#menu__hover_producto video,
.menu__hover#menu__hover_producto img {
	top: 65%;
	height: 140vh;
	left: 40%;
	transform: translate(-50%, -50%);
}

.menu__hover#menu__hover_producto.is-active video,
.menu__hover#menu__hover_producto.is-active img {
	top: 55%;
}

.menu__hover.is-active video,
.menu__hover.is-active img {
	opacity: 1;
	transition-delay: 0.1s;
}

.menu__menu li a.menu__menu-a.is-active {
	color: var(--colorNegro);
}

.hoverMenu_faqs.menu .menu__reserved span {
	color: var(--colorNegro);
}

header.hoverMenuHeader_producto .header__logo a svg path {
	fill: var(--colorNegro);
}

header.hoverMenuHeader_inversion .header__menu-close .header__menu-c1,
header.hoverMenuHeader_inversion .header__menu-close .header__menu-c2,
header.hoverMenuHeader_inversion .header__menu-close {
	border-color: var(--colorNegro);
}

header.hoverMenuHeader_inversion .header__menu .header__menu-l1,
header.hoverMenuHeader_inversion .header__menu .header__menu-l2,
header.hoverMenuHeader_inversion .header__menu .header__menu-l3 {
	background-color: var(--colorNegro);
}

.hoverMenu_inversion.menu .btn__rombo_svg {
	background-color: var(--colorNegro);
}

.hoverMenu_inversion.menu .btn__rombo_svg .btn__rombo_txt svg>g {
	fill: var(--colorNegro);
}

.hoverMenu_inversion.menu .btn__rombo_svg span.btn__rombo_cont {
	background-color: var(--colorVerde);
}

.header {
	width: 100vw;
	height: 100vh;
	background-color: var(--colorVerde);
	position: relative;
}

.header1 {
	overflow: hidden;
}

.header .header__data {
	position: relative;
	top: 44%;
	transform: translateY(-50%);
	text-align: center;
}

.header .header__pretitulo,
.bloque__txtc .bloque__txtc__pretitulo {
	font-family: 'KentoLight';
	font-size: 0.938vw;
	line-height: 1.25vw;
	margin-bottom: 1vw;
}

.header .header__titulo {
	max-width: 64vw;
	margin: auto;
}

.opacityIni {
	opacity: 0;
}

.header-pin {
	/* height: calc(100vh + 500px); */
	height: calc(100vh + 2000px);
}

@media screen and (max-width: 600px) {
	.header-pin {
		height: 2000px;
	}

	.pieza {
		zoom: unset !important
	}
}

.header .header__titulo h1,
.bloque__txtc .bloque__txtc__titulo h2 {
	line-height: 5vw;
	font-size: 5.208vw;
	font-family: 'KentoRegular';
	position: relative;
	margin: 0px 0px 1.5vw 0px;
	font-weight: normal;
}

.bloque__txtc .bloque__txtc__figura {
	width: 5.521vw;
	margin: 0px auto 2.552vw;
}

.header .header__ani_right,
.header .header__ani_left {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 0;
	pointer-events: none;
}

.header .header__ani_left {
	top: 100vh;
	left: 100vw;
}

.header .header__ani_right .header__ani_rombo {
	position: absolute;
	z-index: -2;
	width: 75.634vw;
	bottom: 0vw;
	right: 0vw;
}

.header .header__ani_left .header__ani_rombo {
	position: absolute;
	z-index: -2;
	width: 80.938vw;
	top: 0vw;
	left: 0vw;
}

.header .header__ani_right .header__ani {
	position: absolute;
	z-index: -1;
	width: 21.771vw;
	height: auto;
	bottom: 0vw;
	right: 0vw;
}

.header .header__ani_left .header__ani {
	position: absolute;
	z-index: -1;
	width: 37vw;
	height: auto;
	top: 0vw;
	left: 0vw;
}

.header .header__ani_right .header__ani video,
.header .header__ani_left .header__ani video,
.header .header__ani_right .header__ani img,
.header .header__ani_left .header__ani img {
	width: 100%;
	height: auto;
}

/* TEMPORAL PARA KOKE */
.header .header__ani_right .header__ani video,
.header .header__ani_left .header__ani video {
	pointer-events: all;
}

.header .header__ani_right .header__ani video {
	width: 21.771vw;
}

.header .header__ani_center {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 38vw;
	top: 24vw;
	height: 40vw;
	clip-path: polygon(100% 0, 100% 73%, 50% 100%, 0 73%, 0 0);
}

.header .header__ani_center .header__ani_rombo {
	width: 38vw;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	bottom: 0px;
	clip-path: polygon(50% 50%, 100% 50%, 50% 50%, 0% 50%);
	height: 22vw;
	background-color: var(--colorVerdeOsc)
}

.header .header__arrow {
	position: absolute;
	bottom: -8vw;
	left: 50%;
	transform: translateX(-50%);
	width: 1.729vw;
}

.header .header__arrow .btn__rombo_svg {
	width: 1.729vw;
}

.header2 .header__arrow {
	left: 1.615vw;
	transform: none;
	text-align: center;
	width: 5.729vw !important;
}

.header2 .header__data {
	top: calc(50% - 22.5vw);
	transform: none;
	height: 45vw;
}

.header2 .header__ani {
	position: absolute;
	top: 50vw;
	width: 36vw;
	margin-left: 1vw;
	height: 36vw;
}

.header2 .header__ani canvas,
.header2 .header__ani video,
.header2 .header__ani img {
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
}

.header2 .header__ani img {
	width: 80%;
	left: 10%;
	bottom: -5%;
}

.header2 .header__ani img.header__ani_img {
	width: 100%;
	position: absolute;
	bottom: 0px;
	left: 50%;
}
.header2.header__faqs .header__ani img.header__ani_img ,
.header2.header__producto .header__ani img.header__ani_img {
	bottom: -1.5vw;
}
.header2.header__contacto .header__ani img.header__ani_img {
	bottom: -2vw;
}
.header2.header__aviso .header__ani img.header__ani_img {
	bottom: -2.5vw;
}


.header2 .header__titulo_ani {
	transform: scale(1);
}

.pieza-header-container {
	overflow: hidden;
}


.btn__menu_home {
	position: absolute;
	width: 20vw;
	height: 20vw;
	top: calc(50% - 10vw);
	left: calc(50% - 10vw);
	z-index: 9;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.btn__menu_home svg {
	position: absolute;
	width: 100%;
	height: 100%;

	top: calc(50%);
	left: calc(50%);
	transform: translate(-50%, -50%);
}

.btn__menu_home .btn__rombo_txt {
	position: absolute;
	top: calc(52%);
	left: calc(50%);
	transform: translate(-50%, -50%);

	font-size: 1.927vw;
	line-height: 1.927vw;
	color: var(--colorVerde);
	font-family: 'KentoRegular';

	overflow: hidden;
	transform-origin: center top;
	perspective: 10vw;
	border-bottom: 2px solid transparent;
	-moz-transition: var(--aniBtnRombo);
	-ms-transition: var(--aniBtnRombo);
	-o-transition: var(--aniBtnRombo);
	transition: var(--aniBtnRombo);

}

.btn__menu_home .btn__rombo_txt span {
	overflow: hidden;
	transform-origin: center top;
	transform: translateY(210%) rotateX(-90deg);
	display: inline-block;
}

.btn__menu_home:hover .btn__rombo_txt {
	border-bottom: 2px solid var(--colorVerde);
}

.loadImagenes {
	height: 0px;
	width: 0px;
	position: absolute;
	overflow: hidden;
}


.cita-header {
	margin-left: -25vw !important;
	margin-top: 1vw;
}


.cita {
	text-align: left;
	display: inline-block;
}

.cita .cita__title {
	font-family: 'JoulesEtJaques';
	font-size: 2.083vw;
	line-height: 4vw;
	margin-bottom: 0vw;
	padding-left: 5px;
	height: 3.2vw;
	margin-bottom: 0.5vw;
}

.cita .cita__cita {
	max-width: 24.417vw;
	margin-left: 7.708vw;
	font-family: 'Work Sans', sans-serif;
}

.header.header-marca .cita .cita__cita {
	max-width: 15.417vw;
}

.header.header-ani .header__ani_center {
	top: -7vw;
}

.header.header-ani .header__ani_center .header__ani_video video {
	height: 10vw;
	width: 10vw;
}


.header2 .cita-header {
	position: absolute;
	right: 2vw;
	bottom: 2vw;
}

.header2 .cita .cita__cita {
	max-width: 17.417vw;
	margin-left: 3.708vw;
}

.pieza {
	width: 3.542vw;
	height: 3.542vw;
	position: relative;
}

.pieza .pieza__line {
	display: block;
	position: absolute;
	width: 3.542vw;
	height: 1px;
	background-color: var(--colorNegro);
	top: calc(50% - 0.5px);
	left: calc(50% - calc(3.542vw / 2));
	transform-origin: center;
}

.pieza .pieza__rombo {
	display: block;
	position: absolute;
	width: 0.65vw;
	height: 0.65vw;
	border: 1px solid var(--colorNegro);
	transform: rotate(45deg);
	top: 50%;
	left: 50%;
	transform: rotate(45deg);
	background: var(--colorVerde);
	top: calc(50% - 0.325vw);
	left: calc(50% - 0.325vw);
}

.pieza.pieza-blanco .pieza__rombo {
	background: #fff;
}

.pieza.pieza-center {
	margin: auto;
}

.pieza .pieza__2 {
	transform: rotate(90deg);
}

.pieza .pieza__3 {
	transform: rotate(45deg);
}

.pieza .pieza__4 {
	transform: rotate(135deg);
}

.pieza-header {
	display: inline-block;
	margin-bottom: 1.342vw !important;
	transform: translateX(-60px) scale(0.6);
	opacity: 0;
}

.bloque__txtc_pieza.pieza,
.ani_scroll_pieza_grande.pieza,
.ani_scroll_pieza.pieza {
	transform: translateX(-60px) scale(0.6);
	opacity: 0;
}

.pieza__grande {
	width: 5.2vw;
	height: 5.2vw;
}

.pieza__grande .pieza__line {
	width: 5.2vw;
	left: calc(50% - calc(5.2vw / 2));
}

.bloque__txtc_pieza .pieza__1,
.ani_scroll_pieza_grande .pieza__1,
.ani_scroll_pieza.pieza .pieza__1,
.pieza-header.pieza .pieza__1 {
	width: 12vw;
	right: 0px;
	left: auto;
}

.bloque__txtc_pieza .pieza__1,
.ani_scroll_pieza_grande .pieza__1 {
	right: 8px;
}

.pieza__grande .pieza__rombo {
	width: 1vw;
	height: 1vw;
	top: calc(50% - 0.5vw);
	left: calc(50% - 0.5vw);
}

.ani_scroll_fade,
.ani_ini_fade {
	transform: translateY(60px);
	opacity: 0;
}

.bloque__txtc__pretitulo,
.ani_scroll_mask_fade,
.ani_scroll_mask,
.ani_ini_mask_fade,
.ani_ini_mask {
	overflow: hidden;
}

.bloque__txtc__pretitulo span,
.ani_scroll_mask_fade span,
.ani_ini_mask_fade span,
.ani_ini_mask span,
.ani_scroll_mask span {
	display: block;
	transform: translateY(120%);
}
.ani_ini_mask span{
	transform: translateY(120%);
}

.ani_ini_line{
	opacity: 0;
}
.ani_ini_line.active{
	opacity: 1;
}

.ail_line{
	display: block;
	overflow: hidden;
}
.ail_line div{
	transform: translateY(120%) rotate(2deg);
}


.split-line {
	overflow: hidden;
	transform-origin: center top;
	perspective: 10vw;
	height: 5vw;
}

.kentoMedium .split-line {
	height: 2.708vw;
	line-height: 4vw;
}

.bloque__txtc__imgani {
	position: relative;
	transform: translateY(20vw) scale(1);
	z-index: 1;
}

.bloque__txtc__imgani_cont {
	display: inline-block;
	position: relative;
}

.bloque__txtc__imgani img {
	width: 52vw;
	margin: auto;
}

.bloque__txtc__imgani svg {
	width: 100%;
	position: absolute;
	left: 0px;
	bottom: -1.5vw;
	z-index: -1;
}

.split-char {
	overflow: hidden;
	transform-origin: center top;
	transform: translateY(210%) rotateX(-90deg);
}

.header__back {
	height: 100vh;
	width: 100vw;
	position: absolute;
	pointer-events: none;
	background-color: var(--colorNegro);
	top: 0px;
	left: 0px;
	opacity: 0;
}

.bloque__blanco {
	padding-top: 5.625vw;
	position: relative;
	z-index: 9;
	margin-top: 4vw;
}

.bloque__blanco .bloque__blanco-fondo {
	background-color: #fff;
	top: 0px;
	position: absolute;
	z-index: -1;
	left: 0;
	height: 100%;
	width: 100%;
	transform: skewY(-4deg);
}

.bloque__blanco .texto__grande-marca {
	text-align: center;
	font-size: 5.938vw;
	line-height: 5.365vw;
	font-family: 'KentoRegular';
	max-width: 86.042vw;
	margin: 2.76vw auto 6.875vw;
}

.bloque__blanco-diamante {
	text-align: center;
}

.bloque__blanco-diamante svg {
	width: 6.25vw;
}

.texto__pequeno-marca {
	margin: 3.646vw auto;
	max-width: 39.583vw;
	text-transform: uppercase;
	font-size: 1.302vw;
	line-height: 1.563vw;
	text-align: center;
	font-family: 'KentoRegular';
}

.asts-char {
	color: var(--colorVerde);
}

.slider {
	position: relative;
	width: 100%;
	height: 100vh;
}

.slider .slider__line1,
.slider .slider__line2 {
	height: 1px;
	width: 150vw;
	background-color: var(--colorNegro);
	transform: translate(-50%, -50%) rotate(40deg);
	position: absolute;
	top: 50%;
	left: 50%;
	transform-origin: center center;
}

.slider .slider__line1 {
	background-color: var(--colorNegro);
	transform: translate(-50%, -50%) rotate(140deg);
}

.slider .slider__rombo {
	transform: translate(-50%, -50%);
	position: absolute;
	width: 9.063vw;
	height: 9.063vw;
	top: 50%;
	left: 50%;
}

.slider .slider__rombo .slider__rombo_figura {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	overflow: hidden;
}

.slider .slider__rombo .slider__rombo_data {
	font-size: 3.125vw;
	line-height: 3.125vw;
	text-align: center;
	font-family: 'KentoRegular';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	width: 20vw;
	display: flex;
	opacity: 1;
	clip-path: polygon(13% 0%, 87% 0%, 98% 50%, 87% 100%, 13% 100%, 3% 50%);
	height: 4vw;
}

.slider .slider__rombo .slider__rombo_data span {
	margin-top: 12px;
	display: inline-block;
	width: 20vw;
}

.slider .slider__data {
	position: absolute;
	top: 50%;
	left: 5.208vw;
	transform: translateY(-50%);
	width: 22.083vw;
}

.slider .slider__data .slider__title {
	font-family: 'JoulesEtJaques';
	font-size: 2.083vw;
	height: 4vw;
	overflow: hidden;
	margin-bottom: 1vw;
}

.slider .slider__data .slider__title .slider__title_title {
	height: 4vw;
	line-height: 4vw;
	overflow: hidden;
	padding-left: 5px;
}

.slider .slider__data .slider__cont {
	overflow: hidden;
	height: 12vh;
}

.slider .slider__data .slider__cont .slider__cont_cont {
	margin-left: 4.708vw;
	height: 12vh;
	position: absolute;
	top: 0;
	left: 0;
}

.slider .slider__figuras {
	width: 50%;
	position: absolute;
	z-index: -1;
	left: 50%;
	pointer-events: none;
	height: 100vh;
	top: 50%;
	transform: translateY(-50%);
}

.slider .slider__figuras .slider__figuras__figura {
	width: 2000px;
	position: absolute;
	z-index: -1;
	left: 0px;
	pointer-events: none;
	background-color: #81bc8f;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	height: 1680px;
	top: 50%;
	transform: translateY(-50%);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	overflow: hidden;
}

.slider .slider__figuras .slider__figuras__figura img,
.slider .slider__figuras .slider__figuras__figura video {
	position: absolute;
	top: 47%;
	transform: translateY(-50%);
	width: auto;
	left: 11vw;
	height: 33vw;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.slider .slider__controller {
	width: 6vw;
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
	height: 6vw;
}

.slider .slider__controller .slider__controller-left {
	position: absolute;
	left: 0px;
}

.slider .slider__controller .slider__controller-right {
	position: absolute;
	right: 0px;
}

.slider .slider__controller a,
.slider .slider__controller a.btn__rombo_svg::before {
	width: 5.906vw;
	height: 4.76vw;
}

.carrousel__controller-right svg,
.slider__controller-right svg {
	transform: rotate(180deg);
}

.slider .slider__controller a.btn__rombo_svg .btn__rombo_txt svg {
	width: 1.25vw;
	height: auto;
	margin-top: 0.2vw;
}

.slider .slider__cont__inner,
.slider .slider__rombo_data_inner,
.slider .slider__title__inner {
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}
.slider .slider__title__inner{
	transform: translateY(4.2vw);
}
.slider .slider__cont__inner{
	position: relative;
}

.slider__rombo_data_inner {
	display: flex;
}

.slider_active_1 .slider__controller-left,
.slider_active_4 .slider__controller-right {
	opacity: 0.5;
	pointer-events: none;
}

.slider_active_2 .slider__rombo_data_inner {
	transform: translateX(-20vw);
}

.slider_active_3 .slider__rombo_data_inner {
	transform: translateX(-40vw);
}

.slider_active_4 .slider__rombo_data_inner {
	transform: translateX(-60vw);
}

.slider_active_2 .slider__title__inner {
	transform: translateY(-4vw) !important
}

.slider_active_3 .slider__title__inner {
	transform: translateY(-8vw) !important
}

.slider_active_4 .slider__title__inner {
	transform: translateY(-12vw) !important
}

/* .slider_active_2 .slider__cont__inner {
	transform: translateY(-12vh);
}

.slider_active_3 .slider__cont__inner {
	transform: translateY(-24vh);
}

.slider_active_4 .slider__cont__inner {
	transform: translateY(-36vh);
} */

/* .slider__cont_1 .ail_line div {
	transform: translateY(0%) rotate(0deg);
    } */

.slider_active_1 .slider__ff2 {
	transform: translateY(-50%) scale(0.2) !important;
	background-color: #9bcaa6 !important;
}

.slider_active_1 .slider__ff3 {
	transform: translateY(-50%) scale(0.1) !important;
	background-color: #9bcaa6 !important;
	opacity: 0;
}

.slider_active_1 .slider__ff4 {
	transform: translateY(-50%) scale(0) !important;
	background-color: #9bcaa6 !important;
	opacity: 0;
}

.slider_active_2 .slider__ff3 {
	transform: translateY(-50%) scale(0.2) !important;
	background-color: #9bcaa6 !important;
	opacity: 1;
}

.slider_active_2 .slider__ff4 {
	transform: translateY(-50%) scale(0.1) !important;
	background-color: #9bcaa6 !important;
	opacity: 0;
}

.slider_active_3 .slider__ff4 {
	transform: translateY(-50%) scale(0.2) !important;
	background-color: #9bcaa6 !important;
	opacity: 1;
}

.bloque__triangulo {
	padding-top: 20vw;
	position: relative;
	z-index: 0;
	padding-bottom: 13vw;
}

.bloque__triangulo .bloque__triangulo__pretitulo {
	font-family: 'JoulesEtJaques';
	font-size: 2.083vw;
	line-height: 4vw;
	margin-bottom: 0.938vw;
	z-index: 3;
	position: absolute;
	left: 50%;
	margin-top: -8vw;
	transform: translateX(-50%);
}

.bloque__triangulo .bloque__triangulo__titulo {
	font-size: 8.594vw;
	line-height: 8.594vw;
	text-align: center;
	text-transform: uppercase;
	font-family: 'KentoRegular';
	position: relative;
}

.bloque__triangulo .bloque__triangulo__titulo.btt1 {
	z-index: 0;
}

.bloque__triangulo .bloque__triangulo__titulo.btt2 {
	z-index: 2;
}

.bloque__triangulo .bloque__triangulo__tri {
	width: 38.021vw;
	margin: auto;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 100px;
	z-index: 1;
	margin-top: 20vw;
}

.bloque__triangulo.bloque__triangulo-inversion .bloque__triangulo__tri {
	margin-top: 12vw;
}

.bloque__triangulo .bloque__triangulo__tri img,
.bloque__triangulo .bloque__triangulo__tri video {
	position: absolute;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0vw;
	margin-bottom: 2vw;
	max-width: 23.542vw;
}

@media screen and (min-width: 769px) {
	.umbrella {
		bottom: 8vw !important;
	}

	.coins {
		bottom: 4vw !important;
	}
}

.bloque__triangulo .bloque__triangulo__txt {
	width: 48.438vw;
	border-bottom: 1px solid var(--colorNegro);
	text-align: right;
	margin: auto;
	z-index: 4;
	position: relative;
	font-family: 'JoulesEtJaques';
	font-size: 2.083vw;
	line-height: 2.083vw;
	margin-top: 3vw;
}

.bloque__triangulo .bloque__triangulo__medium {
	margin-top: 9.375vw;
	width: 42.813vw;
	text-align: center;
	font-size: 2.604vw;
	line-height: 2.708vw;
	font-family: 'KentoRegular';
	margin: 9.375vw auto 0px;
}

.bloque__triangulo .cita {
	transform: translateX(-50%);
	left: 50%;
	position: relative;
	margin-left: 8.1vw;
}

.bloque__triangulo .bloque__triangulo__rombodeco {
	width: 1.146vw;
	height: 1.146vw;
	margin: 3.125vw auto 3vw;
	position: relative;
}

.bloque__triangulo .bloque__triangulo__rombodeco svg {
	transform: translate(-50%, -50%);
	left: 50%;
	top: 50%;
	width: 0;
	position: relative;
}

.bloque__triangulo-inversion .cita {
	transform: translateX(-50%);
	left: 50%;
	position: relative;
	margin-left: 9.2vw;
}

.bloque__triangulo-inversion .cita .cita__cita {
	max-width: 24.417vw;
	margin-left: 5.708vw;
	font-family: 'Work Sans', sans-serif;
}

.bloque__triangulo__cols {
	display: flex;
	margin-top: 4.375vw;
	margin-left: 41vw;
}

.bloque__triangulo__cols .bloque__triangulo__col {
	width: 28.479vw;
	padding-right: 4vw;
}

.bloque__triangulo__cols .bloque__triangulo__col .bloque__triangulo__col_title {
	font-size: 1.302vw;
	line-height: 1.302vw;
	margin-bottom: 1.675vw;
	font-family: 'KentoRegular';
	width: 17vw;
}

.bloque__triangulo .bloque__triangulo__tri-pro img {
	max-width: 37.135vw;
}

.bloque__triangulo .bloque__triangulo__tri-hexa {
	width: 27.5vw;
}

.bloque__triangulo .bloque__triangulo__tri-hexa img {
	margin-bottom: 4vw;
}

.bloque__triangulo .bloque__triangulo__tri-rombo {
	width: 37.188vw;
}

.bloque__triangulo .bloque__triangulo__tri-rombo img {
	max-width: 28vw;
	margin-bottom: 9vw;
}

.bloque__vision {
	background-color: var(--colorVerde);
	padding: 14vw 0px 10vw;
	position: relative;
}

.bloque__vision_fondo {
	position: absolute;
	z-index: -1;
	background-color: var(--colorVerde);
	height: 100%;
	width: 100%;
	top: 0px;
}

.bloque__vision .bloque__vision_maque {
	position: relative;
	height: 50vw;
}

.marquesina__scroll {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.marquesina__scroll .marquesina__scroll_inner {
	font-size: 15.625vw;
	line-height: 22vw;
	font-family: 'KentoRegular';
	white-space: nowrap;
	position: relative;
	z-index: 1;
	transform: translateX(100vw);
}

.marquesina__scroll .marquesina__scroll_inner svg,
.marquesina__scroll .marquesina__scroll_inner img {
	height: 12vw;
}

.pieza-center .pieza {
	margin: auto;
}

.pieza__blanco .pieza__rombo {
	background: #fff;
}

.bloque__vision .vision_figura1_fondo {
	width: 32vw;
	margin-left: 9.115vw;
	position: absolute;
	top: 30vw;
	z-index: 0;
}

.bloque__vision .vision_figura1_figura {
	width: 32vw;
	margin-left: 9.115vw;
	position: absolute;
	top: 30vw;
	z-index: 2;
}

.bloque__vision .vision_figura1_figura img,
.bloque__vision .vision_figura1_figura video {
	width: 120%;
	margin-top: -5vw;
	margin-left: -10%;
}

.bloque__vision .vision_figura2_fondo {
	width: 39vw;
	right: 5.208vw;
	position: absolute;
	top: 20vw;
	z-index: 0;
}

.bloque__vision .vision_figura2_figura {
	width: 39vw;
	right: 5.208vw;
	position: absolute;
	top: 20vw;
	z-index: 2;
}

.bloque__vision .vision_figura2_figura img,
.bloque__vision .vision_figura2_figura video {
	width: 90%;
	margin-left: 5%;
	margin-top: 3%;
}

.bloque__vision__txtbig_container {
	font-family: 'KentoRegular';
	margin-left: 2.813vw;
	width: 94vw;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig {
	font-size: 5vw;
	line-height: 5vw;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig .split-line {
	height: 4.8vw;
	line-height: 6.7vw;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-max {
	font-size: 6.875vw;
	line-height: 6.6vw;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-max .split-line {
	height: 7vw;
	line-height: 10vw;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-line {
	margin: 2vw 0px;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-cols {
	display: flex;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div {
	width: 50%;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
	width: 15vw;
	display: block;
}

.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span.w14 {
	width: 14vw;
}

.bloque__vision__txtbig_container .bloque__vision__figura {
	width: 6.25vw;
}

.bloque__vision__txtbig_container .cita {
	margin-left: -3.5vw;
}

.bloque__vision__txtbig_container .cita .cita__cita {
	max-width: 29.688vw;
	margin-left: 3.5vw;
}

.bloque__vision__txtbig-cols-bottom {
	margin-top: 15.625vw;
}

.line {
	height: 1px;
	width: 100%;
	background-color: var(--colorNegro);
	display: block;
}

.bloque__vision__txtbig_container-pmin,
.bloque__vision__txtbig_container-inversion {
	margin-left: 1.458vw;
	width: 97.1vw;
	padding: 3.542vw 0px;
}

.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig {
	font-size: 4.1vw;
	line-height: 3.958vw;
}

.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig .split-line:first-child .split-char:first-child {
	margin-left: 19.8vw;
}

.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig .split-line {
	height: 4.4vw;
	line-height: 6vw;
}

.bloque__listado {
	position: relative;
	padding: 0px 2.604vw;
	margin: 6.604vw 0px;
}

.bloque__listado .bloque__listado_item {
	position: relative;
	padding: 6.25vw 6.25vw;
	display: flex;
	-moz-transition: var(--aniListadoItem);
	-ms-transition: var(--aniListadoItem);
	-o-transition: var(--aniListadoItem);
	transition: var(--aniListadoItem);
}

.bloque__listado .bloque__listado_item .bloque__listado_item-titulo {
	font-size: 6.771vw;
	line-height: 6.771vw;
	font-family: 'KentoRegular';
	pointer-events: none;
}

.bloque__listado .bloque__listado_item .bloque__listado_item-titulo .split-line {
	height: 7.4vw;
	line-height: 9.5vw;
}

.bloque__listado .bloque__listado_item .bloque__listado_item-num {
	width: 3vw;
	font-family: 'KentoLight';
	padding-top: 2px;
	overflow: hidden;
	height: 1.3vw;
	pointer-events: none;
	margin-top: 1.3vw;
}

.bloque__listado .bloque__listado_item .bloque__listado_item-num span {
	display: block;
	transform: translateY(120%);
}

.bloque__listado .bloque__listado_item .bloque__listado_item-txt {
	position: absolute;
	right: 0px;
	top: 50%;
	transform: translateY(-50%);
	width: 16.667vw;
	pointer-events: none;
}

.bloque__listado .bloque__listado_item .bloque__listado_item-linea {
	position: absolute;
	left: 0px;
	width: 100%;
	bottom: 0px;
}

.bloque__listado .bloque__listado_item-linea svg {
	position: absolute;
	height: 0;
	margin-top: -0.32vw;
}

.bloque__listado_item-linea .line {
	width: 0%;
}

.bloque__listado .bloque__listado_item:hover {
	padding-left: 0px;
	padding-right: 0px;
}

.bloque__listado .bloque__listado_item:hover {
	padding-left: 0px;
	padding-right: 0px;
}

.bloque__listado .bloque__listado_item:hover .bloque__listado_item-txt {
	opacity: 1;
}

.bloque__listado_item-img-movil {
	display: none;
}

.bloque__listado_hover {
	height: 32vw;
	width: 32vw;
	position: fixed;
	top: 0px;
	left: 0px;
	margin-top: -23vw;
	margin-left: -16vw;
	pointer-events: none;
	clip-path: polygon(100% 0, 100% 71%, 50% 100%, 0 71%, 0 0);
	z-index: -1;
	transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.bloque__listado_hover.active {
	width: 32vw;
}

.bloque__listado_hover svg {
	position: absolute;
	bottom: 0vw;
	-moz-transition: var(--aniListadoItem);
	-ms-transition: var(--aniListadoItem);
	-o-transition: var(--aniListadoItem);
	transition: var(--aniListadoItem);
	transition-duration: 0.4s;
	width: 0%;
	left: 50%;
	transform: translateX(-50%);
}

.bloque__listado_hover.active svg {
	width: 100%;
	bottom: 0px;
}

.bloque__listado_hover svg {
	position: absolute;
	bottom: 0px;
}

.bloque__listado_hover img,
.bloque__listado_hover video {
	position: absolute;
	bottom: -30vw;
	z-index: 1;
	max-width: 22vw;
	margin-left: 5vw;
	-moz-transition: var(--aniListadoItem);
	-ms-transition: var(--aniListadoItem);
	-o-transition: var(--aniListadoItem);
	transition: var(--aniListadoItem);
	transition-duration: 0.6s;
}

.bloque__listado_hover.active img.active,
.bloque__listado_hover.active video.active {
	bottom: 3vw;
}

.bloque__verde {
	padding: 8.333vw 0px 5vw;
	background: var(--colorVerde);
	margin: 10vw 0px 0px;
}

.bloque__txtc {
	text-align: center;
	position: relative;
	margin-bottom: -15vw;
}

.bloque__txtc .bloque__txtc__titulo h2 {
	font-size: 6.771vw;
	line-height: 9.5vw;
}

.bloque__txtc .pieza {
	display: inline-block;
	margin-bottom: 3.542vw;
}

.bloque__txtc .split-line {
	height: 7vw;
	line-height: 9.5vw;
}

.bloque__txtc .bloque__txtc__titulo__rombodeco {
	width: 1.146vw;
	height: 1.146vw;
	margin: 3vw auto 3vw;
	position: relative;
}

.bloque__txtc .bloque__txtc__titulo {
	margin-top: 2vw;
}

/* .bloque__txtc .cita {
	margin-left: 0;
	position: absolute;
	bottom: 5vw;
	right: 3vw;
}
 */
 .bloque__txtc .cita  {
	margin-left: 12vw;
}
.bloque__txtc .cita .cita__cita {
	max-width: 18.417vw;
    	margin-left: 5.708vw;
}

.ani_scroll_rombodeco svg {
	width: 0%;
}

.bloque__colscroll {
	display: flex;
	margin-top: 10vw;
	padding-bottom: 5vw;
}

.bloque__colscroll .bloque__colscroll_colimgs {
	width: 60%;
	text-align: center;
	position: relative;
	height: 100vh;
	pointer-events: none;
}

.bloque__colscroll .bloque__colscroll_coldata {
	width: 40%;
}

.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info-space {
	height: 400px;
}

.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
	width: 26.042vw;
	padding-bottom: 10.417vw;
}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani {
	height: 35vw;
	width: 43vw;
	margin: auto;
	position: relative;
	-webkit-clip-path: polygon(100% 0, 100% 64%, 50% 100%, 0 64%, 0 0);
	clip-path: polygon(100% 0, 100% 64%, 50% 100%, 0 64%, 0 0);
	top: 20%;
	transform: translateY(-50%);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani {
	top: 50%;
}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani.anima .bloque__colscroll_colimgs_ani_rombo {

	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani .bloque__colscroll_colimgs_ani_rombo {
	position: absolute;
	bottom: 0vw;
	width: 0px;
	left: 0px;
	z-index: -1;
	left: 50%;
	width: 43vw;
	height: 25vw;
	z-index: -1;
	transform: translateX(-50%);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	background-color: var(--colorVerdeOsc);
	clip-path: polygon(50% 50%, 100% 50%, 50% 50%, 0% 50%);

}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani img,
.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani video {
	width: 44vw;
	height: auto;
	position: absolute;
	left: 50%;
	bottom: -110%;
	transform: translateX(-50%);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani img.colscroll_active,
.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani video.colscroll_active {
	bottom: -2%;
}

.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani img#colscroll_1_img.colscroll_active,
.bloque__colscroll .bloque__colscroll_colimgs .bloque__colscroll_colimgs_ani video#colscroll_1_img.colscroll_active {
	transition-delay: 0.8s;
}


.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_number {
	position: absolute;
	margin-left: -4vw;
	font-family: 'KentoLight';
	font-size: 1vw;
	margin-top: -2px;
}

.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_title {
	font-family: 'KentoRegular';
	position: relative;
	margin: 0px 0px 1.823vw 0px;
	font-weight: normal;
	font-size: 2.083vw;
	line-height: 2vw;
}

.bloque__negro {
	background-color: var(--colorNegro);
	color: var(--colorVerde);
	padding: 10vw 0px 13vw;
}

.bloque__equipo {
	padding: 0px 0px 0vw;
}

.bloque__equipo .bloque__equipo_item {
	position: relative;
	overflow: hidden;
	height: 100vh;
	/* David Locco */
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_img {
	position: relative;
	z-index: 2;
	padding: 10vh 0px 10vh;
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_img img,
.bloque__equipo .bloque__equipo_item .bloque__equipo_img video {
	height: 80vh;
	display: block;
	width: auto;
	margin: auto;
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre {
	font-size: 7.813vw;
	font-family: 'KentoRegular';
	position: relative;
	font-weight: normal;
	font-size: 7vw;
	line-height: 2vw;
	white-space: nowrap;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre img,
.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre svg {
	height: 4.9vw;
	display: inline-block;
	margin: 0px 1.5vw;
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre1 {
	z-index: 1;
	margin-top: -5vw;
	transform: translate(100vw, -50%);
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre2 {
	z-index: 3;
	margin-top: 5vw;
	transform: translate(-100vw, -50%);
}

.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre2.--4 {
	transform: translate(-300vw, -50%);
}

.end-element {
	margin-top: 0vh;
}

.bloque__hablamos {}

.bloque__hablamos .bloque_hablamos_txt {
	width: 12.063vw;
	margin: 10vw auto 0px;
	text-align: center;
	font-family: 'KentoLight';
	position: relative;
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 1.2vw;
	text-transform: uppercase;
}

.bloque__hablamos_cont_btn {
	height: 23.542vw;
}

.btn_hablamos {
	position: relative;
	display: block;
	width: 40.74vw;
	margin: auto;
	margin-top: 8.594vw;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0 !important;
}

.btn_hablamos svg {
	width: 100%;
	height: auto;
}

.btn_hablamos span {
	font-family: 'KentoRegular';
	display: block;
	font-weight: normal;
	font-size: 6.771vw;
	line-height: 6vw;
	text-transform: uppercase;
	position: absolute;
	top: 52%;
	left: 50%;
	transform: translate(-50%, 0%);
	color: var(--colorVerde);
	opacity: 0;
}

.fondo__verde {
	margin-top: -1px;
	background-color: var(--colorVerde);
}

.fondo_blanco {
	background-color: #fff;
}

.bloque__vision__txtbig_container_producto {
	padding: 10vw 0px;
	margin-top: -1px;
	height: 100vh;
}

.btn__rombo-vision {
	position: absolute;
	margin-top: -3.03vw;
	right: 3vw;
	height: 5.99vw;
}

.btn__rombo-vision.btn__rombo_svg .btn__rombo_txt svg {
	width: 3.021vw;
	transform: rotate(180deg);
}

.btn__rombo-vision.btn__rombo_svg::before {
	height: 5.99vw;
	width: 7.448vw;
}

.contenedor__scroll_lateral {
	display: flex;
	flex-wrap: nowrap;
	width: 500vw;
}

.contenedor__scroll_lateral .contenedor__scroll_lateral-seccion {
	width: 100vw;
	height: 100vh;
}

.contenedor__scroll_lateral-data {
	padding: 8vw 10vw;
	height: 100%;
	text-align: center;
	position: relative;
}

.contenedor__scroll_lateral-data .csls__row {
	display: flex;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.contenedor__scroll_lateral-data .csls__col_data {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	padding: 0px 6vw 0px 2vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col {
	width: 50%;
	position: relative;
}

.contenedor__scroll_lateral-data .csls__row .csls__col img,
.contenedor__scroll_lateral-data .csls__row .csls__col video {
	max-width: 100%;
	max-height: 61vh;
}

.contenedor__scroll_lateral-data .csls__row .csls__col svg {
	position: absolute;
	z-index: -1;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	left: 50%;
}

.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-sostenibilidad video {
	max-height: 34vw;
	margin-bottom: -5vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-unicidad svg {
	width: auto;
	height: 34.5vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-unicidad video {
	max-height: 34vw;
	margin-top: -3vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad svg {
	width: auto;
	height: 34.5vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad video {
	max-height: 34vw;
	margin-top: -11vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_pre {
	font-family: 'JoulesEtJaques';
	font-size: 2.083vw;
	line-height: 4vw;
	margin-bottom: 0.8vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_tit {
	line-height: 1.302vw;
	font-size: 1.302vw;
	font-family: 'KentoRegular';
	margin-bottom: 2.083vw;
}

.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_txt {}

.bloque__txtc-producto {
	padding-top: 13.385vw;
	padding-bottom: 3vw;
	margin-bottom: 0;
}

.fondo__negro {
	background-color: var(--colorNegro);
	color: var(--colorVerde);
}

.bloque__txtc-producto .bloque__txtc__titulo {
	width: 63.229vw;
	margin: 0px auto 7.813vw;
}

.bloque__txtc-producto .bloque__txtc__titulo h2 {
	font-size: 5.208vw;
	line-height: 5vw;
}

.bloque__txtc-producto .split-line {
	height: 5vw;
	line-height: 7vw;
}

.tabla {
	padding: 0px 1.563vw 8.177vw;
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 1.302vw;
	text-transform: uppercase;
}

.tabla .row {
	border-bottom: 1px solid var(--colorVerde);
	display: flex;
	align-items: center;
	justify-content: center;
}

.tabla .row .col {
	width: 33%;
	padding: 3.177vw 0vw;
}

.tabla .row .col.col-header {
	padding: 0vw 0vw 2.396vw;
	text-align: center;
}

.tabla .row .col.col-data {
	font-size: 1.563vw;
	line-height: 1.563vw;
	text-align: center;
}

.tabla .row .col.col-txt {
	width: 31.813vw;
	padding-right: 3vw;
}

.bloque__triangulo-producto {
	padding-bottom: 8.594vw;
	margin-bottom: 0px;
}

.bloque__triangulo-producto .bloque__triangulo__tri {
	height: 32vw;
	width: 37.135vw;
}

.bloque__triangulo-producto .bloque__triangulo__tri img,
.bloque__triangulo-producto .bloque__triangulo__tri video {
	transform: translate(-50%, 2vw);
}

.bloque__triangulo .bloque__triangulo__txt-producto {
	border-bottom: 0px;
	margin-top: 14vw;
	text-align: center;
}

.bloque__triangulo-producto .bloque__triangulo__medium {
	font-size: 1.302vw;
	line-height: 1.563vw;
	width: 35.573vw;
	margin-top: 2.708vw;
}

.bloque__triangulo-producto .bloque__triangulo__medium-marca {
	margin-top: 10vw;
}

.bloque__triangulo-producto .kentoMedium .split-line {
	height: 1.563vw;
	line-height: 1.9vw;
}

.bloque__scroll__later {}

.bloque__scroll__later .bloque__sl__fixed {
	height: 100vh;
}

.bloque__scroll__later .bloque__sl__pantalla {
	height: 100vh;
	padding: 1.51vw;
	position: relative;
}

.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data {
	width: 27.927vw;
	padding-left: 3.281vw;
	position: relative;
}

.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_number {
	font-family: 'KentoLight';
	font-weight: normal;
	font-size: 1vw;
	line-height: 1vw;
	text-transform: uppercase;
	position: absolute;
	left: 0px;
	top: 0px;
}

.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_title {
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 2.083vw;
	line-height: 2.083vw;
	text-transform: uppercase;
	margin: 0px 0px 1.823vw 0px;
}

.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_cont {
	width: 18.646vw;
}

.bloque__scroll__later .bloque__sl__pantalla:nth-child(2n + 1) .bloque__sl__data {
	float: right;
}

.bloque__sl__data_img_movil {
	display: none;
}

.bloque__scroll__later .bloque__sl__mask {
	width: 45.313vw;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	clip-path: polygon(100% 0, 100% 71%, 50% 100%, 0 71%, 0 0);
	height: 46vw;
	z-index: 0;
}

.bloque__scroll__later .bloque__sl__mask .bloque__sl__rombo {
	position: absolute;
	bottom: 0px;
	width: 45.313vw;
	height: 26.406vw;
	background-color: var(--colorVerdeOsc);
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	z-index: -1;
}

.bloque__scroll__later .bloque__sl__mask img,
.bloque__scroll__later .bloque__sl__mask video {
	position: absolute;
	z-index: 1;
	max-width: 46vw;
	bottom: -60vw;
	transform: translateX(-50%);
	left: 50%;
}

.bloque__listado-producto .bloque__listado {
	margin-top: 0px;
}

.bloque__product_txt {
	text-align: center;
	margin-top: 10vw;
}

.bloque__product_txt svg {
	width: 5.521vw;
	margin: 0px auto 2.552vw;
}

.bloque__product_txt .bloque__product_txt_data {
	width: 38.125vw;
	margin: 0px auto;
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 2.083vw;
	line-height: 2.188vw;
	text-transform: uppercase;
}

.bloque__product_txt .bloque__product_txt_data .split-line {
	height: 2.3vw;
	line-height: 3vw;
}

.bloque__hablamos-producto {
	padding-bottom: 9.115vw;
}

.bloque__hablamos-producto .bloque_hablamos_txt {
	margin: 6vw auto 0px;
}

.bloque__hablamos_cont_btn-negro .btn_hablamos span {
	color: var(--colorNegro);
}

.bloque__hablamos_cont_btn-negro .btn_hablamos svg g {
	stroke: var(--colorNegro);
}

.cards {
	height: 300vh;
}

.cards__container {
	height: 100vh;
	position: relative;
	width: 100vw;
	overflow: hidden;
}

.card {
	width: 73.438vw;
	height: 32.24vw;
	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-55%, -45%);
	-moz-transition: var(--aniCards);
	-ms-transition: var(--aniCards);
	-o-transition: var(--aniCards);
	transition: var(--aniCards);
}

.card .card__fondo {
	width: 73.438vw;
	height: 32.24vw;
	position: absolute;
	z-index: -1;
}

.card .card__fondo .card__fondo_movil {
	display: none;
}

.card .card__img {
	position: absolute;
	width: 29.948vw;
	margin-left: 2.969vw;
	margin-top: -2vw;
}

.card .card__img img,
.card .card__img video {
	width: 100%;
}

.card .card__img svg {
	width: 100%;
	position: absolute;
	bottom: 0px;
	z-index: -1;
}

.card .card__img.card__img_mismacalidad {
	margin-top: -4vw;
}


.card .card__data {
	width: 27.708vw;
	left: 32.917vw;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	padding-left: 5.99vw;
}

.card .card__data .card__data_number {
	left: 0vw;
	top: 0%;
	position: absolute;
	font-family: 'KentoLight';
	font-weight: normal;
	font-size: 1.198vw;
	line-height: 1.458vw;
}

.card .card__data .card__data_title {
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 2.917vw;
	line-height: 2.917vw;
	margin-bottom: 1.042vw;
}

.card1 {
	transform: translate(-55%, -45%);
	opacity: 1;
	z-index: 3;
}

.card2 {
	transform: translate(-48%, -53%);
	opacity: 0.9;
	z-index: 2;
}

.card3 {
	transform: translate(-41%, -61%);
	opacity: 0.8;
	z-index: 1;
}

.card_active_2 .card1 {
	transform: translate(-55%, 120%) !important;
	opacity: 1;
	z-index: 3;
}

.card_active_2 .card2 {
	transform: translate(-55%, -45%) !important;
	opacity: 1;
	z-index: 3;
}

.card_active_2 .card3 {
	transform: translate(-48%, -53%) !important;
	opacity: 0.9;
	z-index: 2;
}

.card_active_3 .card1 {
	transform: translate(-55%, 120%) !important;
	opacity: 1;
	z-index: 3;
}

.card_active_3 .card2 {
	transform: translate(-55%, 120%) !important;
	opacity: 1;
	z-index: 3;
}

.card_active_3 .card3 {
	transform: translate(-55%, -45%) !important;
	opacity: 1;
	z-index: 3;
}

.page__inversion {
	padding-top: 10vw;
}

.bloque__faqs {
	margin-top: -1px;
	background-color: var(--colorVerde);
	padding: 6.25vw 1.667vw 10.625vw;
	padding-top: 40vh;
}

.bloque__faqs ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

.bloque__faqs ul li .faqs {
	position: relative;
}

.bloque__faqs ul li .faqs .faqs__header {
	overflow: hidden;
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 1.563vw;
	line-height: 1.563vw;
	position: relative;
	padding-top: 2.5vw;
	padding-bottom: 2vw;
	cursor: pointer;
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_rombo {
	float: left;
	width: 8vw;
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_number {
	float: left;
	width: 8vw;
	font-size: 0.938vw;
	font-family: 'KentoLight';
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_title {
	float: left;
	width: 73vw;
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_title .split-line {
	height: 1.563vw;
	line-height: 2.2vw;
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_rombo svg {
	width: 1.302vw;
}

.bloque__faqs ul li .faqs .faqs__header .faqs__header_rombo svg path {
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.bloque__faqs ul li .faqs.open .faqs__header .faqs__header_rombo svg path {
	fill: var(--colorNegro);
}

.bloque__faqs ul li .faqs .faqs__line {
	position: absolute;
	height: 1px;
	width: 100%;
	bottom: 0px;
	left: 0px;
	background-color: var(--colorNegro);
}

.bloque__faqs ul li .faqs .faqs__data {
	overflow: hidden;
	height: 0;
}

.bloque__faqs ul li .faqs .faqs__data_cont {
	padding: 1vw 10vw 2.604vw 23.438vw;
	font-size: 16px;
	line-height: 20px;
}

.header.header-contacto .header__ani_center {
	top: 17vw;
}

.header__contacto_contenedor {
	width: calc(100%);
	background: var(--colorVerde);
	padding: 0px 1.6vw;
	padding-top: 7vw;
	margin-bottom: 0px;
	margin-top: -1px;
}

.header__contacto_data {
	width: calc(100%);
	padding-bottom: 0.964vw;
	font-family: 'KentoLight';
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 1.146vw;
	overflow: hidden;
	position: relative;
}

.header__contacto_data a {
	color: var(--colorNegro);
	text-decoration: none;
}

.header__contacto_data .header__contacto_data_left {
	float: left;
	width: 40vw;
	opacity: 0;
	transform: translateY(30px);
}

.header__contacto_data .header__contacto_data_right {
	float: right;
	width: 40vw;
	text-align: right;
	opacity: 0;
	transform: translateY(30px);
}

.header__contacto_data .header__contacto_data_line {
	height: 1px;
	width: 0%;
	background-color: var(--colorNegro);
	position: absolute;
	bottom: 0px;
	left: 50%;
}

.page__contacto {
	width: calc(100% - 3.646vw);
	margin-left: 1.823vw;
	padding-top: 7.292vw;
	padding-bottom: 5.208vw;
	margin-top: -1px;
	position: relative;
}

.contacto {
	display: flex;
}

.contacto .contacto__col1 {
	width: 44vw;
}

.contacto .contacto__col2 {
	width: 58.25vw;
	margin-right: 1vw;
}

.contacto .contacto__col2 .contacto__mensaje {
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 2.604vw;
	line-height: 2.604vw;
	border-bottom: 1px solid var(--colorNegro);
	height: 100%;
}

.contacto .contacto__col2 .contacto__mensaje .asts-char {
	color: var(--colorVerdeOsc);
}

.contacto .contacto__formcont {
	width: 28.646vw;
}

.form__control:focus {
	outline: none;
}

.form__control {
	border: 0px;
	border-bottom: 1px solid var(--colorNegro);
	background: transparent;
	padding: 0px;
	color: var(--colorNegro);
	display: block;
	width: 100%;
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 0.938vw;
	padding: 1vw 0px;
	margin-bottom: 2vw;
	border-radius: 0px;
}

::placeholder {
	color: var(--colorNegro);
}

.contacto__formcont .form__control:last-child {
	margin-bottom: 0px;
}

.form__control_textarea {
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 2.604vw;
	line-height: 2.604vw;
	border: 0px;
	padding: 0px;
	padding-top: 10px;
	height: 82%;
}

.form__control_textarea::placeholder {
	color: var(--colorVerdeOsc);
}

.contacto__input {
	position: relative;
}

.contacto__input .contacto__input_error_ico {
	position: absolute;
	right: -70px;
	opacity: 0;
	visibility: hidden;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.contacto__input .contacto__input_error {
	position: absolute;
	margin-top: -26px;
	opacity: 0;
	visibility: hidden;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.contacto__input.active .contacto__input_error,
.contacto__input.active .contacto__input_error_ico {
	opacity: 1;
	visibility: visible;
}

.contacto__check_error,
.contacto__mensaje_error {
	margin-top: 10px;
	opacity: 0;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.contacto__check_error.active,
.contacto__mensaje_error.active {
	opacity: 1;
}

.form__submit {
	font-family: 'KentoLight';
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 0.938vw;
	padding: 1vw 3px;
	border: 0px;
	padding: 0px;
	background: transparent;
	width: 10.156vw;
	height: 3.385vw;
	text-align: center;
	position: relative;
	cursor: pointer;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	color: var(--colorNegro);
}

.form__submit span {
	position: absolute;
	z-index: 1;
	top: 54%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form__submit svg {
	top: 0px;
	left: 0px;
	width: 10.156vw;
	height: 3.385vw;
	position: absolute;
}

.form__submit:hover {
	color: var(--colorVerde);
}

.form__submit svg path {
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	stroke: var(--colorNegro);
	fill: var(--colorVerde);
}

.form__submit:hover svg path {
	stroke: var(--colorVerde);
	fill: var(--colorNegro);
}

.mensaje__ok {
	position: absolute;
	width: 0vh;
	height: 60vh;
	background-color: var(--colorNegro);
	color: var(--colorVerde);
	text-align: center;
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	z-index: 1;
}

.mensaje__ok .mensaje__ok_title {
	width: 28vh;
	font-family: 'KentoRegular';
	font-weight: normal;
	font-size: 1.5vw;
	line-height: 1.5vw;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	margin: auto;
	left: 50%;
}

.mensaje__ok.active {
	width: 60vh;
}

.contacto__legalsub {
	display: flex;
	margin-top: 2.5vw;
	position: relative;
}

.contacto__legalsub .contacto__legal {
	width: 37.5vw;
	font-size: 0.729vw;
	line-height: 1.042vw;
}

.contacto__legalsub .contacto__submit {
	text-align: right;
	width: 32%;
}

.contacto__legalsub .contacto__submit .form__submit {
	display: inline-block;
}

.checkbox__container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox__container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: transparent;
	border: 1px solid var(--colorNegro);
	transform: rotate(45deg);
}

.checkmark:after {
	content: '';
	position: absolute;
	opacity: 0;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	transition-duration: 0.2s;
}

.checkbox__container input:checked~.checkmark:after {
	opacity: 1;
}

.checkbox__container .checkmark:after {
	left: 6px;
	top: 6px;
	width: 8px;
	height: 8px;
	border: 0;
	border-width: 0px;
	background: var(--colorNegro);
}

.contacto__legal .checkbox__container {
	position: absolute;
	top: 4px;
}

.contacto__legal p {
	margin: 0px;
	padding: 0px 0px 0px 50px;
}

.contacto__legal p a {
	color: var(--colorNegro);
}

footer {
	height: 100vh;
	overflow: hidden;
	background-color: var(--colorNegro);
	color: var(--colorVerde);
	transform: translateY(0px);
	z-index: 0;
	position: relative;
}

footer .footer {
	height: 100vh;
	position: relative;
}

footer .footer .footer__rombo {
	position: absolute;
	width: 30.448vw;
	height: 30.448vw;
	border: 1px solid var(--colorNegro);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	background-color: var(--colorNegro);
	z-index: 1;
	overflow: hidden;
}

footer .footer .footer__rombo a {
	text-decoration: none;
	font-size: 5.208vw;
	line-height: 5.208vw;
	font-family: 'KentoRegular';
	font-weight: normal;
	transform: rotate(-45deg);
	display: block;
	color: var(--colorVerde);
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	margin-top: calc(50% - 2vw);
	opacity: 0;
}

footer .footer .footer__rombo a svg {
	height: 3.575vw;
	-webkit-animation: rotating 10s linear infinite;
	-moz-animation: rotating 10s linear infinite;
	-ms-animation: rotating 10s linear infinite;
	-o-animation: rotating 10s linear infinite;
	animation: rotating 10s linear infinite;
}

footer .footer .footer__rombo a span {
	display: block;
	animation: 7s linear infinite marquee;
}

footer .split-char {

	transform: translateY(150%) rotateX(-110deg);
}

@keyframes marquee {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-38vw);
	}
}

footer .footer .footer__linea1,
footer .footer .footer__linea2,
footer .footer .footer__linea3 {
	height: 1px;
	width: 0%;
	background-color: var(--colorVerde);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

footer .footer .footer__linea1 {
	transform: translate(-50%, -50%) rotate(40deg);
}

footer .footer .footer__linea2 {
	transform: translate(-50%, -50%) rotate(140deg);
}

footer .footer__txt_top,
footer .footer__txt_bottom {
	font-family: 'KentoLight';
	font-weight: normal;
	font-size: 0.938vw;
	line-height: 1.25vw;
	text-align: center;
	position: absolute;
	opacity: 0;
}

footer .footer__txt_top {
	top: 5vh;
	width: 10.417vw;
	left: 50%;
	transform: translateX(-50%);
}

footer .footer__txt_bottom {
	bottom: -8.208vw;
	width: 23.438vw;
	left: 50%;
	transform: translateX(-50%);
}

footer .footer__txt_bottom a {
	color: var(--colorVerde);
	text-decoration: none;
}

footer .footer__btn-contacto {
	position: absolute;
	bottom: -5vw;
	left: 1.875vw;
}

footer .footer__btn-contacto .btn__rombo {
	opacity: 0;
}

footer .footer__btn-scroll,
footer .footer__btn-rs {
	position: absolute;
	bottom: 1.354vw;
	right: 1.875vw;
}

footer .footer__btn-scroll {
	bottom: 52vh;
}

footer .footer__btn-scroll .btn__rombo,
footer .footer__btn-rs .btn__rombo {
	width: 100px !important;
	height: 100px !important;
	margin-right: 1.5vw;
	opacity: 0;
}

footer .footer__btn-scroll .btn__rombo.btn__rombo_svg::before,
footer .footer__btn-rs .btn__rombo.btn__rombo_svg::before {
	width: 100px !important;
	height: 100px !important;
}

footer .footer__btn-scroll .btn__rombo .btn__rombo_txt svg {
	width: 18px;
}

footer .footer__btn-rs .btn__rombo .btn__rombo_txt svg {
	width: 24px;
}

footer .footer__btn-scroll .btn__rombo_svg:hover svg path {
	stroke: var(--colorNegro);
	transition-delay: 0.2s;
}

footer .footer__menu1 {
	position: absolute;
	top: 25vh;
	left: 10.417vw;
}

footer .footer__menu2 {
	position: absolute;
	top: 64vh;
	right: 7.417vw;
}

.footer__menu_svg {
	position: absolute;
	width: 3.542vw;
	height: 3.542vw;
	margin-left: -10vw;
	margin-top: 0.6vw;
	opacity: 0;
}

.footer__menu_svg span {
	width: 100%;
	height: 1px;
	background-color: var(--colorVerde);
	position: absolute;
	display: block;

}

.footer__menu_svg span.footer__menu_svg_1 {
	top: calc(50% - 0.5px);
	width: 185%;
	left: -100%;
}

.footer__menu_svg span.footer__menu_svg_2 {
	top: 50%;
	height: 70%;
	width: 1px;
	left: calc(50% - 0.5px);
	transform: translateY(-50%);
}

.footer__menu_svg span.footer__menu_svg_3 {
	height: 0.8vw;
	width: 0.8vw;
	top: calc(50% - 0.35vw);
	left: calc(50% - 0.41vw);
	background: var(--colorNegro);
	border: 1px solid var(--colorVerde);
	transform: rotate(45deg);
}

footer.verde .footer__menu_svg span {
	background-color: var(--colorNegro);
}

footer.verde .footer__menu_svg span.footer__menu_svg_3 {
	background: var(--colorVerde);
	border: 1px solid var(--colorNegro);
}


footer .footer__menu-a {
	font-size: 2.604vw;
	line-height: 2.604vw;
	font-family: 'KentoRegular';
	font-weight: normal;
	color: var(--colorVerde);
	display: inline-block;
	text-decoration: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

footer .footer__menu-a .split-line {
	height: 3vw;
	line-height: 4vw;
	text-decoration: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

footer.verde {
	color: var(--colorNegro);
	background-color: var(--colorVerde);
}

footer.verde a {
	color: var(--colorNegro);
}

footer.verde .footer .footer__rombo {
	border: 1px solid var(--colorVerde);
	background-color: var(--colorVerde);
}

footer.verde .footer .footer__rombo a {
	color: var(--colorNegro);
}

footer.verde .footer .footer__rombo a svg path {
	stroke: var(--colorNegro);
	fill: var(--colorVerde);
}

footer.verde .footer .footer__linea1,
footer.verde .footer .footer__linea2,
footer.verde .footer .footer__linea3 {
	background-color: var(--colorNegro);
}

footer .footer .footer__linea3 {
	left: 0px;
	transform: none;
	width: 0%;
}

footer.verde .footer .footer__linea1,
footer.verde .footer .footer__linea2,
footer .footer .footer__linea1,
footer .footer .footer__linea2 {
	background-color: transparent;
	width: 100%;
}

footer .footer .footer__linea1 span,
footer .footer .footer__linea2 span {
	display: block;
	width: 100%;
	position: absolute;
	height: 1px;
	left: 100%;
	background-color: var(--colorVerde);

}

footer.verde .footer .footer__linea2 span {
	width: 100%;
	left: -100%;
}

footer.verde .footer .footer__linea1 span,
footer.verde .footer .footer__linea2 span {
	display: block;
	width: 100%;
	background-color: var(--colorNegro);
	position: absolute;
	height: 1px;
	left: 100%;
}


footer.verde .btn__rombo {
	background-color: var(--colorNegro);
}

footer.verde .btn__rombo span.btn__rombo_cont {
	background-color: var(--colorVerde);
}

footer.verde .btn__rombo::before {
	background-color: var(--colorNegro);
}

footer.verde .footer__menu_svg svg g {
	stroke: var(--colorNegro);
}

footer.verde .footer__menu_svg svg path {
	fill: var(--colorVerde);
}

footer.verde .btn__rombo_svg .btn__rombo_txt svg>g {
	stroke: var(--colorNegro);
	transition-delay: 0.2s;
}

footer.verde .footer__btn-rs .btn__rombo_svg .btn__rombo_txt svg>g {
	fill: var(--colorNegro);
}

footer.verde .btn__rombo:hover span.btn__rombo_txt span {
	animation-name: aniLoadBtnVerde;
}

footer.verde .footer__btn-scroll .btn__rombo_svg:hover svg path {
	stroke: var(--colorVerde);
}

footer.verde .footer__btn-rs .btn__rombo_svg:hover svg path {
	stroke: var(--colorVerde);
	fill: var(--colorVerde);
	transition-delay: 0.2s;
}

.page-producto {
	background-color: var(--colorVerde) !important;
}

.page-producto .fondo__verde {
	background-color: transparent;
}

.bloque__colscroll-inversion {
	margin-top: 4.188vw;
	margin-bottom: 0px;
	padding-bottom: 5vw;
}

 .bloque__txtc-inversion {
	margin-bottom: 14vw;
	max-width: 100vw;
	overflow: hidden;
}
.bloque__txtc-inversion .bloque__txtc__imgani svg {
	width: 108%;
	left: -4%;
	bottom: -3vw;
}


.bloque__carrousel {
	padding-bottom: 12.813vw;
	position: relative;
}

.carrousel {
	padding-left: 2.969vw;
	overflow: hidden;
}

.carrousel .carrousel__inner {
	white-space: nowrap;
	display: flex;
	margin-bottom: 4vw;
}

.carrousel .carrousel__inner .carrousel__item {
	transform: translateY(150%);
}

.diapo {
	width: 75vw;
	display: flex;
}

.diapo .diapo__num {
	font-size: 1.302vw;
	font-family: 'KentoRegular';
	font-weight: normal;
	width: 5vw;
	padding-top: 4vw;
}

.diapo .diapo__img {
	position: relative;
}

.diapo .diapo__img img,
.diapo .diapo__img video {
	height: 29.844vw;
	width: auto;
}

.diapo .diapo__img svg {
	width: 100%;
	position: absolute;
	z-index: -1;
	left: 0px;
}

.diapo .diapo__data_cont {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	width: 32.292vw;
	margin-left: 3.75vw;
}

.diapo .diapo__data_cont .diapo__data_cont_title {
	font-size: 3.125vw;
	line-height: 3.125vw;
	font-family: 'KentoRegular';
	font-weight: normal;
	margin-bottom: 2vw;
	white-space: break-spaces;
}

.diapo .diapo__data_cont .diapo__data_cont_cont {
	white-space: break-spaces;
	width: 24.583vw;
}

.carrousel__controller {
	text-align: center;
}

.carrousel__controller a,
.carrousel__controller a.btn__rombo_svg::before {
	width: 5.906vw;
	height: 4.76vw;
}

.carrousel__controller {
	position: absolute;
	bottom: 6vw;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
	height: 6vw;
	width: 6vw;
}

.carrousel__controller .carrousel__controller-left {
	position: absolute;
	left: 0px;
}

.carrousel__controller .carrousel__controller-right {
	position: absolute;
	right: 0px;
}

.carrousel__controller .btn__rombo_svg .btn__rombo_txt svg {
	width: 1.3vw;
}

.bloque__negro-inversion-hablamos {
	padding-top: 8vw;
}

.bloque__negro-inversion-hablamos .bloque__hablamos .bloque_hablamos_txt {
	margin-top: 0vw;
}

.btn__ver {
	position: relative;
	width: 8.594vw;
	text-align: center;
	display: block;
	text-decoration: none;
	font-family: 'KentoRegular';
	font-weight: normal;
	margin-bottom: 2vw;
	font-size: 0.833vw;
	line-height: 0.833vw;
	color: var(--colorNegro);
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
}

.btn__ver span {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.btn__ver-visita {
	width: 14.167vw;
}

.btn__ver-visita span {
	width: 14.167vw;
}

.btn__ver-invierte {
	width: 8.594vw;
	margin-top: 1.4vw;
}

.btn__ver-invierte span {
	width: 8.594vw
}


.btn__ver:hover {
	color: var(--colorVerde);
}

.btn__ver svg path {
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	stroke: var(--colorNegro);
	fill: var(--colorVerde);
}

.btn__ver:hover svg path {
	stroke: var(--colorVerde);
	fill: var(--colorNegro);
}

.btn__ver-visita svg path {
	stroke: var(--colorNegro);
	fill: #fff;
}

.btn__ver-visita-verde svg path {
	stroke: var(--colorNegro);
	fill: transparent;
}

.btn__ver-visita:hover {
	color: #fff;
}

.btn__ver-visita:hover svg path {
	stroke: #fff;
	fill: var(--colorNegro);
}

.btn__ver-visita-verde:hover svg path {
	stroke: var(--colorVerdeOsc);
	fill: var(--colorNegro);
}

.btn__ver-visita-verde:hover {
	color: var(--colorVerdeOsc);
	;
}

.bloque__colscroll_info_img-movil {
	display: none;
}

.fondo__verde-cards {
	/* padding-top: 30vh; */
	padding-top: 10vh;
}

.hover__enlace {
	display: inline-block;
	position: relative;
}

.hover__enlace .hover__enlace_line {
	position: absolute;
	bottom: 0.3vw;
	height: 3px;
	width: 0%;
	left: 2px;
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	background-color: var(--colorVerde);
}

.hover__enlace:hover .hover__enlace_line {
	width: calc(100% - 5px);
}

.hover__enlace-small .hover__enlace_line {
	height: 2px;
	bottom: 2px;
}

footer.verde .hover__enlace .hover__enlace_line {
	background-color: var(--colorNegro);
}

.bloque__legal {
	background-color: var(--colorVerde);
	padding: 2vw 30px;
	font-size: 18px;
	margin-top: -1px;
	line-height: 25px;
}

.bloque__legal__data,
.bloque__legal__header {
	display: flex;
}

.bloque__legal__data .bloque__legal__left,
.bloque__legal__header .bloque__legal__header_left {
	width: 33vw;
}

.bloque__legal__header .bloque__legal__header_right,
.bloque__legal__data .bloque__legal__right {
	width: 50vw;
	margin-left: 7vw;
}

.bloque__legal__header {
	font-size: 1.302vw;
	line-height: 1.302vw;
	font-family: 'KentoRegular';
}

.bloque__legal__header {
	border-top: 1px solid var(--colorNegro);
	padding-top: 2vw;
	padding-bottom: 2vw;
}

.bloque__legal h2,
.bloque__legal h3 {
	font-size: 2.083vw;
	line-height: 2.083vw;
	font-family: 'KentoRegular';
	font-weight: normal;
}

.bloque__legal hr {
	border: 0px;
	height: 1px;
	background-color: var(--colorNegro);
	width: 100%;
	margin: 3vw 0px;
}

.bloque__legal h2 {
	font-size: 1.302vw;
	line-height: 1.302vw;
}

.bloque__legal table {
	border-collapse: collapse;
}

.bloque__legal table tr th {
	font-size: 1.302vw;
	line-height: 1.302vw;
	font-family: 'KentoRegular';
	text-align: left;
	font-weight: normal;
	border-top: 1px solid var(--colorNegro);
	border-bottom: 1px solid var(--colorNegro);
}

.bloque__legal table tr td {
	border-bottom: 1px solid var(--colorNegro);
}

.bloque__legal a {
	color: var(--colorNegro);
}

.header-404 .header__ani_center {
	top: 10vw !important;
}

.header__btn404 {
	position: absolute;
	bottom: 0px;
	text-align: center;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
}

.activeMenuB {
	overflow: hidden;
}

.int_header__titulo_ani,
.header__titulo_ani {
	position: relative;
	line-height: 5vw;
	font-size: 5.208vw;
	font-family: 'KentoRegular';
	position: relative;
	margin: 0px auto 1.5vw auto;
	font-weight: normal;
	max-width: 64vw;
	height: 15vw;
	transform: scale(2.5);
}

.int_header__titulo_ani .int_header__titulo_ani_1,
.int_header__titulo_ani .int_header__titulo_ani_2,
.int_header__titulo_ani .int_header__titulo_ani_3,
.header__titulo_ani .header__titulo_ani_1,
.header__titulo_ani .header__titulo_ani_2,
.header__titulo_ani .header__titulo_ani_3 {
	position: absolute;
	width: 100%;
	top: 0px;
	left: 0px;
	overflow: hidden;
}

.int_header__titulo_ani .int_header__titulo_ani_1 .tit,
.int_header__titulo_ani .int_header__titulo_ani_2 .tit,
.int_header__titulo_ani .int_header__titulo_ani_3 .tit,
.header__titulo_ani .header__titulo_ani_1 .tit,
.header__titulo_ani .header__titulo_ani_2 .tit,
.header__titulo_ani .header__titulo_ani_3 .tit {
	position: absolute;
	width: 100%;
	top: 0px;
	left: 0px;
	width: 64vw;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
}

.int_header__titulo_ani .int_header__titulo_ani_3,
.header__titulo_ani .header__titulo_ani_3 {
	background-color: var(--colorVerde);
	left: 50%;
	transform: translateX(-50%);
	clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
	width: 19vw;
	height: 15vw;
	overflow: hidden;
}

.int_header__titulo_ani .int_header__titulo_ani_1,
.header__titulo_ani .header__titulo_ani_1 {
	width: 0vw;
	height: 15vw;
	left: 32vw;
	transform: none;
}

.int_header__titulo_ani .int_header__titulo_ani_1 .tit,
.header__titulo_ani .header__titulo_ani_1 .tit {
	left: 0;
	transform: none;
}

.int_header__titulo_ani .int_header__titulo_ani_2,
.header__titulo_ani .header__titulo_ani_2 {
	width: 0vw;
	height: 15vw;
	left: 32vw;
	transform: none;
}

.int_header__titulo_ani .int_header__titulo_ani_2 .tit,
.header__titulo_ani .header__titulo_ani_2 .tit {
	left: auto;
	transform: none;
	right: 0;
}

.int_header__titulo_ani .int_header__titulo_ani_3 .tit,
.header__titulo_ani .header__titulo_ani_3 .tit {
	left: -100%;
}

.int_header__titulo_ani .tit .ocu,
.header__titulo_ani .tit .ocu {
	opacity: 0;
}

.int_header__titulo_ani .tit .linet,
.header__titulo_ani .tit .linet {
	display: block;
	padding-top:1vw;
	height:5vw
}
.int_header__titulo_ani.--center .tit .linet {
	height:6vw
}

.int_header__titulo_ani .int_header__titulo_ani_1 .linet1,
.header__titulo_ani .header__titulo_ani_1 .linet1 {
	transform: translateX(-3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_1 .linet3,
.header__titulo_ani .header__titulo_ani_1 .linet3 {
	transform: translateX(3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_2 .linet1,
.header__titulo_ani .header__titulo_ani_2 .linet1 {
	transform: translateX(3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_2 .linet3,
.header__titulo_ani .header__titulo_ani_2 .linet3 {
	transform: translateX(-3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_3 .linet1,
.header__titulo_ani .header__titulo_ani_3 .linet1 {
	transform: translateX(3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_3 .linet2,
.header__titulo_ani .header__titulo_ani_3 .linet2 {
	transform: translateX(-3vw);
}

.int_header__titulo_ani .int_header__titulo_ani_1 .linet2,
.int_header__titulo_ani .int_header__titulo_ani_2 .linet2,
.int_header__titulo_ani .int_header__titulo_ani_3 .linet2,
.header__titulo_ani .header__titulo_ani_1 .linet2,
.header__titulo_ani .header__titulo_ani_2 .linet2,
.header__titulo_ani .header__titulo_ani_3 .linet2 {
	width: 64vw;
	text-align: center;
	letter-spacing: -1px;
}

.int_header__titulo_ani .int_header__titulo_ani_3 .linet3,
.header__titulo_ani .header__titulo_ani_3 .linet3 {
	transform: translateX(3vw);
}

.int_header__titulo_ani {
	width: 90vw;
	max-width: 90vw;
	line-height: 6.1vw;
	font-size: 6.7vw;
	height: 22vw;
}

.int_header__titulo_ani .int_header__titulo_ani_1 .tit,
.int_header__titulo_ani .int_header__titulo_ani_2 .tit,
.int_header__titulo_ani .int_header__titulo_ani_3 .tit {
	width: 90vw;
}

.int_header__titulo_ani .int_header__titulo_ani_1 .linet2,
.int_header__titulo_ani .int_header__titulo_ani_2 .linet2,
.int_header__titulo_ani .int_header__titulo_ani_3 .linet2 {
	width: 90vw;
}

.int_header__titulo_ani .int_header__titulo_ani_2 {
	left: 45vw;
	height: 22vw;
}

.int_header__titulo_ani .int_header__titulo_ani_1 {
	left: 45vw;
	height: 22vw;
}

.int_header__titulo_ani .int_header__titulo_ani_3 {
	width: 30vw;
	height: 22vw;
}


.header.header__inversion .header__ani_center {
	margin-top: 5vw;
}

.header.header__inversion .header__data {
	top: calc(50% - 24vw);
	height: 48vw;
}

.header.header__faqs .header__ani_center {
	margin-top: 6vw;
}

.header.header__faqs .header__data {
	top: calc(50% - 22.5vw);
	height: 50vw;
}

.menu__menu li:nth-child(5),
.menu__menu li:nth-child(6) {
	display: none;
}

.header__btn404 .btn__ver {
	margin: 0px;
}

.header__404.header-ani .header__ani_center {
	margin-top: 3vw;
}

.header__404 .header__data {
	height: calc(45vw + 140px);
	top: 3vw;
}

#header__404_data {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

*{
	cursor: none !important
}

.cursor{
	z-index: 99999999999999999999 !important;
	width: 34px;
	height: 34px;
	position: fixed;
	border-radius: 50%;
	pointer-events: none;
	top: 0px;
	left: 0px;
	mix-blend-mode: difference !important;
}
.cursor.display-none {
	display: none !important;
}
.cursor svg{
	-moz-transition: var(--aniBtnSlider);
	-ms-transition: var(--aniBtnSlider);
	-o-transition: var(--aniBtnSlider);
	transition: var(--aniBtnSlider);
	width: 0px;
	height: 0px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.cursor.active svg{
	width: 34px;
	height: 34px;
}
.cursor.hover svg{
	width: 17px;
	height: 17px;
}

.cursor path{
	fill: #dedede;
}
.cursor.is-hidden svg{
	width: 0px;
	height: 0px;
}


@media screen and (min-width: 1900px) and (max-height: 920px) {

	.header.header__faqs .header__data,
	.header.header__inversion .header__data,
	.header2 .header__data {
		top: 3vw;
	}
}

@media screen and (min-width: 1921px) {

	.slider_active_4 .slider__ff5,
	.slider_active_3 .slider__ff4,
	.slider_active_2 .slider__ff3,
	.slider_active_1 .slider__ff2 {
		margin-left: -8vw;
	    }

	.slider .slider__figuras .slider__figuras__figura {
		width: 3190px;
		height: 2680px;
	    }

	.menu__hover-top,
	.menu__hover-bottom {
		clip-path: polygon(50% 0%, 0 100%, 100% 100%);
		width: 3000px;
		height: 1258px;
	}

	.menu__hover-top {
		clip-path: polygon(50% 100%, 0 0, 100% 0);
	}

	.menu__hover-right,
	.menu__hover-left {
		clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0 100%, 0 0);
		height: 1680px;
		width: 2000px;
	}

	.menu__hover-right {
		clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 50%, 50% 0);
	}

	.menu__hover#menu__hover_inversion video,
	.menu__hover#menu__hover_inversion img {
		left: 40%;
	}

	.menu__hover#menu__hover_producto video,
	.menu__hover#menu__hover_producto img {
		left: 62%;
	}
}
@media screen and (min-width: 2600px) {
	.slider .slider__figuras .slider__figuras__figura {
		width: 199.615vw;
		height: 167.692vw;
	}
	.bloque__scroll__later .bloque__sl__mask img, .bloque__scroll__later .bloque__sl__mask video {
		margin-bottom: 3vw;
	    }
		.slider_active_4 .slider__ff5, .slider_active_3 .slider__ff4, .slider_active_2 .slider__ff3, .slider_active_1 .slider__ff2 {
			margin-left: -38vw;
		}
}

@media screen and (max-width: 1700px) {

	.slider_active_1 .slider__ff2 {
		transform: translateY(-50%) scale(0.4) !important;
	}

	.slider_active_1 .slider__ff3 {
		transform: translateY(-50%) scale(0.2) !important;
	}

	.slider_active_1 .slider__ff4 {
		transform: translateY(-50%) scale(0) !important;
	}

	.slider_active_2 .slider__ff3 {
		transform: translateY(-50%) scale(0.4) !important;
	}

	.slider_active_2 .slider__ff4 {
		transform: translateY(-50%) scale(0.2) !important;
	}

	.slider_active_3 .slider__ff4 {
		transform: translateY(-50%) scale(0.4) !important;
	}

	.bloque__faqs {
		padding-top: 20vh;
	}
}

@media screen and (max-width: 1500px) {

	.menu__hover#menu__hover_producto video,
	.menu__hover#menu__hover_producto img {
		height: 110vh;
		left: 56%;
	}

	.menu__hover#menu__hover_inversion.is-active video,
	.menu__hover#menu__hover_inversion.is-active img {
		top: 54%;
	}

	.menu__hover#menu__hover_inversion video,
	.menu__hover#menu__hover_inversion img {
		top: 59%;
		height: 110vh;
		left: 43%;
	}


}

@media screen and (max-width: 1366px) {
	.bloque__scroll__later .bloque__sl__mask {
		top: 50%;
	}

	.contacto .contacto__col2 .contacto__mensaje,
	.form__control_textarea {
		font-size: 35px !important;
		line-height: 35px !important;
	}

	.menu__hover#menu__hover_producto img {
		top: 53%;
		height: 40%;
		left: 58%;
	}

	.menu__hover#menu__hover_inversion img {
		height: 60%;
	}

	.menu__hover#menu__hover_inversion img {
		left: 39%;
	}

	.menu__hover#menu__hover_faqs img {
		height: 100%;
		top: 59%;
	}

	.menu__hover#menu__hover_faqs.is-active img {
		top: 55%;
	}

	.menu__hover#menu__hover_marca img {
		height: 100%;
		top: 80%;
	}

	.menu__hover#menu__hover_marca.is-active img {
		height: 100%;
		top: 76%;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info-space {
		height: 250px;
	}

	.menu__menu li:nth-child(1) {
		top: 6vw;
	}

	.menu .menu__reserved {
		font-size: 0.9vw;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_img {
		padding: 10vh 0px 10vh;
	}

	.card .card__data {
		width: 30.708vw;
		font-size: 1.171vw;
		line-height: 1.464vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col img,
	.contenedor__scroll_lateral-data .csls__row .csls__col video {
		max-width: 100%;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_tit {
		line-height: 1.611vw;
		font-size: 1.611vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_txt {
		line-height: 1.464vw;
		font-size: 1.171vw;
	}

	.contenedor__scroll_lateral-data .csls__col_data {
		padding: 0px 2vw;
	}

	.contenedor__scroll_lateral-data {
		padding: 8vw 4vw;
	}

	.bloque__txtc .bloque__txtc__pretitulo {
		font-size: 1.171vw;
		line-height: 1.4vw;
	}

	.tabla {
		font-size: 1.171vw;
		line-height: 1.4vw;
	}

	.tabla .row .col {
		width: 33%;
		padding: 2.177vw 0vw;
	}

	.bloque__triangulo-producto .bloque__triangulo__medium {
		font-size: 1.611vw;
		line-height: 1.611vw;
		width: 43.338vw;
		margin-top: 2.708vw;
	}

	.bloque__triangulo-producto .kentoMedium .split-line {
		height: 1.8vw;
		line-height: 2.6vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data {
		font-size: 1.171vw;
		line-height: 1.4vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_cont {
		width: 20.646vw;
	}

	.bloque__hablamos .bloque_hablamos_txt {
		font-size: 1.171vw;
		line-height: 1.4vw;
		width: 16vw;
	}

	.bloque__triangulo .bloque__triangulo__medium {
		width: 42.813vw;
		margin-top: 2vw;
	}

	.bloque__triangulo-inversion .cita .cita__cita,
	.bloque__triangulo__cols .bloque__triangulo__col {
		font-size: 1.171vw;
		line-height: 1.464vw;
	}

	.bloque__triangulo__cols .bloque__triangulo__col .bloque__triangulo__col_title {
		font-size: 1.611vw;
		line-height: 1.8vw;
	}

	.bloque__triangulo-inversion .cita .cita__cita {
		max-width: 30.417vw;
		margin-left: 6.708vw;
	}

	.bloque__triangulo-inversion .cita {
		margin-left: 11.2vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
		font-size: 1.171vw;
		line-height: 1.464vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
		width: 28.042vw;
	}

	.bloque__txtc .bloque__txtc__titulo h2 {
		font-size: 5.198vw;
		line-height: 5.198vw;
		width: 66.911vw;
		margin: auto;
	}

	.bloque__txtc .split-line {
		height: 5.198vw;
		line-height: 7vw;
	}

	.bloque__txtc .cita {
		margin-left: 22vw;
		font-size: 1.171vw;
	}

	.bloque__txtc .cita .cita__cita {
		max-width: 20vw;
	}

	.header2 .cita-header,
	.diapo .diapo__data_cont .diapo__data_cont_cont {
		font-size: 1.171vw;
		line-height: 1.464vw;
	}

	.header2 .cita .cita__cita {
		max-width: 19.417vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
		width: 17vw;
		display: block;
		font-size: 1.171vw;
	}

	.line {
		height: 2px;
	}

	.slider_active_1 .slider__ff2 {
		transform: translateY(-50%) scale(0.5) !important;
	}

	.slider_active_1 .slider__ff3 {
		transform: translateY(-50%) scale(0.2) !important;
	}

	.slider_active_1 .slider__ff4 {
		transform: translateY(-50%) scale(0) !important;
	}

	.slider_active_2 .slider__ff3 {
		transform: translateY(-50%) scale(0.5) !important;
	}

	.slider_active_2 .slider__ff4 {
		transform: translateY(-50%) scale(0.2) !important;
	}

	.slider_active_3 .slider__ff4 {
		transform: translateY(-50%) scale(0.5) !important;
	}

	footer .footer__btn-scroll {
		bottom: 54vh;
	}

	footer .footer__btn-rs {
		bottom: 2.5vw;
	}

	footer .footer__btn-scroll .btn__rombo,
	footer .footer__btn-rs .btn__rombo {
		width: 90px !important;
		height: 90px !important;
	}

	footer .footer__btn-scroll .btn__rombo.btn__rombo_svg::before,
	footer .footer__btn-rs .btn__rombo.btn__rombo_svg::before {
		width: 90px !important;
		height: 90px !important;
	}

	footer .footer__btn-scroll .btn__rombo .btn__rombo_txt svg {
		width: 16px;
	}

	footer .footer__btn-rs .btn__rombo .btn__rombo_txt svg {
		width: 20px;
	}

	.pieza {
		zoom: 1;
	}

	.bloque__triangulo__medium-marca {
		width: 42.813vw !important;
	}

	.bloque__faqs ul li .faqs .faqs__header {
		font-size: 24px;
		line-height: 30px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_title .split-line {
		height: 30px;
		line-height: 42px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_number {
		font-size: 14px;
	}

	.header__contacto_data,
	.form__control {
		font-size: 16px;
		line-height: 20px;
	}

	.bloque__legal {
		background-color: var(--colorVerde);
		padding: 8vw 30px;
		font-size: 16px;
		line-height: 20px;
	}

	header .header__logo {
		width: 206px;
	}

	header .header__logo a {
		width: 206px;
	}

	header .header__logo a svg.header__logo_txt {
		width: 147px;
		margin-top: 12px;
	}

	header .header__logo a svg.header__logo_logo {
		width: 54px;

	}

	.menu__container .btn__rombo_svg {
		height: 80px;
		width: 80px;
	}

	.menu__container .btn__rombo_svg .btn__rombo_txt svg {
		width: 20px;
	}

	.contacto__input .contacto__input_error {
		margin-top: -23px;
	}

	.header .header__ani_center {
		margin-top: 1vw;
	}

	.header.header__inversion .header__ani_center {
		margin-top: 7vw;
	}

	.header.header__faqs .header__ani_center {
		margin-top: 8vw;
	}

	.header .header__ani_left .header__ani {
		width: 35vw;
	}

	.header .header__ani_right .header__ani video {
		width: 18.771vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col svg {
		height: 37vw !important;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-unicidad video {
		max-height: 38vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad video {
		max-height: 37vw;
		margin-top: -10vw;
	}

	.header__404.header-ani .header__ani_center {
		margin-top: 4vw;
	}
	.slider .slider__rombo .slider__rombo_data span {
		margin-top: 8px;
	}
	
}

@media screen and (max-width: 1024px) {
	*{
		cursor: initial !important;
	}
	.cursor{
		display: none !important;
	}
	
	header .header__logo a svg.header__logo_logo {
		width: 42px;
	}

	header .header__logo a svg.header__logo_txt {
		margin-top: 6px;
	}

	.menu__menu li {
		height: 4vw;
	}

	.menu__menu li a.menu__menu-a {
		font-size: 4vw;
		line-height: 6vw;
	}

	.menu__menu li:nth-child(1) {
		top: 10vw;
	}

	.menu__menu li:nth-child(2) {
		left: 2.5vw;
	}

	.menu__menu li:nth-child(3) {
		right: 2.5vw;
	}

	header .header__logo {
		left: 2.344vw;
		top: 3.597vw;
	}

	header .header__logo a svg {
		width: 14.355vw;
	}

	header .header__menu-content {
		right: 2.344vw;
		top: 1.953vw;
		width: 5.859vw;
		height: 5.859vw;
		z-index: 3;
	}

	.header__menu {
		width: 5.859vw;
		height: 5.859vw;
	}

	.header__menu .header__menu-c1,
	.header__menu .header__menu-c2 {
		top: 0.8vw;
		left: 0.8vw;
		width: 4.2vw;
		height: 4.2vw;
	}

	.header__menu .header__menu-l1,
	.header__menu .header__menu-l2,
	.header__menu .header__menu-l3 {
		top: 2.8vw;
		width: 2vw;
	}

	.header__menu-close:hover .header__menu-l2,
	.header__menu-close:hover .header__menu-l3 {
		top: 2.8vw;
		width: 2vw;
	}

	.header__menu-close .header__menu-l2,
	.header__menu-close .header__menu-l3 {
		left: 2vw;
	}

	.btn__rombo {
		font-size: 1.172vw;
		line-height: 1.172vw;
		width: 17.871vw;
		height: 8.201vw;
	}

	.btn__rombo::before {
		width: 17.871vw;
		height: 8.201vw;
	}

	.menu .menu__btn-contacto {
		left: 3.418vw;
	}

	.btn__rombo {
		font-size: 1.172vw;
		line-height: 1.172vw;
	}

	.menu .menu__btn-invierte .btn__rombo {
		transform: translateY(9vw);
	}

	.menu__menu li:nth-child(4) {
		bottom: 15vw;
	}

	.menu__menu li:nth-child(4) a span {
		margin-left: -5px;
	}

	.menu .menu__reserved {
		font-size: 1.172vw;
	}

	.menu .menu__btn-invierte {
		height: 8.2vw;
		right: 11vw;
	}

	.menu .menu__btn-rs {
		right: 7vw;
	}

	.btn__rombo_svg .btn__rombo_txt svg {
		width: 1.85vw;
	}

	.btn__rombo_svg::before {
		width: 8.201vw;
	}

	.menu__hover-top,
	.menu__hover-bottom {
		width: 985px;
	}

	.menu__hover-right,
	.menu__hover-left {
		height: 2560px;
	}

	.menu__hover#menu__hover_inversion video,
	.menu__hover#menu__hover_inversion img {
		left: 28%;
	}

	.menu__hover#menu__hover_producto video,
	.menu__hover#menu__hover_producto img {
		left: 69%;
	}

	.header .header__titulo {
		max-width: 75vw;
		margin: auto;
	}

	.header .header__pretitulo,
	.bloque__txtc .bloque__txtc__pretitulo {
		font-size: 1.563vw;
		line-height: 2.148vw;
		margin-bottom: 2vw;
	}

	.header .header__titulo h1,
	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 5.859vw;
		font-size: 6.348vw;
	}

	.header .header__titulo h1 .split-line,
	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 5.859vw;
		line-height: 6.8vw;
	}

	.pieza {
		width: 4.8vw;
		height: 4.8vw;
	}

	.pieza .pieza__line {
		width: 4.8vw;
		left: calc(50% - calc(4.8vw / 2));
	}

	.pieza .pieza__rombo {
		width: 1vw;
		height: 1vw;
		top: calc(50% - 0.5vw);
		left: calc(50% - 0.5vw);
	}

	.header .pieza-header {
		margin-bottom: 3.906vw;
	}

	.cita .cita__title {
		font-size: 3.3vw;
		line-height: 6vw;
		height: 5vw;
	}

	.cita .cita__cita {
		max-width: 27.417vw;
		margin-left: 7.708vw;
		font-family: 'Work Sans', sans-serif;
		font-size: 1.465vw;
		line-height: 1.7vw;
	}

	.cita-header {
		margin-top: 12px;
		margin-left: -28vw;
	}

	.bloque__blanco .texto__grande-marca {
		font-size: 6.348vw;
		line-height: 6.348vw;
		max-width: 94vw;
		margin: 2.76vw auto 8.5vw;
	}

	.bloque__blanco {
		padding-top: 9.625vw;
	}

	.bloque__blanco-diamante svg {
		width: 6.8vw;
	}

	.texto__pequeno-marca {
		margin: 4.2vw auto;
		max-width: 57.617vw;
		font-size: 2.14vw;
		line-height: 2.5vw;
	}

	.slider .slider__data .slider__title {
		font-size: 2.734vw;
	}

	.slider .slider__data .slider__title .slider__title_title {
		line-height: 5vw;
	}

	.slider .slider__data .slider__cont .slider__cont_cont {
		font-size: 1.465vw;
		line-height: 1.855vw;
	}

	.slider .slider__data {
		width: 30vw;
		left: 2.208vw;
	}

	.slider .slider__data .slider__cont .slider__cont_cont,
	.slider .slider__data .slider__cont {
		height: 12vh;
	}

	/* .slider_active_2 .slider__cont__inner {
		transform: translateY(-12vh);
	}

	.slider_active_3 .slider__cont__inner {
		transform: translateY(-24vh);
	}

	.slider_active_4 .slider__cont__inner {
		transform: translateY(-36vh);
	} */

	.bloque__triangulo .bloque__triangulo__tri {
		margin-top: 20vw;
	}

	.bloque__triangulo .bloque__triangulo__titulo {
		font-size: 8.3vw;
		line-height: 8.3vw;
	}

	.bloque__triangulo .bloque__triangulo__txt {
		width: 60.438vw;
		font-size: 2.4vw;
		line-height: 2.4vw;
		margin-top: 15vw;
	}

	.bloque__triangulo .bloque__triangulo__medium {
		width: 56.445vw;
		font-size: 3.418vw;
		line-height: 3.418vw;
		margin: 14vw auto 0px;
	}

	.bloque__triangulo .bloque__triangulo__medium .split-line {
		height: 3.418vw;
		line-height: 4.5vw;
	}

	.bloque__triangulo .bloque__triangulo__rombodeco {
		width: 2vw;
		height: 2vw;
		margin: 4.125vw auto 5vw;
	}

	.bloque__vision_fondo {
		top: 50px;
	}

	.bloque__vision__txtbig_container {
		font-family: 'KentoRegular';
		margin-left: 2vw;
		width: 96vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols {
		font-size: 1.4vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
		width: 24vw;
	}

	.bloque__vision__txtbig_container .cita .cita__cita {
		max-width: 42.688vw;
		margin-left: 3.5vw;
	}

	.line {
		height: 1px;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-num {
		width: 5vw;
		padding-top: 4px;
		height: 1.3vw;
		font-size: 1.75vw;
		line-height: 1.75vw;
		height: 2vw;
	}

	.bloque__listado .bloque__listado_item-linea svg {
		position: absolute;
		margin-top: -5px;
		height: 11px !important;
		left: -2px;
	}

	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 7.4vw;
		font-size: 7.813vw;
	}

	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 6.2vw;
		line-height: 7.2vw;
	}

	.bloque__txtc .cita .cita__cita {
		margin-left: 0vw;
		max-width: 25vw;
	}

	.bloque__txtc .cita {
		margin-left: 21vw;
	}

	.bloque__txtc .bloque__txtc__titulo__rombodeco {
		width: 2vw;
		height: 2vw;
		margin: 4vw auto 4vw;
	}

	.bloque__txtc .cita {
		bottom: 4vw;
		right: 2vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_title {
		margin: 0px 0px 1vw 0px;
		font-size: 3.418vw;
		line-height: 3.418vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
		width: 38vw;
		padding-bottom: 10.417vw;
		font-size: 1.465vw;
		line-height: 1.7vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_number {
		margin-left: -5vw;
		font-size: 1.563vw;
		margin-top: 1px;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre {
		font-size: 10vw !important;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre img,
	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre svg {
		height: 6.9vw;
		margin: 0px 30px;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_img img {
		height: 70vh;
	}

	.bloque__hablamos .bloque_hablamos_txt {
		width: 20.063vw;
		margin: 8vw auto 0px;
		font-size: 1.5vw;
		line-height: 1.6vw;
	}

	footer .footer .footer__linea1 {
		transform: translate(-50%, -50%) rotate(52deg);
	}

	footer .footer .footer__linea2 {
		transform: translate(-50%, -50%) rotate(128deg);
	}

	footer .footer__txt_top,
	footer .footer__txt_bottom {
		font-size: 1.172vw;
		line-height: 1.4vw;
	}

	footer .footer__txt_top {
		width: 15.417vw;
	}

	footer .footer__txt_bottom {
		width: 30.438vw;
	}

	.menu {
		z-index: 9 !important;
	}

	.header2 .cita .cita__cita {
		max-width: 29.417vw;
		margin-left: 3.708vw;
	}

	.header .header__ani_center {
		top: 27vw;
	}

	.header .header__arrow .btn__rombo_svg {
		transform: scale(0.7) translateY(1.7vw);
	}

	.card .card__fondo {
		width: 80.438vw;
	}

	.card {
		width: 83.438vw;
		height: 35.24vw;
	}

	.card .card__data .card__data_title {
		font-size: 3.418vw;
		line-height: 3.418vw;
		margin-bottom: 1.5vw;
	}

	.card .card__data {
		width: 39.708vw;
		font-size: 1.563vw;
		line-height: 1.8vw;
		left: 34.917vw;
	}

	.card .card__data .card__data_number {
		top: 1px;
		font-size: 2.148vw;
		line-height: 2.148vw;
	}

	.btn__rombo-vision {
		margin-top: -3vw;
		height: 6vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
		width: 17vw;
		font-size: 1.563vw;
		line-height: 1.8vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col {
		width: 44%;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col {
		width: 50%;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_tit {
		line-height: 2.148vw;
		font-size: 2.148vw;
		margin-bottom: 3vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_txt {
		line-height: 1.8vw;
		font-size: 1.563vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_pre {
		font-size: 2.734vw;
		line-height: 4vw;
		margin-bottom: 1.938vw;
	}

	.contenedor__scroll_lateral-data .csls__row {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 6.348vw;
		font-size: 6.348vw;
	}

	.bloque__txtc-producto .bloque__txtc__titulo {
		width: 80.229vw;
	}

	.tabla .row .col.col-txt {
		width: 50vw;
		padding-right: 5vw;
	}

	.tabla {
		font-size: 1.563vw;
		line-height: 1.8vw;
	}

	.bloque__triangulo-producto .kentoMedium .split-line {
		height: 2.3vw;
		line-height: 3.8vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data {
		font-size: 1.563vw;
		line-height: 1.7vw;
		padding-left: 4.281vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_cont {
		width: 23.646vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_title {
		font-size: 2.734vw;
		line-height: 2.734vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_number {
		font-family: 'KentoRegular';
		font-size: 1.5vw;
		line-height: 1.5vw;
		top: 1px;
	}

	.bloque__product_txt .bloque__product_txt_data {
		width: 47.168vw;
		font-size: 2.734vw;
		line-height: 2.832vw;
	}

	.bloque__product_txt .bloque__product_txt_data .split-line {
		height: 2.7vw;
		line-height: 3.8vw;
	}

	.bloque__product_txt svg {
		width: 6.836vw;
	}

	.bloque__scroll__later .bloque__sl__mask {
		transform: translate(0%, -50%);
	}

	.bloque__scroll__later .bloque__sl__pantalla {
		height: auto;
		padding-bottom: 16vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla:nth-child(2n + 1) .bloque__sl__data {
		float: none;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data {
		width: 40.927vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_title {
		font-size: 2.734vw;
		line-height: 2.734vw;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_cont {
		width: 39.646vw;
	}

	.bloque__scroll__later {
		padding-bottom: 10vw;
	}

	.bloque__triangulo .bloque__triangulo__pretitulo {
		font-size: 3.418vw;
	}

	.bloque__triangulo.bloque__triangulo-inversion .bloque__triangulo__tri {
		margin-top: 20vw;
	}

	.bloque__triangulo-inversion .cita .cita__cita,
	.bloque__triangulo__cols .bloque__triangulo__col {
		font-size: 1.563vw;
		line-height: 1.953vw;
	}

	.bloque__triangulo-inversion .cita .cita__cita {
		max-width: 40.417vw;
		margin-left: 8.708vw;
	}

	.bloque__triangulo-inversion .cita {
		margin-left: 15.2vw;
	}

	.bloque__colscroll .bloque__colscroll_colimgs {
		width: 50%;
	}

	.bloque__colscroll .bloque__colscroll_coldata {
		width: 50%;
	}

	.diapo .diapo__data_cont .diapo__data_cont_title {
		font-size: 3.418vw;
		line-height: 3.418vw;
	}

	.header2 .cita-header,
	.diapo .diapo__data_cont .diapo__data_cont_cont {
		font-size: 1.563vw;
		line-height: 1.953vw;
	}

	.diapo .diapo__num {
		font-size: 2.148vw;
		width: 6vw;
		padding-top: 4vw;
	}

	.diapo .diapo__img img,
	.diapo .diapo__img video {
		height: 34.18vw;
		width: auto;
	}

	.diapo {
		width: 80vw;
	}

	.fondo__verde-cards {
		padding-top: 0vh;
	}

	.contenedor__scroll_lateral-data .csls__col_data {
		padding: 0px 0px 0px 2vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col img,
	.contenedor__scroll_lateral-data .csls__row .csls__col video,
	.bloque__vision__txtbig_container-data {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.bloque__listado .bloque__listado_item {
		padding-left: 0px;
		padding-right: 0px;
	}

	.bloque__listado .bloque__listado_item {
		padding-bottom: 130px;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-txt {
		position: absolute;
		left: 55px;
		bottom: 0px;
		top: auto;
		transform: none;
		width: 300px;
		height: 100px;
		right: auto;
		font-size: 1.4vw;
		line-height: 1.4vw;
		pointer-events: none;
		z-index: -1;
		opacity: 1;
	}

	.bloque__colscroll-inversion {
		padding: 0px 2vw;
	}

	.bloque__triangulo__cols {
		display: flex;
		margin-top: 7vw;
		margin-left: 2vw;
	}

	.bloque__triangulo__cols .bloque__triangulo__col {
		width: 48.479vw;
		padding-right: 4vw;
	}

	.bloque__triangulo__cols .bloque__triangulo__col .bloque__triangulo__col_title {
		font-size: 2.148vw;
		line-height: 2.148vw;
		width: 25vw;
	}

	.bloque__colscroll.bloque__colscroll-inversion .bloque__colscroll_colimgs img {
		margin: 0vh;
	}

	.bloque__colscroll.bloque__colscroll-inversion .bloque__colscroll_colimgs img {
		margin: 0vh;
		width: 40vw;
		margin-left: -4vw;
		margin-top: 12vh;
	}

	.bloque__txtc .bloque__txtc__titulo h2 {
		width: 90vw;
	}

	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 6vw;
		font-size: 6vw;
	}

	footer .footer__btn-scroll {
		transform: scale(1.1);
	}

	footer .footer__btn-rs {
		transform: scale(1.1);
	}

	footer.verde .btn__rombo {
		height: 8.201vw !important;
	}

	.carrousel__controller {
		zoom: 1.3;
	}

	.bloque__faqs ul li .faqs .faqs__data_cont {
		padding: 1vw 10vw 2.604vw 16.438vw;
	}

	.header__contacto_contenedor {
		padding-top: 4vw;
	}

	.contacto__legal p {
		font-size: 12px;
		line-height: 15px;
	}

	.header.header-contacto .header__ani_center {
		top: 21vw;
	}

	.bloque__legal__data {
		display: block;
	}

	.bloque__legal__data .bloque__legal__left {
		width: 100%;
	}

	.bloque__legal__data .bloque__legal__right {
		width: 100%;
		margin-left: 0px;
	}

	.bloque__legal__header {
		font-size: 18px;
		line-height: 20px;
	}

	.bloque__legal {
		padding: 4vw 15px;
		font-size: 16px;
		line-height: 20px;
	}

	.bloque__legal__header {
		font-size: 14px;
		line-height: 17px;
	}

	.bloque__legal__header .bloque__legal__header_left {
		width: 50%;
	}

	.bloque__legal h2,
	.bloque__legal h3 {
		font-size: 28px;
		line-height: 28px;
	}

	.bloque__legal table tr th {
		font-size: 14px;
		line-height: 16px;
	}

	table {
		font-size: 14px;
		line-height: 16px;
	}

	.bloque__legal {
		padding: 4vw 15px 100px;
	}

	.header__btn404 .btn__ver {
		width: 120px;
		font-size: 14px;
		line-height: 14px;

	}

	footer .footer__btn-scroll .btn__rombo,
	footer .footer__btn-rs .btn__rombo {
		width: 82px !important;
		height: 82px !important;
	}

	footer .footer__btn-scroll .btn__rombo.btn__rombo_svg::before,
	footer .footer__btn-rs .btn__rombo.btn__rombo_svg::before {
		width: 82px !important;
		height: 82px !important;
	}

	footer .footer__btn-scroll .btn__rombo .btn__rombo_txt svg {
		width: 14px;
	}

	footer .footer__btn-rs .btn__rombo .btn__rombo_txt svg {
		width: 18px;
	}

	.int_header__titulo_ani,
	.header__titulo_ani {
		line-height: 6vw;
		font-size: 6.348vw;
		margin: 0px auto 1.5vw auto;
		max-width: 80vw;
		height: 17vw;
		transform: scale(2.5);
	}

	.int_header__titulo_ani .int_header__titulo_ani_1 .tit,
	.int_header__titulo_ani .int_header__titulo_ani_2 .tit,
	.int_header__titulo_ani .int_header__titulo_ani_3 .tit,
	.header__titulo_ani .header__titulo_ani_1 .tit,
	.header__titulo_ani .header__titulo_ani_2 .tit,
	.header__titulo_ani .header__titulo_ani_3 .tit {
		width: 80vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_1 .linet2,
	.int_header__titulo_ani .int_header__titulo_ani_2 .linet2,
	.int_header__titulo_ani .int_header__titulo_ani_3 .linet2,
	.header__titulo_ani .header__titulo_ani_1 .linet2,
	.header__titulo_ani .header__titulo_ani_2 .linet2,
	.header__titulo_ani .header__titulo_ani_3 .linet2 {
		width: 80vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_3,
	.header__titulo_ani .header__titulo_ani_3 {
		width: 24vw;
		height: 19vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_1,
	.header__titulo_ani .header__titulo_ani_1 {
		height: 19vw;
		left: 40vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_2,
	.header__titulo_ani .header__titulo_ani_2 {
		height: 19vw;
		left: 40vw;
	}
	
	.int_header__titulo_ani.--center .int_header__titulo_ani_2 {
		left: 35vw;
	}

	.header.header-marca .cita .cita__cita {
		max-width: 24.417vw;
	}

	.contacto__check_error,
	.contacto__mensaje_error {
		font-size: 12px;
	}

	.contacto__input .contacto__input_error {
		margin-top: -25px;
		font-size: 12px;
	}

	.form__control {
		margin-bottom: 3vw;
	}

	.contacto__input .contacto__input_error_ico {
		transform: scale(0.8);
	}

	.contacto__legalsub {
		margin-top: 4vw;
	}

	.mensaje__ok .mensaje__ok_title {
		font-size: 2vw;
		line-height: 2vw;
	}

	.btn__ver-invierte {
		width: 12vw;
		margin-top: 2vw;
	}

	.btn__ver-invierte span {
		width: 12vw;
		font-size: 1.1vw;
		line-height: 1.1vw;
	}

	.header .header__ani_center .header__ani_rombo {
		width: 50vw;
		height: 27vw;

	}

	.header .header__ani_center {
		width: 50vw;
		height: 50vw;
	}

	.header2 .header__ani {
		top: 50vw;
		width: 46vw;
		margin-left: 2vw;
		height: 46vw;
	}

	.header2 .header__data {
		height: 58vw;
		top: calc(50% - 29vw);
	}

	.header .header__ani_center {
		margin-top: 3vw;
	}

	.header.header__inversion .header__data {
		height: 64vw;
		top: calc(50% - 32vw);
	}

	.header.header__inversion .header__ani_center {
		margin-top: 10vw;
	}

	.header.header__faqs .header__data {
		height: 64vw;
		top: 4vw
	}

	.header.header__faqs .header__ani_center {
		margin-top: 12vw;
	}

	.bloque__scroll__later .bloque__sl__mask img,
	.bloque__scroll__later .bloque__sl__mask video {
		max-width: 44vw;
		margin-bottom: 1vw;
	}

	.header__404 .header__data {
		height: calc(52vw + 140px);
	}
	.int_header__titulo_ani .tit .linet, .header__titulo_ani .tit .linet {
		height: 6vw;
		padding-top: 1.2vw;
	    }
}

@media screen and (max-width: 992px) {
	header .header__logo a svg.header__logo_txt {
		display: none;
	}

	body.scroll-down header .header__logo a {
		width: 50px;
	}

	.header .header__ani_right .header__ani_rombo {
		position: absolute;
		z-index: -2;
		width: 113.634vw;

		right: -75.594vw;
		bottom: -32.271vw;
	}

	.header .header__ani_right .header__ani {
		position: absolute;
		z-index: -1;
		width: 38.771vw;

		right: -37.563vw;
		bottom: -41.646vw;
	}

	.bloque__hablamos_cont_btn {
		height: 32.542vw;
	}

	.pieza-header {
		margin-bottom: 3vw !important;
	}

	.header .header__pretitulo,
	.bloque__txtc .bloque__txtc__pretitulo {
		font-size: 1.823vw;
		line-height: 2.148vw;
		margin-bottom: 4vw;
	}

	.header .header__titulo h1,
	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 6.859vw;
		font-size: 7.348vw;
	}

	.header .header__titulo h1 .split-line,
	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 6.859vw;
		line-height: 8vw;
	}

	.cita .cita__title {
		font-size: 4.3vw;
		line-height: 8vw;
		height: 6vw;
	}

	.cita .cita__cita {
		max-width: 27.417vw;
		margin-left: 7.708vw;
		font-size: 1.8vw;
		line-height: 2.2vw;
	}

	.header__arrow {
		display: none;
	}

	.header .header__ani_left .header__ani_rombo {
		width: 190.938vw;

		left: -80.146vw;
		top: -34.813vw;
	}

	.header .header__ani_left .header__ani {
		width: 62.333vw;
		left: -61.458vw;
		top: -59.24vw;
	}

	.header .header__ani_right .header__ani video {
		width: 47.771vw !important;
	}

	.bloque__colscroll {
		margin-top: 0vw;
	}

	.slider .slider__controller {
		bottom: 22vw;
	}

	footer .footer .footer__linea1 {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	footer .footer .footer__linea2 {
		transform: translate(-50%, -50%) rotate(135deg);
	}

	footer .footer__menu1 {
		top: 21vh;
		left: 54%;
		transform: translateX(-50%);
	}

	footer .footer__menu2 {
		position: absolute;
		top: 72vh;
		left: 54%;
		right: auto;
		transform: translateX(-50%);
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info-space {
		height: 60vh;
	}

	.header .header__titulo {
		max-width: 90vw;
		margin: auto;
	}

	.card_active_2 .card1 {
		transform: translate(-55%, 200%) !important;
	}

	.card_active_3 .card1 {
		transform: translate(-55%, 200%) !important;
	}

	.card_active_3 .card2 {
		transform: translate(-55%, 200%) !important;
	}

	.card .card__data {
		font-size: 2.2vw;
		line-height: 2.4vw;
	}

	.menu__menu li:nth-child(1) {
		top: 19vh;
	}

	.menu__menu li:nth-child(4) {
		bottom: 23vh;
	}

	.menu .menu__container .menu__line1,
	.menu .menu__container .menu__line2,
	.menu .menu__container .menu__line3 {
		width: 169vw;
	}

	.form__control {
		margin-bottom: 4vw;
	}

	.contacto .contacto__col2 .contacto__mensaje,
	.form__control_textarea {
		font-size: 26px !important;
		line-height: 28px !important;
	}

	.mensaje__ok .mensaje__ok_title {
		font-size: 4vw;
		line-height: 4vw;
		width: 36vh;
	}

	.header .header__ani_center {
		margin-top: 8vw;
	}

	.header2 .header__data {
		height: 63vw;
		top: calc(50% - 31.5vw);
	}

	.header.header__inversion .header__ani_center {
		margin-top: 15vw;
	}

	.header.header__inversion .header__data {
		height: 68vw;
		top: calc(50% - 34vw);
	}

	.header.header__faqs .header__ani_center {
		margin-top: 17vw;
	}

	.header.header__faqs .header__data {
		height: 72vw;
		top: calc(50% - 36vw);
	}

	.menu__hover {
		display: none;
	}

	.header .header__ani_right .header__ani video {
		width: 38.771vw;
	}

	.card .card__img {
		margin-top: 0vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad video {
		margin-top: -6vw;
	}

	.bloque__triangulo-producto .kentoMedium .split-line {
		height: 3.3vw;
		line-height: 5vw;
	}

	.header__404.header-ani .header__ani_center {
		margin-top: 13vw;
	}

	.header__404.header-ani .header__data {
		height: 78vw;
		top: calc(50% - 31.5vw);
	}

	footer.verde .footer .footer__linea1,
	footer.verde .footer .footer__linea2,
	footer .footer .footer__linea1,
	footer .footer .footer__linea2 {
		width: 160%;
	}

	footer .footer__menu-a .split-line {
		height: 4.604vw;
		line-height: 6vw;
	}

	footer .footer__menu-a {
		font-size: 4.604vw;
		line-height: 4.604vw;
	}

	.footer__menu_svg {
		opacity: 0;
	}

	.bloque__txtc.bloque__txtc-inversion {
		margin-bottom: 10vw;
	}
}

@media screen and (max-width: 600px) {
	header .header__logo {
		left: 3.733vw;
		top: 9.667vw;
	}

	.bloque__txtc.bloque__txtc-inversion {
		margin-bottom: unset;
	}
	header .header__logo,
	header .header__logo a svg {
		width: 33.808vw;
	}

	.menu__menu li a.menu__menu-a {
		font-size: 7.733vw;
		line-height: 7.733vw;
	}

	.menu__menu li {
		height: 6.5vw;
		width: 100%;
		text-align: center;
	}

	.menu__menu li:nth-child(1) {
		top: 13vh;
	}

	.menu__menu li:nth-child(2) {
		top: 19vh;
		left: 0px;
	}

	.menu__menu li:nth-child(3) {
		top: 23.3vh;
		left: 0px;
	}

	.bloque__hablamos_cont_btn {
		height: 45.542vw;
	}

	.menu__menu li:nth-child(4) {
		bottom: 24vh;
	}

	.menu__menu li:nth-child(5) {
		display: block;
		bottom: 19.8vh;
	}

	.menu__menu li:nth-child(6) {
		display: block;
		bottom: 15.7vh;
	}

	.menu__btn-invierte {
		display: none;
	}

	.menu .menu__container .menu__line1,
	.menu .menu__container .menu__line2 {
		width: 125vh;
	}

	.menu .menu__btn-contacto {
		display: none;
	}

	.btn__menu_home .btn__rombo_txt {
		top: calc(53%);
		font-size: 4.8vw;
		line-height: 4.8vw;
	}

	.btn__rombo {
		font-size: 4vw;
		line-height: 4vw;
	}

	.menu__menu li a.menu__menu-a {
		font-size: 25px;
		line-height: 36px;
	}

	.btn__rombo_svg {
		width: 18.667vw !important;
		height: 18.667vw;
	}

	.btn__rombo_svg .btn__rombo_txt svg {
		width: 4.85vw;
	}

	.menu .menu__btn-rs {
		right: calc(50% - 9.3vw);
	}

	.menu .menu__reserved {
		font-size: 12px;
		width: 60%;
		text-align: center;
		line-height: 12px;
		text-align: left;
		left: 26px;
		transform: none;
	}

	.header__menu {
		width: 14.4vw;
		height: 14.4vw;
		border-radius: 15vw;
	}

	.header__menu .header__menu-c1,
	.header__menu .header__menu-c2 {
		top: 1.8vw;
		left: 1.8vw;
		width: 10.2vw;
		height: 10.2vw;
	}

	header .header__menu-content {
		right: 3.733vw;
		top: 4vw;
		width: 14.4vw;
		height: 14.4vw;
		border-radius: 15vw;
	}

	.header__menu .header__menu-l1,
	.header__menu .header__menu-l2,
	.header__menu .header__menu-l3 {
		width: 3vw;
		top: 6.6vw;
	}

	.header__menu .header__menu-l1,
	.header__menu .header__menu-l2,
	.header__menu .header__menu-l3,
	.header__menu:hover .header__menu-l1,
	.header__menu:hover .header__menu-l2,
	.header__menu:hover .header__menu-l3 {
		top: 6.8vw;
		width: 4vw;
		left: 7vw;
	}

	.header__menu-close .header__menu-l2,
	.header__menu-close .header__menu-l3,
	.header__menu-close:hover .header__menu-l2,
	.header__menu-close:hover .header__menu-l3 {
		left: 5vw;
	}

	.menu .menu__container .menu__line3 {
		display: block;
	}

	.header .header__ani_right .header__ani_rombo {
		position: absolute;
		width: 300vw;

		right: -164.594vw;
		bottom: -42.271vw;
	}

	.header .header__ani_right .header__ani {
		width: 42.771vw;
		/* right: -42.563vw;
		bottom: -48.646vw; */
		right: -2.563vw;
		bottom: -28.646vw;
	}

	.header .header__ani_left .header__ani {
		left: -21.458vw;
		top: -29.24vw;
	}

	.header .header__data {
		top: 82vw;
	}

	.pieza {
		width: 13vw;
		height: 13vw;
	}

	.pieza-header {
		position: relative !important;
		top: 0 !important;
		margin-bottom: 24px !important;
	}

	.pieza .pieza__line {
		width: 13vw !important;
		left: calc(50% - calc(13vw / 2));
	}

	.pieza .pieza__rombo {
		width: 3vw;
		height: 3vw;
		top: calc(50% - 1.5vw);
		left: calc(50% - 1.5vw);
	}

	.header__pretitulo {
		position: relative !important;
		top: 0px !important;
		left: 0% !important;
		transform: none !important;
	}

	.header .header__pretitulo,
	.bloque__txtc .bloque__txtc__pretitulo {
		font-size: 14px;
		line-height: 19px;
		margin-bottom: 14px;
	}

	.header .header__titulo h1,
	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 30px;
		font-size: 30px;
		padding: 0px;
		width: 100%;
		margin-left: 0;
	}

	.header .header__titulo h1 .split-line,
	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 30px;
		line-height: 42px;
	}

	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 6.2vw;
		line-height: 8vw;
	}

	.header .header__titulo {
		overflow: hidden;
	}

	.cita-header {
		margin-left: 0vw !important;
		width: 90%;
		margin-top: 0px !important;
		margin-bottom: 10px !important;
	}

	.cita .cita__cita {
		max-width: 75vw;
		margin-left: 15vw;
		font-size: 14px;
		line-height: 16px;
	}

	.header-ani .cita.cita-header .cita__cita {
		padding-right: 30px
	}

	.cita.cita-header .cita__cita {
		max-width: 60vw;
	}

	.bloque__blanco .texto__grande-marca {
		font-size: 34px;
		line-height: 34px;
		margin: 32px auto 43px;
	}

	.bloque__blanco {
		padding-top: 13.625vw;
	}

	.bloque__blanco-diamante svg {
		width: 18.667vw;
	}

	.texto__pequeno-marca {
		margin: 10.2vw auto;
		max-width: 79.617vw;
		font-size: 4.267vw;
		line-height: 4.8vw;
	}

	.bloque__triangulo .bloque__triangulo__titulo {
		font-size: 13.867vw;
		line-height: 13.867vw;
	}

	.bloque__triangulo .bloque__triangulo__tri {
		width: 90vw;
		margin-top: 11vw;
	}

	.bloque__triangulo .bloque__triangulo__txt {
		width: 90vw;
		font-size: 6.667vw;
		line-height: 9.667vw;
		margin-top: 12vw;
		border-bottom: 2px solid var(--colorNegro);
	}

	.bloque__triangulo .bloque__triangulo__medium {
		width: 100%;
		font-size: 30px;
		line-height: 30px;
		margin: 26px auto 0px;
		padding: 0px 20px;
	}

	.bloque__triangulo .bloque__triangulo__medium .split-line {
		height: 30px;
		line-height: 43px;
	}

	.bloque__triangulo .bloque__triangulo__rombodeco {
		width: 5.6vw;
		height: 5.6vw;
		margin: 9.333vw auto;
	}

	.bloque__triangulo .cita {
		transform: none;
		left: 0%;
		position: relative;
		margin-left: 5%;
		width: 90%;
	}

	.cita .cita__title {
		font-size: 7.467vw !important;
		line-height: 14vw;
		margin-bottom: 10px;
		height: 12vw;
	}

	.bloque__triangulo {
		margin-bottom: 41vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig {
		font-size: 6.933vw;
		line-height: 6.933vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig .split-line {
		height: 6.933vw;
		line-height: 8vw;
	}

	.bloque__vision__txtbig_container {
		margin-left: 15px;
		width: calc(100vw - 30px);
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-line {
		margin: 8vw 0px;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
		width: 42vw;
		font-size: 3.733vw;
		line-height: 4.5vw;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols-bottom {
		display: block;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols-bottom>div {
		width: 100%;
	}

	.bloque__vision__txtbig_container .bloque__vision__figura {
		width: 60px;
	}

	.bloque__vision .vision_figura1_figura img,
	.bloque__vision .vision_figura1_figura video {
		width: 100%;
		margin-top: 6%;
		margin-left: 0;
	}

	.bloque__vision__txtbig_container .cita {
		margin-top: 12vw;
	}

	.bloque__vision__txtbig_container .cita .cita__cita {
		margin-left: 20vw;
		width: 70vw;
		max-width: 70vw;
	}

	.cita .cita__cita p {
		margin-top: 0px;
	}

	.bloque__vision {
		overflow: hidden;
		padding: 60px 0px 60px;
	}

	.bloque__vision .bloque__vision_maque {
		position: relative;
		height: 100vh;
	}

	.bloque__vision .vision_figura1_fondo {
		width: 65vw;
		margin-left: 5vw;
		position: absolute;
		top: 30vw;
		z-index: 0;
	}

	.bloque__vision .vision_figura1_figura {
		width: 65vw;
		margin-left: 5vw;
	}

	.header .header__ani_left .header__ani_rombo {
		left: -60.146vw;
		top: 5.187vw;
	}

	.header .header__ani_right .header__ani_rombo {
		right: -135.594vw;
		bottom: -12.271vw;
	}

	.bloque__vision .vision_figura2_fondo {
		width: 65.208vw;
		/* right: -12.792vw; */
		margin-top: 88vw;
	}

	.bloque__vision .vision_figura2_figura {
		width: 65.208vw;
		/* right: -12.792vw; */
		position: absolute;
		top: 20vw;
		z-index: 2;
		margin-top: 88vw;
	}

	.bloque__vision .vision_figura2_figura img,
	.bloque__vision .vision_figura2_figura video {
		width: 76%;
		margin-left: 13%;
	}

	.marquesina__scroll .marquesina__scroll_inner {
		font-size: 32vw;
		line-height: 45vw;
	}

	.bloque__listado {
		position: relative;
		padding: 0px 5vw;
		margin: 10vw 0px 30vw;
	}

	.bloque__listado .bloque__listado_item {
		padding: 12.267vw 0vw;
		transition: var(--aniListadoItem);
		display: block;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-txt {
		position: relative;
		right: 0px;
		top: 0;
		transform: none;
		width: 75vw;
		font-size: 4vw;
		line-height: 5.067vw;
		margin-left: 15vw;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-titulo {
		font-size: 26px;
		line-height: 26px;
		margin-bottom: 4vw;
		margin-left: 15vw;
		width: 75vw;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-titulo .split-line {
		height: 26px;
		line-height: 38px;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-num {
		width: 12vw;
		padding-top: 1px;
		height: 1.3vw;
		font-size: 4.267vw;
		line-height: 4.267vw;
		height: 4.267vw;
		position: absolute;
	}

	.bloque__listado .bloque__listado_item-linea svg {
		position: absolute;
		margin-top: -5px;
		height: 11px !important;
		left: -2px;
	}

	.bloque__listado_item-img-movil {
		display: block;
		width: 100%;
		opacity: 0;
	}

	.bloque__listado_item-img-movil img {
		display: block;
		height: auto;
		width: 100%;
		margin-bottom: 9.333vw;
	}

	.bloque__verde {
		padding: 19.333vw 0px;
	}

	.bloque__txtc .pieza {
		margin-bottom: 9.542vw;
	}

	.bloque__txtc .bloque__txtc__titulo__rombodeco {
		width: 5.6vw;
		height: 5.6vw;
		margin: 9.333vw auto;
	}

	.bloque__txtc .cita {
		font-size: 1.171vw;
		width: 90vw;
		margin: 0px;
		padding: 0px;
	}

	.bloque__txtc .cita .cita__cita {
		margin-left: 20vw;
		max-width: 70vw;
	}

	.bloque__colscroll {
		display: block;
	}

	.bloque__colscroll .bloque__colscroll_colimgs {
		display: none;
	}

	.bloque__colscroll .bloque__colscroll_coldata {
		width: 90vw;
		margin-left: 5vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info-space {
		height: 0vh;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
		padding-bottom: 10.417vw;
		font-size: 1.465vw;
		line-height: 1.7vw;
		width: 78vw;
		font-size: 4vw;
		line-height: 5.067vw;
		margin-left: 12vw;
		position: relative;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_title {
		font-size: 8vw;
		line-height: 8vw;
		margin-bottom: 4vw;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_number {
		width: 12vw;
		padding-top: 1px;
		height: 1.3vw;
		font-size: 4.267vw;
		line-height: 4.267vw;
		height: 4.267vw;
		position: absolute;
		margin-left: -12vw;
	}

	.bloque__colscroll_info_img-movil {
		display: block;
		width: 90vw;
		margin-left: -15vw;
		margin-bottom: 9.333vw;
	}

	.bloque__colscroll_info_img-movil img {
		display: block;
		width: 100%;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre {
		font-size: 40px !important;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre img,
	.bloque__equipo .bloque__equipo_item .bloque__equipo_nombre svg {
		height: 40px;
		margin: 0px 10px -6px;
	}

	.bloque__hablamos {
		margin-top: 37vw;
	}

	.bloque__hablamos .bloque_hablamos_txt {
		width: 42.063vw;
		margin: 14vw auto 0px;
		font-size: 3vw;
		line-height: 4vw;
	}

	footer .footer .footer__rombo a {
		text-decoration: none;
		font-size: 8.208vw;
		line-height: 18.208vw;
		margin-top: 7vw;
	}

	footer .footer__btn-contacto,
	footer .footer__txt_top {
		display: none;
	}

	footer .footer__btn-rs {
		left: 1.875vw;
		right: auto;
	}

	footer .footer__btn-scroll {
		bottom: 1.354vw !important;
	}

	footer .footer__txt_bottom {
		width: 50vw;
		font-size: 3.172vw;
		line-height: 3.4vw;
		margin-bottom: 10px;
	}

	footer .footer__menu-a {
		font-size: 7.733vw;
		line-height: 7.733vw;
		margin-top: 2vw;
	}

	footer .footer__menu-a .split-line {
		line-height: 11vw;
		height: 7.733vw;
	}

	.footer__menu_svg {
		width: 11.688vw !important;
		margin-top: 1vw !important;
		margin-left: -15vw !important;
		display: none;
	}

	footer .footer__menu1 {
		top: 20vh;
		left: 50%;
		transform: translateX(-50%);
		width: 237px;
		text-align: center;
	}

	footer .footer__menu2 {
		text-align: center;
		top: 70vh;
		left: 50%;
		width: 237px;
	}

	.bloque__negro {
		padding: 10vw 0px 20vw;
	}

	.slider .slider__data {
		top: 50% !important;
	}

	.slider .slider__data .slider__cont .slider__cont_cont {
		font-size: 3vw;
		line-height: 3vw;
		margin: 0px 0px 0px 0px;
	}

	.slider .slider__data .slider__title .slider__title_title {
		line-height: 5vw;
		padding-left: 5px;
		font-size: 5vw;
	}

	.slider .slider__rombo .slider__rombo_figura svg path {
		stroke-width: 4px;
	}

	.slider .slider__rombo .slider__rombo_data span {
		margin-top: 4px;
	}

	.slider {
		height: 420px;
		overflow: hidden;
	}

	.slider .slider__controller {
		bottom: 14vw;
		width: 36vw !important;
	}

	.slider .slider__controller a,
	.slider .slider__controller a.btn__rombo_svg::before {
		width: 17vw !important;
		height: 15vw;
	}

	.slider .slider__controller {
		height: 15vw;
	}

	.slider .slider__controller a.btn__rombo_svg .btn__rombo_txt svg {
		width: 4.25vw;
		height: auto;
		margin-top: 1.2vw;
	}

	.contenedor__scroll_lateral {
		width: 100vw !important;
		overflow: hidden;
	}

	.contenedor__scroll_lateral {
		display: block;
		flex-wrap: initial;
		width: 500vw;
	}

	.fondo__verde,
	.fondo__negro {
		overflow: hidden;
	}

	.header {
		height: auto;
		overflow: hidden;
		width: 100vw;
	}

	.header .header__data {
		top: 110px;
	}

	.header-ani {
		height: 100vh;
	}

	.header-ani .cita-header {
		position: absolute;
		right: auto;
		bottom: auto;
		top: 580px;
		left: 20px;
		margin: 0px;
	}

	.header-ani .cita.cita-header .cita__cita {
		max-width: 100%;
		margin-left: 15%;
	}

	.card .card__fondo .card__fondo_desktop {
		display: none;
	}

	.card .card__fondo .card__fondo_movil {
		display: block;
	}

	.card {
		width: 89.333vw;
		height: 165.925vw;
	}

	.card .card__fondo {
		width: 89.333vw;
		height: 165.925vw;
	}

	.card .card__img {
		width: 84vw;
		position: relative;
		margin-bottom: 20px;
	}

	.card .card__img video {
		width: 90%;
		margin-left: 5%;
		margin-bottom: 6%;
	}

	.card .card__data {
		font-size: 16px;
		line-height: 20px;
		width: 90%;
		left: 0px;
		top: 0px;
		position: relative;
		transform: none;
		padding-top: 40px;
	}

	.card .card__data .card__data_title {
		font-size: 30px;
		line-height: 30px;
		margin-bottom: 14px;
	}

	.card2 {
		opacity: 0.6;
	}

	.card3 {
		opacity: 0.2;
	}

	.fondo__verde-cards {
		overflow: initial;
	}

	.card_active_2 .card1 {
		transform: translate(-50%, 120%) !important;
	}

	.card_active_2 .card2 {
		transform: translate(-50%, -45%) !important;
	}

	.card_active_2 .card3 {
		transform: translate(-50%, -47%) !important;
		opacity: 0.6;
	}

	.card_active_3 .card1 {
		transform: translate(-50%, 120%) !important;
	}

	.card_active_3 .card2 {
		transform: translate(-50%, 120%) !important;
	}

	.card_active_3 .card3 {
		transform: translate(-50%, -45%) !important;
	}

	.card .card__data .card__data_number {
		top: 0;
		font-size: 16px;
		line-height: 20px;
		left: 22px;
	}

	.bloque__vision__txtbig_container_producto {
		height: initial;
	}

	.bloque__vision__txtbig_container-data {
		position: relative;
		top: 0%;
		transform: none;
		margin-top: 50px;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig {
		font-size: 32px;
		line-height: 34px;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig .split-line {
		height: 34px;
		line-height: 46px;
	}

	.bloque__vision__txtbig-line .btn__rombo_svg {
		display: none;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-line {
		margin: 24px 0px 18px;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span {
		width: 42vw;
		font-size: 14px;
		line-height: 17px;
	}

	.contenedor__scroll_lateral .contenedor__scroll_lateral-seccion {
		width: 100vw;
		height: auto;
	}

	.contenedor__scroll_lateral-data .csls__row {
		position: relative;
		top: 0%;
		transform: none;
		display: block;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col {
		width: 100%;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col img,
	.contenedor__scroll_lateral-data .csls__row .csls__col video,
	.bloque__vision__txtbig_container-data {
		position: relative;
		top: 0%;
		transform: none;
		width: 76%;
	}

	.contenedor__scroll_lateral-data .csls__col_data {
		position: relative;
		top: 0;
		transform: none;
		padding: 35px 0px;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_pre {
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 25px;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_tit {
		line-height: 23px;
		font-size: 23px;
		margin-bottom: 25px;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col .csls__col_txt {
		line-height: 20px;
		font-size: 16px;
	}

	.bloque__vision__txtbig_container-data {
		width: 100%;
	}

	.bloque__txtc .bloque__txtc__figura {
		width: 68px;
		margin: 0px auto 58px;
	}

	.bloque__txtc-producto {
		padding-top: 100px;
		padding-bottom: 60px;
	}

	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 30px;
		line-height: 42px;
	}

	.bloque__txtc-producto .bloque__txtc__titulo {
		margin: 0px auto;
	}

	.tabla {
		font-size: 22px;
		line-height: 22px;
		padding: 0px 20px;
	}

	.tabla .row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
		padding: 0px;
		margin: 0px 0px 40px 0px;
	}

	.tabla .row .col.col-header.col.col-txt {
		display: none;
	}

	.tabla .row .col.col-header {
		width: 50%;
		padding: 17px 0px;
		font-size: 14px !important;
		line-height: 14px !important;
		text-align: left;
		padding-right: 10%;
	}

	.tabla .row .col.col-txt {
		width: 100%;
		padding-right: 0px;
		margin-bottom: 10px;
		font-size: 22px !important;
		line-height: 22px !important;
	}

	.bloque__triangulo .bloque__triangulo__txt {
		border-bottom: 0px;
		margin-top: 150px;
	}

	.bloque__triangulo .bloque__triangulo__tri {
		margin-top: 250px;
	}

	.tabla .row .col {
		width: 50%;
		padding: 0px 0px 4px 0px;
		text-align: left !important;
		font-size: 16px !important;
		line-height: 20px !important;
	}

	.bloque__triangulo {
		padding-top: 120px;
		padding-bottom: 100px;
		margin-bottom: 0px;
	}

	.bloque__triangulo .bloque__triangulo__titulo {
		font-size: 38px;
		line-height: 38px;
	}

	.bloque__triangulo .bloque__triangulo__tri-pro img,
	.bloque__triangulo .bloque__triangulo__tri-pro video {
		max-width: 270px;
	}

	.bloque__product_txt svg {
		width: 80px;
	}

	.bloque__product_txt .bloque__product_txt_data {
		width: 100%;
		font-size: 30px;
		line-height: 30px;
		margin-top: 30px;
	}

	.bloque__product_txt .bloque__product_txt_data .split-line {
		height: 30px;
		line-height: 42px;
	}

	.bloque__scroll__later .bloque__sl__fixed {
		display: none;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data {
		width: 100%;
		padding-left: 12%;
		margin-bottom: 20px;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_title {
		font-size: 28px;
		line-height: 28px;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_cont {
		font-size: 16px;
		line-height: 20px;
		width: 100%;
	}

	.bloque__scroll__later .bloque__sl__pantalla .bloque__sl__data .bloque__sl__data_number {
		font-family: 'KentoRegular';
		font-size: 16px;
		line-height: 20px;
		top: -1px;
	}

	.bloque__scroll__later .bloque__sl__pantalla {
		padding: 0px 20px;
	}

	.bloque__sl__data_img_movil {
		display: block;
		width: 100%;
		margin-bottom: 30px;
	}

	.bloque__sl__data_img_movil img {
		display: block;
		width: 100%;
	}

	.bloque__listado .bloque__listado_item .bloque__listado_item-txt {
		margin-left: 0px;
	}
	

	.bloque__listado_hover {
		display: none !important;
	}

	.bloque__listado_item_hidden_movil {
		display: none !important;
	}

	.diapo {
		display: block;
		position: relative;
		width: calc(100vw - 0px);
		padding-right: 20px;
	}

	.diapo .diapo__img {
		text-align: center;
		margin-bottom: 25px;
	}

	.diapo .diapo__img img,
	.diapo .diapo__img video {
		height: 70vw;
		width: auto;
		padding: 3vw;
	}

	.diapo .diapo__data_cont {
		position: relative;
		top: 0%;
		transform: none;
		width: 100%;
		margin-left: 0px;
	}

	.diapo .diapo__data_cont .diapo__data_cont_title {
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 24px;
	}

	.btn__ver {
		position: relative;
		width: 110px;
		font-size: 14px;
		line-height: 14px;
		color: var(--colorNegro);
		transition: var(--aniBtnSlider);
	}

	.carrousel {
		padding: 0px 20px 20px;
		overflow: hidden;
	}

	.diapo .diapo__num {
		font-size: 20px;
		width: 6vw;
		padding-top: 35px;
		position: absolute;
	}

	.carrousel__controller {
		zoom: 1.2;
		width: 130px !important;
		bottom: 0px;
	}

	.carrousel__controller {
		height: 50px !important;
	}

	.carrousel__controller a,
	.carrousel__controller a.btn__rombo_svg::before {
		width: 60px !important;
		height: 50px !important;
	}

	.carrousel__controller .btn__rombo_svg .btn__rombo_txt svg {
		width: 18px;
		margin-top: 3px;
	}

	.bloque__txtc-inversion {
		padding-bottom: 100px;
	}

	.bloque__hablamos .bloque_hablamos_txt {
		width: 48.063vw;
		margin: 65px auto 0px;
		font-size: 15px;
		line-height: 19px;
	}

	.btn_hablamos {
		position: relative;
		display: block;
		width: 40.74vw;
		margin: auto;
		margin-top: 80px;
		top: 0%;
		transform: none;
		opacity: 0;
	}

	.bloque__hablamos-producto {
		padding-bottom: 70px;
	}

	.bloque__equipo {
		overflow: hidden;
	}

	.header-marca {
		height: 100vh !important;
		transform: none !important;
	}

	.header__back {
		display: none;
	}

	.header-marca .header__data {
		position: absolute;
		top: 48%;
		width: 100%;
	}

	.header.header-faq .cita .cita__cita,
	.header.header-marca .cita .cita__cita {
		max-width: 63.417vw;
	}

	.header .header__ani_right {
		top: -15px;
	}

	.bloque__triangulo-producto {}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__titulo {
		font-size: 52px;
		line-height: 48px;
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__tri img,
	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__tri video {
		max-width: 66vw;
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__tri {
		margin-top: 70px;
		height: 295px;
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__txt {
		border-bottom: 0px;
		margin-top: 40px;
		border-bottom: 2px solid var(--colorNegro);
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__medium-marca {
		width: 100% !important;
		margin-top: 80px;
	}

	.bloque__vision__txtbig-marca {
		font-size: 26px !important;
		line-height: 30px !important;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig.bloque__vision__txtbig-marca .split-line {
		height: 30px !important;
		line-height: 44px !important;
	}

	.bloque__vision__txtbig_container .cita {
		margin-left: 0;
	}

	.bloque__listado-marca {
		padding: 0px 5vw;
		margin: 0 0px 30vw;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_img img {
		height: 50vh;
		margin-top: 15vh;
	}

	.bloque__equipo .bloque__equipo_item .bloque__equipo_img {
		height: 100vh;
	}

	.bloque__equipo .bloque__equipo_item {
		height: 200vh;
	}

	.bloque__hablamos-marca {
		margin: 0px;
	}

	.bloque__hablamos-marca.bloque__hablamos .bloque_hablamos_txt {
		margin-top: 30px;
	}

	footer .footer {
		overflow: hidden;
	}

	.bloque__blanco .bloque__blanco-fondo {
		display: none;
	}

	footer .footer__btn-scroll .btn__rombo,
	footer .footer__btn-rs .btn__rombo {
		width: 60px !important;
		height: 60px !important;
	}

	.header2.header-ani .cita-header {
		position: relative;
	}

	.header-ani .cita-header {
		top: 330px;
	}

	footer .footer__btn-rs {
		left: 15px;
		right: auto;
		bottom: 10px !important;
		margin-bottom: 10px;
	}

	footer .footer__btn-scroll {
		bottom: 10px !important;
		right: 15px;
		margin-bottom: 10px;
	}

	.header.header-faq .cita .cita__cita {
		max-width: 100%;
		margin-left: 15%;
	}

	footer .footer__btn-scroll .btn__rombo .btn__rombo_txt svg {
		width: 12px;
	}

	footer .footer__btn-rs .btn__rombo .btn__rombo_txt svg {
		width: 16px;
	}

	.page__inversion {
		padding-top: 100px;
	}

	.bloque__vision__txtbig_container-inversion {
		margin-bottom: 60px;
	}

	.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig {
		font-size: 28px;
		line-height: 28px;
	}

	.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig .split-line {
		height: 28px;
		line-height: 37px;
	}

	.bloque__vision__txtbig_container-inversion .bloque__vision__txtbig .split-line:first-child .split-char:first-child {
		margin-left: 0;
	}

	.bloque__triangulo-inversion.bloque__triangulo .bloque__triangulo__titulo {
		font-size: 45px;
		line-height: 45px;
	}

	.bloque__triangulo-inversion.bloque__triangulo .bloque__triangulo__pretitulo {
		position: relative;
		left: 50%;
		margin-top: 0;
		margin: auto;
		text-align: center;
		margin-bottom: 30px;
		font-size: 25px;
	}

	.bloque__triangulo-inversion.bloque__triangulo .bloque__triangulo__medium {
		width: 100%;
		font-size: 22px;
		line-height: 22px;
		margin: 120px auto 0px;
		padding: 0px 20px;
	}

	.bloque__triangulo.bloque__triangulo-inversion .bloque__triangulo__tri {
		margin-top: 171px;
		width: 75vw;
	}

	.bloque__triangulo.bloque__triangulo-inversion .bloque__triangulo__tri img,
	.bloque__triangulo.bloque__triangulo-inversion .bloque__triangulo__tri video {
		max-width: 45.542vw;
	}

	.bloque__triangulo-inversion .cita .cita__cita,
	.bloque__triangulo__cols .bloque__triangulo__col {
		font-size: 16px;
		line-height: 20px;
	}

	.bloque__triangulo-inversion .cita .cita__cita {
		max-width: 81.417vw;
		margin-left: 15vw;
	}

	.bloque__triangulo .bloque__triangulo__tri-inversion-hexa.bloque__triangulo__tri {
		width: 62vw;
	}

	.bloque__triangulo .bloque__triangulo__tri-inversion-hexa.bloque__triangulo__tri img,
	.bloque__triangulo .bloque__triangulo__tri-inversion-hexa.bloque__triangulo__tri video {
		max-width: 55vw;
		margin-bottom: 60px;
	}

	.bloque__triangulo__cols {
		width: calc(100% - 30px);
		display: block;
		margin-top: 70px;
		margin-left: 15px;
	}

	.bloque__triangulo__cols .bloque__triangulo__col {
		width: 100%;
		padding-right: 0;
		margin-bottom: 60px;
	}

	.bloque__triangulo__cols .bloque__triangulo__col .bloque__triangulo__col_title {
		font-size: 22px;
		line-height: 22px;
		width: 60vw;
	}

	.btn__ver-visita {
		position: relative;
		width: 220px;
		font-size: 14px;
		line-height: 14px;
		color: var(--colorNegro);
		transition: var(--aniBtnSlider);
	}

	.btn__ver-visita span {
		width: 165px;
	}

	.bloque__triangulo .bloque__triangulo__tri-rombo img,
	.bloque__triangulo .bloque__triangulo__tri-rombo video {
		max-width: 52vw !important;
		margin-bottom: 73px;
		max-width: 30vw;
	}

	.bloque__vision__txtbig_container-inv2 {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.bloque__vision__txtbig_container .bloque__vision__txtbig-cols>div>span.w14 {
		width: 100%;
	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info .bloque__colscroll_info_title {
		font-size: 22px;
		line-height: 22px;
		margin-bottom: 20px;
	}

	.bloque__txtc-inversion {
		padding-bottom: 100px;
		margin-top: 100px;
	}

	.header2 .cita-header,
	.diapo .diapo__data_cont .diapo__data_cont_cont {
		font-size: 16px;
		line-height: 20px;
		width: 90%;
	}

	.bloque__carrousel-inversion {
		margin-bottom: 100px;
	}

	.bloque__negro-inversion-hablamos .bloque__hablamos {
		margin-top: 42px;

	}

	.bloque__colscroll .bloque__colscroll_coldata .bloque__colscroll_info {
		padding-bottom: 0px;
	}

	.menu__container {
		height: 90vh;
		top: 10vh;
	}

	.bloque__faqs {
		width: 100%;
		padding: 0px 15px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_rombo svg {
		width: 14px;
		margin-top: 5px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_number {
		display: none;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_title {
		font-size: 20px;
		line-height: 20px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_title .split-line {
		height: 22px;
		line-height: 30px;
	}

	.bloque__faqs ul li .faqs .faqs__header .faqs__header_title {
		width: 84vw;
	}

	.bloque__faqs ul li .faqs .faqs__data_cont {
		padding: 10px 0px 50px 30px;
		font-size: 16px;
		line-height: 20px;
	}

	.bloque__faqs ul li .faqs .faqs__header {
		font-size: 20px;
		line-height: 20px;
		padding: 22px 0px 14px;
	}

	.bloque__hablamos {
		padding-bottom: 100px;
	}

	.header.header-contacto .header__ani_center {
		top: -73px;
	}

	.header-contacto .cita-header {
		top: 520px;
	}

	.header-contacto {
		padding-bottom: 110px;
	}

	.header__contacto_contenedor {
		padding-top: 4vw;
		width: 100%;
		padding: 0px 15px;
	}

	.page__contacto {
		width: 100%;
		padding: 80px 15px 0px;
		margin: 0px;
		position: relative;
	}

	.header__contacto_data .header__contacto_data_left,
	.header__contacto_data .header__contacto_data_right {
		width: 100%;
		float: none;
		text-align: left;
		font-size: 15px;
		padding-bottom: 25px;
	}

	.contacto {

		display: block;
	}

	.contacto .contacto__col1,
	.contacto .contacto__col2 {
		width: 100%;
	}

	.contacto .contacto__formcont {
		width: 100%;
	}

	.form__control {
		padding: 10px 0px;
		margin-bottom: 30px;
	}

	.contacto__legalsub,
	.contacto__legalsub .contacto__legal {
		display: block;
		width: 100%;
	}

	.contacto__legalsub {
		padding: 10px 0px;
	}

	.contacto__legalsub .contacto__submit {
		text-align: right;
		width: 100%;
	}

	.form__submit {
		font-size: 14px;
		line-height: 14px;
		padding: 1vw 3px;
		width: 144px;
		height: 48px;
		font-family: 'KentoRegular';
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.form__submit svg {
		width: 144px;
		height: 48px;
	}

	.contacto .contacto__col2 .contacto__mensaje {
		font-size: 30px;
		line-height: 30px;
		padding: 30px 0px 10px;
	}

	.header-aviso .cita-header {
		top: 528px;
	}

	.header-aviso {
		height: auto;
		padding-bottom: 120px;
	}

	.header-404 {
		height: 100vh;
	}

	.header-aviso .header__ani_center {
		margin-top: -50px;
	}

	.header__btn404 {
		bottom: 0px;
	}

	header .header__logo a svg.header__logo_logo {
		width: 49px;
	}

	header .header__logo,
	header .header__logo a svg {
		width: 50px !important;
	}

	header .header__logo {
		left: 15px;
		top: 28px;
	}

	.btn_hablamos span {
		font-size: 8.771vw;
	}

	.header__404.header-ani .header__ani_center {
		margin-top: 19vw;
	}

	.header2.header__404 .header__ani {
		top: 70vw;
	}


	.slider .slider__data .slider__title {
		position: absolute;
		top: -80px !important;
		left: -6px !important;
		width: 100vw !important;
		text-align: center;
		height: 26px !important;
	}

	.slider .slider__data .slider__title .slider__title_title {
		line-height: 35px;
		padding-left: 5px;
		font-size: 20px;
		height: 26px;
	}

	.slider .slider__data .slider__cont .slider__cont_cont {
		font-size: 12px;
		line-height: 14px;
		margin: 0px 0px 0px 0px;
		height: 100px;
	}

	.slider .slider__data .slider__cont {
		height: 100px;
	}

	.slider .slider__data {
		width: 35vw;
	}

	.slider_active_2 .slider__title__inner {
		transform: translateY(-26px) !important
	}

	.slider_active_3 .slider__title__inner {
		transform: translateY(-52px) !important
	}

	.slider_active_4 .slider__title__inner {
		transform: translateY(-78px) !important
	}


	/* .slider_active_2 .slider__cont__inner {
		transform: translateY(-100px);
	}

	.slider_active_3 .slider__cont__inner {
		transform: translateY(-200px);
	}

	.slider_active_4 .slider__cont__inner {
		transform: translateY(-300px);
	} */

	.btn__rombo::before {
		width: 100%;
		height: 100%;
	}

	footer .footer .footer__rombo a svg {
		height: 24px;
	}

	footer .footer .footer__rombo a span {
		display: block;
		animation: 7s linear infinite marquee_movil;
	}

	.bloque__triangulo.bloque__triangulo-producto .bloque__triangulo__titulo {
		font-size: 38px;
		line-height: 45px;
	}
	
	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__txt {
		border-bottom: 0px;
		margin-top: 130px;
		border-bottom: 0px;
	}

	.bloque__triangulo.bloque__triangulo-producto .bloque__triangulo__medium {
		width: 100%;
		font-size: 22px;
		line-height: 22px;
		margin: 26px auto 0px;
		padding: 0px 20px;
	}

	.bloque__triangulo.bloque__triangulo-producto .bloque__triangulo__medium .split-line {
		line-height: 30px;
		height: 22px;
	}

	.bloque__triangulo-inversion .bloque__triangulo__medium {
		font-size: 30px !important;
		line-height: 30px !important;
	}

	.contacto .contacto__col2 .contacto__mensaje,
	.form__control_textarea {
		font-size: 30px !important;
		line-height: 30px !important;
		margin-bottom: 0px;
	}

	.form__control_textarea {
		height: 357px;
	}

	.int_header__titulo_ani,
	.header__titulo_ani {
		line-height: 8.4vw;
		font-size: 8.4vw;
		margin: 0px auto 7vw auto;
		max-width: 90vw;
		height: 22vw;
		transform: scale(2.5);
	}
	.int_header__titulo_ani.--center {
		margin: 0px;
		margin-bottom: 7px;
		max-width: 100vw;
	}

	.int_header__titulo_ani.--producto,
	.header__titulo_ani.--producto {
		line-height: 8vw;
		font-size: 8vw;
	}


	.int_header__titulo_ani .int_header__titulo_ani_1 .tit,
	.int_header__titulo_ani .int_header__titulo_ani_2 .tit,
	.int_header__titulo_ani .int_header__titulo_ani_3 .tit,
	.header__titulo_ani .header__titulo_ani_1 .tit,
	.header__titulo_ani .header__titulo_ani_2 .tit,
	.header__titulo_ani .header__titulo_ani_3 .tit {
		width: 90vw;
	}
	.int_header__titulo_ani.--center .int_header__titulo_ani_1 .tit,
	.int_header__titulo_ani.--center .int_header__titulo_ani_2 .tit,
	.int_header__titulo_ani.--center .int_header__titulo_ani_3 .tit {
		width: 100vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_1 .linet2,
	.int_header__titulo_ani .int_header__titulo_ani_2 .linet2,
	.int_header__titulo_ani .int_header__titulo_ani_3 .linet2,
	.header__titulo_ani .header__titulo_ani_1 .linet2,
	.header__titulo_ani .header__titulo_ani_2 .linet2,
	.header__titulo_ani .header__titulo_ani_3 .linet2 {
		width: 90vw;
	}
	.int_header__titulo_ani.--center .int_header__titulo_ani_1 .linet2,
	.int_header__titulo_ani.--center .int_header__titulo_ani_2 .linet2,
	.int_header__titulo_ani.--center .int_header__titulo_ani_3 .linet2 {
		width: 100vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_3,
	.header__titulo_ani .header__titulo_ani_3 {
		width: 32vw;
		height: 24vw;
	}
	.int_header__titulo_ani.--center .int_header__titulo_ani_3 {
		width: 35vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_1,
	.header__titulo_ani .header__titulo_ani_1 {
		height: 24vw;
		left: 45vw;
	}
	.int_header__titulo_ani.--center .int_header__titulo_ani_1 {
		left: 50vw;
	}

	.int_header__titulo_ani .int_header__titulo_ani_2,
	.header__titulo_ani .header__titulo_ani_2 {
		height: 24vw;
		left: 45vw;
	}
	.int_header__titulo_ani.--center .int_header__titulo_ani_2 {
		left: 50vw;
	}

	.contacto__input .contacto__input_error {
		margin-top: -20px;
	}

	.contacto__check_error,
	.contacto__mensaje_error {
		font-size: 12px;
		line-height: 16px;
	}

	.contacto__check_error {
		margin-bottom: 30px;
	}

	.mensaje__ok {
		height: 80vw;
		top: 70%;
	}

	.mensaje__ok.active {
		width: 80vw;
		top: 70%;
	}

	.mensaje__ok .mensaje__ok_title {
		width: 50vw;
	}

	.header .header__ani_center {
		width: 82vw;
		height: 82vw;
	}

	.header .header__ani_center .header__ani_rombo {
		width: 82vw;
		height: 44vw;
	}

	.btn__ver-invierte {
		width: 36vw;
		margin-top: 6vw;
	}

	.btn__ver-invierte span {
		width: 36vw;
		font-size: 12px;
	}

	.header .header__ani_center .header__ani_rombo {
		width: 82vw;
		height: 44vw;
	}

	.header2 .header__ani {
		width: 70vw;
		margin-left: 23px;
		height: 70vw;
		margin-top: 5vw;
	}

	.header2 .header__ani.--faq,
	.header2 .header__ani.--inversion {
		width: 76vw;
		margin-left: 14px;
	}

	.header .header__ani_center {
		margin-top: 16vw;
	}

	.header2 .header__data {
		height: 103vw;
	}

	.header .header__data {
		top: 25vw;
	}

	.header-marca.header .header__data {
		top: 45%;
	}

	.header-ani .cita-header {
		top: 27vw;
	}

	.header-ani .cita-header.--producto {
		top: 36vw;
	}

	.pieza .pieza__2 {
		left: 0px !important;
	}

	.header.header__inversion .header__ani_center {
		margin-top: 26vw;
	}

	.header.header__inversion .header__data {
		height: 113vw;
		top: 25vw;
	}

	.header.header__inversion .header__ani {
		top: 65vw;
	}

	.header.header__faqs .header__ani_center {
		margin-top: 30vw;
	}

	.header.header__faqs .header__data {
		height: 115vw;
		top: 25vw;
	}

	.header.header__faqs .header__ani {
		top: 65vw;
	}

	.btn__menu_home {
		width: 48vw;
		height: 48vw;
		top: calc(50% - 24vw);
		left: calc(50% - 24vw);
	}

	.btn__menu_home svg path {
		stroke-width: 4px;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col svg {
		height: 80vw !important;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col video {
		max-height: 78vw !important;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-sostenibilidad {
		margin-bottom: 10vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-sostenibilidad svg {
		height: 66vw !important;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-unicidad {
		margin-bottom: 6vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad video {
		margin-top: -3vw;
		margin-bottom: 10vw;
	}

	.contenedor__scroll_lateral-data .csls__row .csls__col.csls__col-autenticidad {
		margin-bottom: 4vw;
	}

	.bloque__triangulo .bloque__triangulo__tri-pro {
		width: 74vw;
		height: 74vw;
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__tri video {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 48vw;
		margin: 0px;
	}

	.bloque__triangulo-producto.bloque__triangulo .bloque__triangulo__tri video.img__diana {
		top: 62%;
	}

	.menu__menu li a.menu__menu-a {
		perspective: 28vw;
	}

	.diapo .diapo__img svg {
		position: absolute;
		width: auto;
		z-index: -1;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.menu__menu li a.menu__menu-a span {
		transform: translateY(4.5vh) rotateX(-90deg);
	}

	footer.verde .footer .footer__linea1,
	footer.verde .footer .footer__linea2 {
		width: 200%;
	}

	footer.verde .footer .footer__linea1 span,
	footer.verde .footer .footer__linea2 span {
		width: 200%;
	}

	footer.verde .footer .footer__linea2 span {
		width: 200%;
	}

	.header__404.header-ani .header__data {
		height: 460px;
	}

	.bloque__txtc {
		overflow: hidden;
		margin-bottom: 20vw; 
	}
	.bloque__txtc__imgani img {
		width: 66vw;
		margin: auto;
	}

	.bloque__txtc .cita {
		position: relative;
	}
	.int_header__titulo_ani{
		width: 90vw;
	}
	.bloque__txtc-inversion .bloque__txtc__imgani_cont {
		margin-top: -9vw;
	}
	.slider .slider__figuras .slider__figuras__figura  {

		transition: all 0.5s ease-in-out;
	}
	.slider .slider__figuras .slider__figuras__figura video {
		top: 50%;
		left: 16vw;
	}
	.slider .slider__title__inner {
		transform: translateY(7.2vw);
	}

	.int_header__titulo_ani.--center .tit .linet, .int_header__titulo_ani .tit .linet, .header__titulo_ani .tit .linet {
		letter-spacing: -2px !important;
		height: 8.2vw;
	}

}


@media screen and (max-width: 1023px) and (max-height: 450px) {
	.menu__menu li:nth-child(4) {
		bottom: 16vh;
	}

	.menu__container .btn__rombo_svg .btn__rombo_txt svg {
		width: 16px;
	}

	.menu__container .btn__rombo_svg {
		height: 8.201vw;
	}

	.btn__menu_home {
		width: 16vw;
		height: 16vw;
		top: calc(50% - 8vw);
		left: calc(50% - 8vw);
	}

	.menu__menu li a.menu__menu-a {
		perspective: 28vw;
	}

	.menu__menu li:nth-child(1) {
		top: 14vh;
	}

	.header__menu {
		transform: scale(0.8) !important;
	}

	.header__menu:hover .header__menu-l1,
	.header__menu:hover .header__menu-l2,
	.header__menu:hover .header__menu-l3 {
		left: 4vw;
		top: 5vw;
	}

	.header .header__ani_left,
	.header .header__ani_right {
		display: none;
	}

	.btn__menu_home svg path {
		stroke-width: 4px;
	}

	.menu__container {
		top: 10vh;
		width: 100vw;
		height: 90vh;
		overflow: initial;
	}

	.cita .cita__title {
		font-size: 32px !important;
		line-height: 60px;
	}

	.header.header-faq .cita .cita__cita,
	.header.header-marca .cita .cita__cita {
		max-width: 63.417vw;
		font-size: 16px;
		line-height: 20px;
	}

	.pieza-header {}

	.header1 {
		overflow: hidden;
		transform: none !important;
	}

	.header__back {
		display: none;
	}

	.header .header__titulo h1,
	.bloque__txtc .bloque__txtc__titulo h2 {
		line-height: 5.859vw;
		font-size: 5.348vw;
	}

	.header .header__titulo h1 .split-line,
	.bloque__txtc .bloque__txtc__titulo h2 .split-line {
		height: 5.859vw;
		line-height: 7vw;
	}

	.bloque__blanco {
		overflow: hidden;
	}

	.slider .slider__controller {
		bottom: 0vw;
		zoom: 1.6;
	}

	footer .footer__btn-scroll {
		transform: scale(0.8);
		bottom: 50vh;
	}

	footer .footer__btn-rs {
		transform: scale(0.8);
	}

	footer .footer__menu1 {
		top: 14vh;
		left: 52%;
		transform: translateX(-50%);
	}

	footer .footer__menu2 {
		top: 74vh;
		left: 52%;
		right: auto;
		transform: translateX(-50%);
	}

	.header-faq {
		height: 150vh;
	}

	.header-contacto,
	.header-ani {
		height: 130vh;
	}

	.fondo__verde-cards,
	.fondo__negro,
	.page-producto.fondo__verde {
		overflow: hidden;
	}

	.cards {
		margin-top: 250px;
	}

	.contacto .contacto__col2 .contacto__mensaje,
	.form__control_textarea {
		font-size: 20px !important;
		line-height: 22px !important;
	}

	.header__menu.header__menu-close .header__menu-l1,
	.header__menu.header__menu-close .header__menu-l2,
	.header__menu.header__menu-close .header__menu-l3 {
		top: 32px;
		width: 20px;
		left: 3.2vw !important;
	}

	.header__menu:hover .header__menu-l1,
	.header__menu:hover .header__menu-l2,
	.header__menu:hover .header__menu-l3 {
		top: 32px;
		width: 20px;
		left: 50%;
	}


}


@keyframes marquee_movil {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-228px);
	}
}

@keyframes aniLoadBtn {
	0% {
		transform: translateY(20px) rotateX(-60deg);
		transform-origin: center;
		color: var(--colorNegro);
	}

	100% {
		transform: translateY(0px) rotateX(0deg);
		transform-origin: center;
		color: var(--colorNegro);
	}
}

@keyframes aniLoadBtnVerde {
	0% {
		transform: translateY(20px) rotateX(-60deg);
		transform-origin: center;
		color: var(--colorVerde);
	}

	100% {
		transform: translateY(0px) rotateX(0deg);
		transform-origin: center;
		color: var(--colorVerde);
	}
}

@keyframes aniLoadBtnMenu {
	0% {
		top: 4.8vw;
		transform: rotate(-63deg);
		transform-origin: center;
	}

	100% {
		top: 0vw;
		transform: rotate(0deg);
		transform-origin: center;
	}
}

@keyframes aniLoadBtnMenuC2 {
	0% {
		transform: rotate(23deg);
		transform-origin: center;
	}

	100% {
		transform: rotate(-23deg);
		transform-origin: center;
	}
}

@-webkit-keyframes rotating

/* Safari and Chrome */
	{
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}


.page-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	height: 100dvh;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	z-index: 10;
	pointer-events: none !important;
	opacity: 1;
	padding: 3vw 3.8vw;
	transition: opacity .2s ease;
}

html.loaded .page-loader,
body.bodyExperiencia .page-loader {
	opacity: 0;
}

.loader {
	width: 31px;
	height: 31px;
	border: 1px solid var(--colorNegro);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation .6s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


.front-canvas,
.front-canvas * {
	pointer-events: none !important;
}

.front-canvas {
	position: fixed !important;
	z-index: 10;
	top: 0;
	left: 0;
}

html:not(.loaded) .front-canvas {
	opacity: 0 !important;
}

#leva__root {
	display: none !important;
}